import { useRecordContext } from 'react-admin'

import { Header, InnerHeader, ShowHeaderSkeleton, formatMoney, type TabType } from 'components'
import { type Action, deleteOneAction } from 'core/actions'
import {
    invoiceFields,
    InvoiceStatusKeys,
    InvoiceTypeKeys,
    type InvoiceModel,
} from 'resources/invoices'
import { AutoGrid, InfoBadge } from 'ui'

import CloseInvoiceButton from './CloseInvoiceButton'
import { CancelAction, ReopenAction, ResetAction, SendInvoiceAction } from './actions'

const actions: Action<InvoiceModel> = (record, { children }) => {
    return [
        record.status === InvoiceStatusKeys.OPEN && record.type === InvoiceTypeKeys.WORK_ORDER ? (
            <ResetAction
                key="reset"
                children={children}
            />
        ) : undefined,
        <SendInvoiceAction
            key="sendInvoice"
            record={record}
            children={children}
        />,
        record.status === InvoiceStatusKeys.CLOSED ? (
            <ReopenAction
                key="reopen"
                children={children}
            />
        ) : undefined,
        record.status === InvoiceStatusKeys.CLOSED ? (
            <CancelAction
                key="cancel"
                children={children}
            />
        ) : undefined,
        deleteOneAction({
            id: record.id,
            children,
        }),
    ]
}
const InvoiceShowHeader = ({ tabs }: { tabs: TabType[] }) => {
    const record = useRecordContext<InvoiceModel>()

    if (!record) {
        return <ShowHeaderSkeleton />
    }

    return (
        <InnerHeader
            tabs={tabs}
            tabWidth="186px"
            actions={actions}
            mainAction={<CloseInvoiceButton />}
        >
            <Header.Info
                avatar={
                    <Header.Avatar defaultImage={<invoiceFields.avatar.Icon record={record} />} />
                }
            >
                <Header.Content>
                    <Header.Title>{record.number}</Header.Title>
                </Header.Content>
                <Header.Content mobilePlacement="bottom">
                    <AutoGrid.Row>
                        <AutoGrid.Column>{invoiceFields.status.value(record)}</AutoGrid.Column>
                        <AutoGrid.Column>
                            <InfoBadge badgeContent={formatMoney(record.total)} />
                        </AutoGrid.Column>
                    </AutoGrid.Row>
                </Header.Content>
            </Header.Info>
        </InnerHeader>
    )
}

export default InvoiceShowHeader
