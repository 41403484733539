import { type Serializer } from 'core'
import { telematicsExtras } from 'resources/telematics'
import { type MeterModel } from 'resources/units'

export const meterSourceMap: { [key in MeterModel['source']]?: string } = {
    WORK_ORDER: 'Work Order',
    PM_SCHEDULE: 'PM Schedule',
    MANUAL: 'Manual Entry',
}

export const translateMeterSource = (source: MeterModel['source']) => {
    if (meterSourceMap[source]) {
        return meterSourceMap[source]
    }
    return telematicsExtras[source.toLowerCase()]?.providerName
}
export const meterSerializer: Serializer<MeterModel & { timestamp: string }> = [
    'type',
    { name: 'value', parse: 'number' },
    { name: 'timestamp', parse: 'dateTime' },
]
