import { type ListControllerResult } from 'react-admin'

import { type Identifier } from 'appTypes'
import {
    costMaskParams,
    TextareaInput,
    TextInput,
    type DrawerState,
    UtilityDrawerEditor,
} from 'components'
import { type ResourceType, maxLengthValidationText, requiredValidation } from 'core'
import { JobLaborForm, jobLaborSerializer } from 'pages/WorkOrders/Jobs/Labor'
import {
    JobPartForm,
    jobPartSerializer,
    jobPartWithoutNumberSerializer,
} from 'pages/WorkOrders/Jobs/Parts'
import { type JobPartFormProps } from 'pages/WorkOrders/Jobs/Parts/JobPartForm'
import { type WorkOrderItemsExtra } from 'pages/WorkOrders/Jobs/config/constants'
import { type VendorModel } from 'resources/vendors'
import {
    type WorkOrderModel,
    type JobModel,
    JobItemTypeKeys,
    type JobPartModel,
} from 'resources/workOrders'
import { Stack } from 'ui'

import { jobItemSerializer } from '../../VendorWorkOrderExtraActionsDrawerToggler'

import PartWithoutNumberDrawerForm from './PartWithoutNumberDrawerForm'
import { type LaborFormExtra, type PartWithoutNumberFormExtra } from './drawerTogglers'

const descriptionValidation = [maxLengthValidationText, requiredValidation]

export const editPartWithoutNumberActionExtraArgs = (
    resource: ResourceType,
    listContext: ListControllerResult,
    record: JobPartModel,
    disabledFields: boolean,
    job: JobModel,
): DrawerState => {
    const extraData: PartWithoutNumberFormExtra = {
        disabledFields,
        job,
        record,
    }

    return {
        drawerArgs: {
            renderTopRight: (render) =>
                render({
                    disabled: disabledFields,
                    title: disabledFields ? 'Reopen the WO to delete this item' : undefined,
                    confirmConfig: {
                        title: 'Are you sure you want to delete this item?',
                    },
                }),
            title: disabledFields
                ? 'View Part (Without Part Number)'
                : 'Edit Part (Without Part Number)',
            renderWrapper: (params) => (
                <UtilityDrawerEditor
                    defaultValues={{
                        type: JobItemTypeKeys.PART,
                    }}
                    {...params}
                    serializer={jobPartWithoutNumberSerializer}
                />
            ),
            renderContent: () => <PartWithoutNumberDrawerForm />,
            renderBottomRight: disabledFields ? () => null : undefined,
            renderBottomLeft: disabledFields
                ? (render) => render({ children: 'Close' })
                : undefined,
        },
        extraArgs: {
            listContext,
            resource,
            id: record.id,
        },
        extra: extraData,
    }
}

export const editPartActionExtraArgs = (
    resource: ResourceType,
    listContext: ListControllerResult,
    id: Identifier,
    disabledFields: boolean,
    vendor: VendorModel,
    job: JobModel,
    workOrder: WorkOrderModel,
    hideInputs?: JobPartFormProps['hideInputs'],
    title?: string,
    // eslint-disable-next-line max-params
): DrawerState => {
    const extraData: WorkOrderItemsExtra = {
        disabledFields,
        vendor,
        job,
        workOrder,
    }

    return {
        drawerArgs: {
            renderTopRight: (render) =>
                render({
                    disabled: disabledFields,
                    title: disabledFields ? 'Reopen the WO to delete this item' : undefined,
                    confirmConfig: {
                        title: 'Are you sure you want to delete this item?',
                    },
                }),
            title: disabledFields ? `View ${title || 'Part'}` : `Edit ${title || 'Part'}`,
            renderWrapper: (params) => (
                <UtilityDrawerEditor
                    defaultValues={{
                        type: JobItemTypeKeys.PART,
                    }}
                    {...params}
                    serializer={jobPartSerializer}
                />
            ),
            renderContent: () => <JobPartForm hideInputs={hideInputs} />,
            renderBottomRight: disabledFields ? () => null : undefined,
            renderBottomLeft: disabledFields
                ? (render) => render({ children: 'Close' })
                : undefined,
        },
        extraArgs: {
            listContext,
            resource,
            id,
        },
        extra: extraData,
    }
}

export const editLaborActionExtraArgs = (
    resource: ResourceType,
    listContext: ListControllerResult,
    id,
    disabledFields: boolean,
): DrawerState => ({
    drawerArgs: {
        renderTopRight: (render) =>
            render({
                disabled: disabledFields,
                title: disabledFields ? 'Reopen the WO to delete this item' : undefined,
                confirmConfig: {
                    title: 'Are you sure you want to delete this item?',
                },
            }),
        renderWrapper: (params) => (
            <UtilityDrawerEditor
                {...params}
                serializer={jobLaborSerializer}
            />
        ),
        title: disabledFields ? 'View Labor' : 'Edit Labor',
        renderContent: () => <JobLaborForm />,
        renderBottomRight: disabledFields ? () => null : undefined,
        renderBottomLeft: disabledFields ? (render) => render({ children: 'Close' }) : undefined,
    },
    extraArgs: {
        listContext,
        resource,
        id,
    },
    extra: {
        disabledFields,
    } as LaborFormExtra,
})
export const editVWOItemDrawerArgs = (
    name: string,
    resource: ResourceType,
    listContext: ListControllerResult,
    id: Identifier,
    disabledFields: boolean,
): DrawerState => ({
    drawerArgs: {
        renderTopRight: (render) =>
            render({
                disabled: disabledFields,
                title: disabledFields ? 'Reopen the WO to delete this item' : undefined,
                confirmConfig: {
                    title: 'Are you sure you want to delete this item?',
                },
            }),
        title: disabledFields ? `View ${name}` : `Edit ${name}`,
        renderWrapper: (args) => (
            <UtilityDrawerEditor
                serializer={jobItemSerializer}
                {...args}
            />
        ),
        renderContent: () => (
            <Stack spacing="10px">
                <TextareaInput
                    source="description"
                    label="Description"
                    disabled={disabledFields}
                    validate={descriptionValidation}
                />
                <TextInput
                    source="price"
                    label="Amount"
                    {...costMaskParams}
                    disabled={disabledFields}
                    validate={requiredValidation}
                />
            </Stack>
        ),
        renderBottomRight: disabledFields ? () => null : undefined,
        renderBottomLeft: disabledFields ? (render) => render({ children: 'Close' }) : undefined,
    },
    extraArgs: {
        listContext,
        resource,
        id,
    },
    extra: {
        disabledFields,
    } as WorkOrderItemsExtra,
})
