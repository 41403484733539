import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import {
    type CardListConfig,
    type DatagridColumnsProps,
    List,
    type ListSortContentProps,
} from 'components'
import { type ResourceType } from 'core'
import { deleteOneAction } from 'core/actions'
import { createdField } from 'resources/base'
import {
    ptDeleteTitleOnDisabled,
    PTPartDrawerToggler,
    ptPartFields,
    type PtPartModel,
} from 'resources/pricingTiers'
import { type ShopModel } from 'resourcesBase'

import { deletePartAction } from './utils'

const columnsCfg: DatagridColumnsProps<PtPartModel> = {
    checkboxSelection: false,
    columns: [
        {
            field: ptPartFields.name.source,
            headerName: ptPartFields.name.label,
            renderCell: ({ row }) => <ptPartFields.name.Value record={row} />,
        },
    ],
    actions: ({ row }, { children, resource }) => [
        <PTPartDrawerToggler
            key="edit"
            id={row.id}
            resource={resource.resource}
        >
            {(open) =>
                children({
                    title: 'Edit',
                    Icon: Icons.Edit,
                    onClick: open,
                })
            }
        </PTPartDrawerToggler>,
        deleteOneAction({
            children,
            id: row.id,
            disabled: row.isDefault,
            titleOnDisabled: ptDeleteTitleOnDisabled,
            ...deletePartAction(row),
        }),
    ],
}

const cardsCfg: CardListConfig<PtPartModel> = {
    titleSource: (record) => <ptPartFields.name.Value record={record} />,
    disableTitleLink: true,
    defaultImage: null,
    details: [],
    actions: (record, { children, resource }) => [
        <PTPartDrawerToggler
            key="edit"
            id={record.id}
            resource={resource.resource}
        >
            {(open) =>
                children({
                    title: 'Edit',
                    Icon: Icons.Edit,
                    key: 'edit',
                    onClick: open,
                })
            }
        </PTPartDrawerToggler>,
        deleteOneAction({
            children,
            id: record.id,
            disabled: record.isDefault,
            titleOnDisabled: ptDeleteTitleOnDisabled,
            ...deletePartAction(record),
        }),
    ],
}

const sortCfg: ListSortContentProps<PtPartModel> = {
    sortBy: [
        createdField.sort(),
        {
            id: ptPartFields.name.source,
            label: ptPartFields.name.label,
        },
    ],
}

const PartsList = ({ preferencesResource }: { preferencesResource: ResourceType }) => {
    const record = useRecordContext<ShopModel>()

    return (
        <List
            isLoading={!record}
            columnsCfg={columnsCfg}
            cardsCfg={cardsCfg}
            sortCfg={sortCfg}
            preferencesResource={preferencesResource}
            disableExportButton
            disableManageColumns
        />
    )
}

export default PartsList
