import { type ReactElement, useState, type FC } from 'react'

import { type StripeElementChangeEvent } from '@stripe/stripe-js'

import { Box, Typography } from 'ui'

interface InputWrapperProps {
    children: (params: { onChange: (e: StripeElementChangeEvent) => void }) => ReactElement
}

export const InputWrapper: FC<InputWrapperProps> = ({ children }) => {
    const [error, setError] = useState<StripeElementChangeEvent['error']>()

    const changeHandler = (e: StripeElementChangeEvent) => {
        setError(e.error)
    }

    return (
        <>
            <Box
                display="block"
                component="label"
                borderRadius="4px"
                padding="10px 10px"
                border="1px solid #0000003b"
            >
                {children({
                    onChange: changeHandler,
                })}
            </Box>
            <Typography
                height="40px"
                color="error.main"
                variant="caption"
                display="block"
            >
                {error?.message}
            </Typography>
        </>
    )
}
