import { type ReactElement, useState } from 'react'

import { type ListControllerResult, useRecordContext, useShowContext } from 'react-admin'
import { useFormContext } from 'react-hook-form'

import Icons from 'assets/icons'
import {
    useOpenUtilityDrawer,
    MultiFormatScanner,
    useUtilityDrawerContext,
    UtilityDrawerEditor,
} from 'components'
import { type ResourceType, useNotify } from 'core'
import { useDataProvider } from 'core/data'
import { useFinalErrorHandler } from 'core/errors'
import { renderOnPermission } from 'core/permissions'
import { useFlags } from 'lib'
import { JobLaborCreate, JobLaborForm } from 'pages/WorkOrders/Jobs/Labor'
import { type JobPartFormProps } from 'pages/WorkOrders/Jobs/Parts/JobPartForm'
import { type WorkOrderItemsExtra } from 'pages/WorkOrders/Jobs/config/constants'
import { partsResource, type PartModel } from 'resources/parts'
import {
    JobItemTypeKeys,
    type JobPartModel,
    type WorkOrderModel,
    type JobModel,
    useIsVenderWo,
    useWoActionIsDisabled,
    woJobItemPermissions,
} from 'resources/workOrders'
import { Button } from 'ui'

import {
    jobPartApiContext,
    JobPartForm,
    jobPartSerializer,
    jobPartWithoutNumberSerializer,
} from '../../../../Parts'

import PartWithoutNumberDrawerForm from './PartWithoutNumberDrawerForm'
export interface LaborFormExtra extends WorkOrderItemsExtra {
    job: JobModel
    workOrder: WorkOrderModel
}

export interface PartWithoutNumberFormExtra
    extends Pick<WorkOrderItemsExtra, 'job' | 'disabledFields'> {
    record?: JobPartModel
}

const PartScanner = () => {
    const [isOpen, setIsOpen] = useState(false)

    const dataProvider = useDataProvider()
    const close = () => {
        setIsOpen(false)
    }
    const notify = useNotify()
    const { setValue } = useFormContext()
    const errorHandler = useFinalErrorHandler()
    const { flag4047UpcScanner } = useFlags()
    const { extra } = useUtilityDrawerContext()
    const { job } = extra as WorkOrderItemsExtra

    return (
        <>
            <MultiFormatScanner
                isOpen={isOpen}
                close={close}
                onDecode={async (decodedText, isUpcFormat) => {
                    try {
                        let part: PartModel
                        if (isUpcFormat) {
                            part = (
                                await dataProvider.getList(partsResource.resource, {
                                    filter: {
                                        universalProductCode: decodedText,
                                        ...jobPartApiContext(job.id),
                                    },
                                    pagination: { perPage: 1, page: 1 },
                                    sort: null,
                                })
                            ).data[0]
                            if (!part) {
                                throw new Error()
                            }
                        } else {
                            const url = new URL(decodedText)
                            const pathnameParts = url.pathname.split('/')
                            // in case if the url ends with "/"
                            const id = pathnameParts.pop() || pathnameParts.pop()
                            if (!id) {
                                throw new Error()
                            }
                            part = (
                                await dataProvider
                                    .getOne<PartModel>(partsResource.resource, {
                                        id,
                                    })
                                    .catch((err) => {
                                        if (err.status === 404) {
                                            throw new Error()
                                        }
                                        errorHandler(err)
                                        return null
                                    })
                            )?.data
                        }

                        if (!part) {
                            return
                        }

                        setValue('part', part.id, {
                            shouldDirty: true,
                            shouldTouch: true,
                        })
                        notify('Part Selected')
                    } catch {
                        notify({
                            title: 'No Part information found on scanned code',
                            type: 'error',
                        })
                    } finally {
                        close()
                    }
                }}
            />
            <Button
                startIcon={<Icons.QrCodeScanner />}
                variant="text"
                size="small"
                onClick={() => setIsOpen(true)}
            >
                {flag4047UpcScanner ? 'SCAN BARCODE/QR CODE' : 'SCAN QR CODE'}
            </Button>
        </>
    )
}

export const PartCreateWithoutNumberDrawerToggler = renderOnPermission(
    ({
        children,
        resource,
        listContext,
    }: {
        children: (params: { onClick: () => void }) => ReactElement
        resource: ResourceType
        listContext: ListControllerResult
    }) => {
        const open = useOpenUtilityDrawer()
        const { record } = useShowContext<WorkOrderModel>()
        const disabled = useWoActionIsDisabled({ workOrder: record })
        const job = useRecordContext<JobModel>()
        const extra: PartWithoutNumberFormExtra = {
            disabledFields: disabled,
            job,
        }

        return children({
            onClick: disabled
                ? () => {
                      /* */
                  }
                : () => {
                      open({
                          extraArgs: {
                              listContext,
                              resource,
                          },
                          drawerArgs: {
                              title: 'Add Part (Without Part Number)',
                              renderWrapper: (params) => (
                                  <UtilityDrawerEditor
                                      {...params}
                                      serializer={jobPartWithoutNumberSerializer}
                                      defaultValues={{
                                          type: JobItemTypeKeys.PART,
                                      }}
                                  />
                              ),
                              renderContent: () => <PartWithoutNumberDrawerForm />,
                              renderBottomRight: (render) => render({ label: 'Add Part' }),
                          },
                          extra,
                      })
                  },
        })
    },
    woJobItemPermissions.addPart,
)
export const PartCreateDrawerToggler = renderOnPermission(
    ({
        title,
        children,
        resource,
        listContext,
        hideInputs,
    }: {
        children: (params: { onClick: () => void }) => ReactElement
        resource: ResourceType
        listContext: ListControllerResult
        title?: string
        hideInputs?: JobPartFormProps['hideInputs']
    }) => {
        const open = useOpenUtilityDrawer()
        const { record } = useShowContext<WorkOrderModel>()
        const disabled = useWoActionIsDisabled({ workOrder: record })
        const isVendorWo = useIsVenderWo()
        const job = useRecordContext<JobModel>()

        const extra: WorkOrderItemsExtra = {
            vendor: record.purchaseOrderData?.vendorData,
            job,
            workOrder: record,
        }

        return children({
            onClick: disabled
                ? () => {
                      /* */
                  }
                : () => {
                      open({
                          extraArgs: {
                              listContext,
                              resource,
                          },
                          drawerArgs: {
                              title: title || 'Add Part',
                              renderWrapper: (params) => (
                                  <UtilityDrawerEditor
                                      {...params}
                                      serializer={jobPartSerializer}
                                      defaultValues={{
                                          type: JobItemTypeKeys.PART,
                                      }}
                                  />
                              ),
                              renderContent: () => <JobPartForm hideInputs={hideInputs} />,
                              renderBottomRight: (render) => render({ label: 'Add Part' }),
                              renderTopRight: () => (isVendorWo ? null : <PartScanner />),
                          },
                          extra,
                      })
                  },
        })
    },
    woJobItemPermissions.addPart,
)

export const LaborCreateDrawerToggler = renderOnPermission(
    ({
        children,
        resource,
        listContext,
        job,
    }: {
        children: (params: { onClick: () => void }) => ReactElement
        resource: ResourceType
        listContext: ListControllerResult
        job: JobModel
    }) => {
        const open = useOpenUtilityDrawer()
        const { record } = useShowContext()
        const isVendorWo = useIsVenderWo()

        const disabled = useWoActionIsDisabled({ workOrder: record })

        return children({
            onClick: disabled
                ? () => {
                      /* */
                  }
                : () => {
                      open({
                          extraArgs: {
                              listContext,
                              resource,
                          },
                          drawerArgs: {
                              title: 'Add Labor',
                              renderWrapper: (params) => (
                                  <JobLaborCreate
                                      {...params}
                                      isVendorWo={isVendorWo}
                                  />
                              ),
                              renderContent: () => <JobLaborForm />,
                              renderBottomRight: (render) => render({ label: 'Add Labor' }),
                          },
                          extra: {
                              job,
                              workOrder: record,
                          } as LaborFormExtra,
                      })
                  },
        })
    },
    woJobItemPermissions.addLabor,
)
