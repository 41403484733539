import { styled, type Theme, generateNotForwardedProps } from 'lib'

interface FreeWoBarProps {
    statusColor: (theme: Theme) => string
}

const shouldForwardProp = generateNotForwardedProps<FreeWoBarProps>(['statusColor'])

const FreeWoBar = styled('div', {
    shouldForwardProp,
})<FreeWoBarProps>`
    flex-grow: 1;
    height: 6px;
    background-color: ${({ theme, statusColor }) => statusColor(theme)};
    border-radius: 4px;
`

export default FreeWoBar
