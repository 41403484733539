import { type ReactElement } from 'react'

import { inject, observer } from 'mobx-react'

import { InfoCard } from 'components'
import { type AuthStore } from 'core/auth'
import { renderOnBilling } from 'resources/billing'
import { type BillingSubscription } from 'resourcesBase'
import { Stack, Typography } from 'ui'

import { type PlanBodyProps, PlanBody, PlanTitle } from './components'

interface CurrentPlanProps extends Omit<PlanBodyProps, 'subscription'> {
    subscription?: BillingSubscription
    topTitle?: ReactElement
}

const CurrentPlan = inject('auth')(
    observer(
        ({
            auth,
            buttonProps,
            subscription,
            topTitle,
        }: { auth?: AuthStore } & CurrentPlanProps) => {
            const billing = auth.billing
            const subscriptionType = subscription || billing.subscription

            return (
                <InfoCard sx={{ padding: '20px' }}>
                    <Stack>
                        <Typography
                            variant="chartTitle"
                            color="text.secondary"
                        >
                            {topTitle || 'Current Plan'}
                        </Typography>

                        <PlanTitle subscription={subscriptionType} />
                        <PlanBody
                            buttonProps={buttonProps}
                            subscription={subscriptionType}
                        />
                    </Stack>
                </InfoCard>
            )
        },
    ),
)

export default renderOnBilling(CurrentPlan)
