import images from 'assets/images'
import { landingPageUrl } from 'configs'
import { withColor } from 'lib'
import { Box, StyledElement, StatusText, Typography, Button, Img } from 'ui'

const EnterpricePlan = () => {
    return (
        <Box
            bgcolor="white"
            borderRadius="4px"
            boxShadow={1}
            p="20px"
            color={withColor('text.primary')}
        >
            <StatusText statusColor={(theme) => theme.palette.text.primary}>
                Enterprise Pricing
            </StatusText>
            <Box
                mt="19px"
                mb="20px"
                display="flex"
                alignItems="center"
            >
                <Img
                    src={images.fleetpalBlack}
                    sx={{ width: '32px', height: '32px' }}
                />

                <Typography
                    variant="subtitle1"
                    ml="12px"
                    color="inherit"
                >
                    Fleetpal Enterprise
                </Typography>
            </Box>

            <Typography
                variant="h6"
                color="inherit"
                mb="29px"
            >
                Looking for something else?
            </Typography>

            <Typography
                color="inherit"
                variant="body2"
                mb="40px"
            >
                If you’re a specialized fleet or have over 100 units, you may qualify for{' '}
                <StyledElement
                    as="span"
                    sx={{ fontWeight: 'bold' }}
                >
                    Enterprise Pricing
                </StyledElement>
            </Typography>
            <a
                href={landingPageUrl.contact}
                target="_blank"
                rel="noreferrer"
            >
                <Button
                    variant="contained"
                    fullWidth
                    size="large"
                >
                    Contact Sales Now
                </Button>
            </a>
        </Box>
    )
}

export default EnterpricePlan
