import { type FC } from 'react'

import { type MenuItemRenderIconParams } from 'pages/layouts'
import { useTelematicsContext } from 'resources/telematics'

const ConfigureMenuIcon: FC<MenuItemRenderIconParams> = ({ renderBadge, renderIconView }) => {
    const { errorCount, hasUnseenVehicles } = useTelematicsContext()

    if (errorCount || hasUnseenVehicles) {
        return renderBadge({
            color: errorCount ? 'error' : 'primary',
        })
    }

    return renderIconView()
}

export default ConfigureMenuIcon
