import { ResourceWithClearEffect } from 'core'
import { poResource } from 'resources/purchaseOrders'

import { PurchaseOrdersList } from './List'
import { PurchaseOrderShow } from './Show'

const PurchaseOrders = () => {
    return (
        <ResourceWithClearEffect
            name={poResource}
            list={PurchaseOrdersList}
            edit={PurchaseOrderShow}
        />
    )
}

export default PurchaseOrders
