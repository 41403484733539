import {
    type FC,
    type ForwardRefExoticComponent,
    type MouseEvent,
    type ReactElement,
    type ReactNode,
} from 'react'

import { inject, observer } from 'mobx-react'

import { NavLink } from 'components'
import { type AuthStore, ActionsMenu, type BaseActionRenderParams } from 'core'
import { alpha, useFlags, type Flags } from 'lib'
import {
    ListItemButton,
    ListItemText,
    ListItemIcon,
    type SvgIconProps,
    Badge,
    type BadgeProps,
    badgeClasses,
    Typography,
} from 'ui'
import { pathJoin } from 'utils'

import SidebarHide from './SidebarHide'
import SidebarTooltip from './SidebarTooltip'

export interface MenuItemRenderIconParams {
    renderIconView: (params?: SvgIconProps) => ReactElement
    renderBadge: (params?: BadgeProps) => ReactElement
}

type ExtendedValue<T> = T | ((params: { flags: Flags }) => T)

export type MenuItemInterface = {
    hide?: (params: { flags: Flags; auth: AuthStore }) => boolean
    key: string
    to: string
    primary: string
    submenu?: ExtendedValue<
        (Omit<BaseActionRenderParams, 'key' | 'to' | 'Icon'> & {
            key: string
            to: string
            hide?: (params: { auth: AuthStore; flags: Flags }) => boolean
        })[]
    >
    icon: FC
    tooltip?: ExtendedValue<string>
    disabled?: ExtendedValue<boolean>
    renderIcon?: (params: MenuItemRenderIconParams) => ReactNode
}
const SidebarNavItemContent = ({
    onClick,
    className,
    ...props
}: Omit<MenuItemInterface, 'submenu'> & {
    onClick?: (event: MouseEvent<HTMLElement>) => void
    className?: string
}) => {
    const { icon, tooltip, primary, disabled, renderIcon, ...rest } = props
    const flags = useFlags()
    const Icon = icon
    const renderIconView = (params?: SvgIconProps) => <Icon {...params} />
    return (
        <SidebarTooltip
            title={
                tooltip ? (typeof tooltip === 'function' ? tooltip({ flags }) : tooltip) : primary
            }
            placement="right"
            arrow
        >
            <li>
                <ListItemButton
                    component={NavLink as ForwardRefExoticComponent<any>}
                    {...rest}
                    sx={{ p: '14px 16px', border: 0 }}
                    activeClassName="MuiNavDrawerActive"
                    className={className}
                    onClick={onClick}
                    disabled={typeof disabled === 'function' ? disabled({ flags }) : disabled}
                    aria-label={`Go to ${primary}`}
                >
                    <ListItemIcon
                        sx={(theme) => ({
                            padding: 0,
                            color: alpha(theme.palette.text.main, 0.54),
                        })}
                    >
                        {renderIcon
                            ? renderIcon({
                                  renderIconView,
                                  renderBadge: (params = {}) => (
                                      <Badge
                                          color="primary"
                                          variant="dot"
                                          sx={{
                                              [`& .${badgeClasses.badge}`]: {
                                                  mt: '2px',
                                                  mr: '2px',
                                              },
                                          }}
                                          {...params}
                                          children={params.children || renderIconView()}
                                      />
                                  ),
                              })
                            : renderIconView()}
                    </ListItemIcon>
                    <SidebarHide>
                        <ListItemText
                            disableTypography
                            sx={{ margin: 0 }}
                        >
                            <Typography variant="body1">{primary}</Typography>
                        </ListItemText>
                    </SidebarHide>
                </ListItemButton>
            </li>
        </SidebarTooltip>
    )
}

const SidebarNavItem = inject('auth')(
    observer(({ submenu, hide, auth, ...rest }: MenuItemInterface & { auth: AuthStore }) => {
        const flags = useFlags()

        if (hide?.({ flags, auth })) {
            return null
        }

        const menu = (typeof submenu === 'function' ? submenu({ flags }) : submenu)?.filter?.(
            Boolean,
        )

        if (menu?.length) {
            return (
                <ActionsMenu
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    renderToggler={(open, isOpen) => (
                        <SidebarNavItemContent
                            className={isOpen ? 'MuiNavDrawerActive' : ''}
                            onClick={(event: MouseEvent<HTMLElement>) => {
                                open(event)
                                event.preventDefault()
                            }}
                            {...rest}
                        />
                    )}
                    actions={(params, { children }) =>
                        menu.map(({ to = '', hide, ...item }) =>
                            hide?.({ auth, flags })
                                ? null
                                : children({ to: pathJoin(to), ...item, Icon: null }),
                        )
                    }
                />
            )
        }
        return <SidebarNavItemContent {...rest} />
    }),
) as FC<MenuItemInterface>

export default SidebarNavItem
