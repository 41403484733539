import { Header } from 'components'
import { urls } from 'configs'

const ChangePlanHeader = () => {
    return (
        <Header fixed>
            <Header.Block>
                <Header.BackButton to={urls.company} />
                <Header.Main>
                    <Header.Title>Change Plan</Header.Title>
                </Header.Main>
            </Header.Block>
        </Header>
    )
}

export default ChangePlanHeader
