import { useEffect, useRef, useState } from 'react'

import { type ListControllerResult } from 'react-admin'

import { ListViewMode } from './ListViewContext'

const useFirstListLoad = (
    listContext: ListControllerResult,
    viewMode: ListViewMode,
    loading: boolean,
) => {
    const [, rerender] = useState(true)
    const { isLoading } = listContext
    const firstLoad = useRef(true)
    useEffect(() => {
        if (!isLoading && firstLoad.current && !loading) {
            rerender((p) => !p)
            firstLoad.current = false
        }
    }, [isLoading, loading])

    return viewMode === ListViewMode.grid ? loading || isLoading : firstLoad.current
}

export default useFirstListLoad
