import { type FC } from 'react'

import { type BillingInvoice } from 'resources/billing'
import { GridContainerGrid, GridItem } from 'ui'

import PymentHistoryCard from './PymentHistoryCard'

interface Props {
    data: BillingInvoice[]
}

const PaymentHistoryContent: FC<Props> = ({ data }) => {
    return (
        <GridContainerGrid>
            {data.map((invoice) => (
                <GridItem
                    sm={6}
                    md={4}
                    lg={3}
                    key={invoice.id}
                >
                    <PymentHistoryCard invoice={invoice} />
                </GridItem>
            ))}
        </GridContainerGrid>
    )
}

export default PaymentHistoryContent
