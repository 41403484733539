import { type BillingSubscription } from 'resourcesBase'
import { StyledElement } from 'ui'

import { ProPlan, EarlyAccess } from './Plans'
import { type EarlyAccessProps } from './Plans/EarlyAccess/EarlyAccess'

export interface PlanBodyProps extends EarlyAccessProps {
    subscription: BillingSubscription
}

const PlanBody = ({ buttonProps, subscription }: PlanBodyProps) => {
    return (
        <StyledElement sx={{ mt: '20px' }}>
            {subscription === 'free' ? <EarlyAccess buttonProps={buttonProps} /> : <ProPlan />}
        </StyledElement>
    )
}

export default PlanBody
