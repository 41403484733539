import { type FC } from 'react'

import { observer } from 'mobx-react'

import Icons from 'assets/icons'
import { InfoCard, InfoCardHeader } from 'components'
import { urls } from 'configs'
import { useCompanyTaskSync, useConfirm } from 'core'
import { useNavigate } from 'lib'
import {
    type IntegrationConnectConfigurations,
    type Telematics,
    type TelematicsExtra,
} from 'resources/telematics'
import {
    BoxContainer,
    StatusText,
    type StatusTextProps,
    Typography,
    Spacer,
    GridItemCard,
    Img,
    Box,
    SvgIcon,
    Tooltip,
    Stack,
} from 'ui'

import { ConnectButtonDrawer, ConnectButtonLink } from './ConnectButton'
import ConnectedCardContent from './ConnectedCardContent'
import SyncingCardContent from './SyncingCardContent'

interface IntegrationCardProps {
    integration: TelematicsExtra & Telematics
    onDisconnect: () => void
    onManualSync: () => void
}

const IntegrationCard: FC<IntegrationCardProps> = observer(
    ({ integration, onDisconnect, onManualSync }) => {
        const navigate = useNavigate()
        const confirm = useConfirm()
        const { logo: image, isActive, error, errorType, provider, providerName } = integration

        const syncing = useCompanyTaskSync((tasks) => {
            return tasks.some((item) => item.startsWith(provider))
        })

        let statusProps: StatusTextProps = {
            children: 'Not Connected',
            statusColor: (theme) => theme.palette.text.secondary,
        }

        if (syncing) {
            statusProps = {
                children: 'Sync in Progress',
                statusColor: (theme) => theme.palette.primary.main,
            }
        } else if (isActive) {
            if (error) {
                statusProps = {
                    children: 'Error',
                    statusColor: (theme) => theme.palette.charts.red,
                }
            } else {
                statusProps = {
                    children: 'Connected',
                    statusColor: (theme) => theme.palette.charts.greenBody,
                }
            }
        }
        return (
            <GridItemCard>
                <InfoCard
                    sx={{
                        width: '100%',
                        height: '100%',
                        minHeight: '150px',
                    }}
                >
                    <Stack
                        justifyContent="space-between"
                        height="100%"
                    >
                        <InfoCardHeader
                            noDivider
                            actions={(params, { children }) =>
                                isActive
                                    ? [
                                          syncing
                                              ? undefined
                                              : children({
                                                    Icon: Icons.Sync,
                                                    key: 'manual-sync',
                                                    title: 'Manual Sync',
                                                    onClick: onManualSync,
                                                }),
                                          syncing
                                              ? undefined
                                              : children({
                                                    Icon: Icons.Settings,
                                                    key: 'manage',
                                                    disabled: Boolean(error),
                                                    title: 'Manage',
                                                    onClick: () => {
                                                        navigate(
                                                            urls.configure +
                                                                '/sync-vehicles/' +
                                                                integration.provider,
                                                        )
                                                    },
                                                }),

                                          children({
                                              Icon: Icons.LinkOffOutlined,
                                              key: 'disconnect',
                                              title: 'Disconnect',
                                              onClick: () =>
                                                  confirm({
                                                      title: 'Are you sure you want to Disconnect?',
                                                      content:
                                                          "Your units will remain in Fleetpal's system.",
                                                      confirmButtonProps: {
                                                          startIcon: <Icons.LinkOffOutlined />,
                                                          children: 'Disconnect',
                                                          color: 'error',
                                                          variant: 'text',
                                                      },
                                                      onConfirm: onDisconnect,
                                                  }),
                                              itemColor: (theme) => theme.palette.error.main,
                                          }),
                                      ]
                                    : []
                            }
                            title={
                                <BoxContainer
                                    justifyContent="space-between"
                                    // TODO: card actions should have some space from the left side
                                    mr={isActive ? '8px' : undefined}
                                >
                                    <Spacer>
                                        <Img
                                            sx={{
                                                width: '24px',
                                                height: '24px',
                                                maxWidth: '100%',
                                            }}
                                            src={image}
                                        />

                                        <Typography variant="subtitle1">{providerName}</Typography>
                                    </Spacer>
                                    <BoxContainer>
                                        {error ? (
                                            <Tooltip
                                                title={
                                                    errorType === 'authentication'
                                                        ? 'Disconnect and reconnect using updated credentials to resolve error'
                                                        : error
                                                }
                                            >
                                                <SvgIcon
                                                    component={Icons.InfoOutlined}
                                                    sx={{
                                                        width: '16px',
                                                        height: '16px',
                                                        mr: '4px',
                                                        color: statusProps.statusColor,
                                                    }}
                                                />
                                            </Tooltip>
                                        ) : null}
                                        <StatusText {...statusProps} />
                                    </BoxContainer>
                                </BoxContainer>
                            }
                        />
                        <Box>
                            {(syncing && <SyncingCardContent />) ||
                                (isActive ? (
                                    <ConnectedCardContent integration={integration} />
                                ) : (
                                      integration.connect as IntegrationConnectConfigurations['link']
                                  ).url ? (
                                    <ConnectButtonLink integration={integration} />
                                ) : (
                                    <ConnectButtonDrawer integration={integration} />
                                ))}
                        </Box>
                    </Stack>
                </InfoCard>
            </GridItemCard>
        )
    },
)

export default IntegrationCard
