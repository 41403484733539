import { useFormContext } from 'react-hook-form'

import { costMaskParams, inputQuantityMaskParams, TextareaInput, TextInput } from 'components'
import { maxLengthValidationText, requiredValidation } from 'core'
import { CK15Input, CK18Input, CK33InputWithoutParts } from 'resources/vmrs'
import { SectionTitleSmall } from 'ui'

import { type InvoiceLabor } from '../../types'

export interface InvoiceLaborFormProps {
    isDisabled?: (source: keyof InvoiceLabor) => boolean
    isHidden?: (source: keyof InvoiceLabor) => boolean
}

const defaultDisabled = () => false

const descriptionValidation = [maxLengthValidationText, requiredValidation]

const InvoiceLaborForm = ({ isDisabled = defaultDisabled, isHidden }: InvoiceLaborFormProps) => {
    return (
        <>
            <SectionTitleSmall>Basic Details</SectionTitleSmall>

            {isHidden?.('component') ? null : (
                <CK33InputWithoutParts
                    disabled
                    required
                />
            )}

            <TextareaInput
                source="description"
                label="Description"
                disabled={isDisabled('description')}
                validate={descriptionValidation}
            />

            {isHidden?.('cause') ? null : (
                <CK18Input
                    source="cause"
                    disabled
                    label="Cause"
                />
            )}

            {isHidden?.('correction') ? null : <CK15Input disabled />}

            <TextInput
                source="hourlyRate"
                label="Hourly Labor Rate"
                {...costMaskParams}
                disabled
            />

            <SaleRateInput disabled={isDisabled('saleRate')} />

            {isHidden?.('woHours') ? null : (
                <TextInput
                    source="woHours"
                    label="Work Order Qty/Hours"
                    {...inputQuantityMaskParams}
                    disabled
                />
            )}

            <TextInput
                source="qtyHours"
                label="Qty/Hours to Invoice"
                disabled={isDisabled('qtyHours')}
                {...inputQuantityMaskParams}
                validate={requiredValidation}
            />
        </>
    )
}

export const SaleRateInput = ({ disabled }: { disabled: boolean }) => {
    const { watch, getValues } = useFormContext()

    const hourlyRate = watch('hourlyRate') | getValues('hourlyRate')
    const saleRate = watch('saleRate') | getValues('saleRate')

    return (
        <TextInput
            source="saleRate"
            label="Labor Sale Rate"
            {...costMaskParams}
            disabled={disabled}
            helperText={saleRate < hourlyRate ? 'Labor sale rate below hourly labor rate' : ''}
            validate={requiredValidation}
        />
    )
}
export default InvoiceLaborForm
