import Icons from 'assets/icons'

import { type IssueModel } from '../types'

const avatar = {
    source: 'avatar' as keyof IssueModel,
    label: 'Avatar',
    Icon: Icons.Issues,
}

export default avatar
