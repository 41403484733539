import { type FC } from 'react'

import Icons from 'assets/icons'
import { ActionsMenu, type MenuActionProps } from 'core'

import { InvoiceLaborDrawer } from './InvoiceLaborDrawer'
import InvoicePartDrawer from './InvoicePartDrawer'
import InvoiceServiceDrawer from './InvoiceServiceDrawer'

interface Props extends Pick<MenuActionProps, 'renderToggler'> {}

const ItemsAddMenu: FC<Props> = ({ renderToggler }) => {
    return (
        <ActionsMenu
            renderToggler={renderToggler}
            actions={(_, { children }) => [
                <InvoicePartDrawer key="add-part">
                    {(open) => children({ Icon: Icons.Part, title: 'Add Part', onClick: open })}
                </InvoicePartDrawer>,
                <InvoiceLaborDrawer key="add-labor">
                    {(open) =>
                        children({
                            Icon: Icons.Part,
                            title: 'Add Labor',
                            key: 'add-labor',
                            onClick: open,
                        })
                    }
                </InvoiceLaborDrawer>,
                <InvoiceServiceDrawer key="add-service">
                    {(open) => children({ Icon: Icons.Part, title: 'Add Service', onClick: open })}
                </InvoiceServiceDrawer>,
            ]}
        />
    )
}

export default ItemsAddMenu
