import { type ReactElement } from 'react'

import { useElements, useStripe } from '@stripe/react-stripe-js'

import { type UtilityDrawerCancelButtonProps } from 'components'

import { usePaymentFormIsLoading } from './PaymetFormContext'

export const CancelButton = ({
    render,
}: {
    render: (params: UtilityDrawerCancelButtonProps) => ReactElement
}) => {
    const stripe = useStripe()
    const elements = useElements()
    const isLoading = usePaymentFormIsLoading()
    const disabled: boolean = !stripe || !elements || isLoading

    return render({ disabled })
}
