import { CheckboxInput } from 'components'

const InvoicingSettingsForm = ({ disabled }: { disabled: boolean }) => {
    return (
        <>
            <CheckboxInput
                source="invoiceOnWorkOrderClose"
                label="Invoice on WO close"
                disabled={disabled}
            />
            <CheckboxInput
                source="printoutIncludeCompanyLogo"
                label="Show Company Logo in PDF"
                disabled={disabled}
            />
            <CheckboxInput
                source="printoutShowPartNumbers"
                label="Show Part Number in PDF"
                disabled={disabled}
            />
        </>
    )
}
export default InvoicingSettingsForm
