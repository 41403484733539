import { type FC } from 'react'

import { inputPriceMaskParams, TextInput } from 'components'
import { requiredValidation } from 'core'
import { isInputDisabledDefault } from 'utils'

interface Props {
    isInputDisabled?: (source: string) => boolean
}

const ManageTaxForm: FC<Props> = ({ isInputDisabled = isInputDisabledDefault }) => {
    return (
        <>
            <TextInput
                source="name"
                label="Tax Name"
                disabled={isInputDisabled('name')}
            />

            <TextInput
                source="total"
                label="Tax Amount"
                validate={requiredValidation}
                disabled={isInputDisabled('total')}
                {...inputPriceMaskParams}
            />
        </>
    )
}

export default ManageTaxForm
