import { type DataRecord, type NoInfer } from 'appTypes'
import {
    type Column,
    type DataListDetails,
    type ListFilterChoices,
    ListFilterValueInput,
    type SortBy,
    prepareFilterResource,
} from 'components'
import { authStore } from 'core/auth'

import { CK33Label } from '../components/CK33Label'
import { type CK33Model } from '../types'
import { type CK33Levels, ck33Resource, getCk33Info } from '../utils'

const formatCK33CodeByLevel = (code: string, level: number) => {
    if (level === 0) {
        // the middle symbol in the first part is the group code. E.g. 010-000-000
        return code[1]
    }
    return code
        .split('-')
        .filter((_, i) => i < level)
        .join('-')
}

const displayCK33 = (record: CK33Model) => {
    if (!record) {
        return ''
    }
    return formatCK33CodeByLevel(record.code, record.level) + ' ' + record.text
}

interface CK33FilterParams<RecordType extends DataRecord>
    extends Pick<ListFilterChoices<RecordType>[number], 'id' | 'label'> {
    makeItemLabel?: (record: CK33Model) => string
}

const filter = <RecordType extends DataRecord>({
    id,
    label,
    makeItemLabel = displayCK33,
}: CK33FilterParams<RecordType>): ListFilterChoices<RecordType>[number] => ({
    id,
    label,
    renderComponent: (props) => (
        <ListFilterValueInput
            dataProviderProps={{
                getList: {
                    prepareResource: prepareFilterResource,
                },
                getMany: {
                    prepareResource: () => ck33Resource.resource,
                },
            }}
            {...props}
            makeItemLabel={makeItemLabel}
        />
    ),
})

const source = 'component' as const

const dataCardRow = <Source extends string = 'component'>({
    id,
    dataToRecord,
    maxLevel,
}: {
    id?: Source
    dataToRecord: (data) => CK33Model
    maxLevel?: CK33Levels
}) =>
    ({
        source: (id || 'component') as NoInfer<Source>,
        label: <CK33Label excludeLevelAndBelow={maxLevel} />,
        render: (_, data) => displayCK33(dataToRecord(data)),
    }) as const satisfies DataListDetails<any, any>

const gridColumn = <Source extends string = 'component'>({
    id,
    maxLevel,
    dataToRecord,
}: {
    id?: Source
    maxLevel?: CK33Levels
    dataToRecord: (data: DataRecord) => CK33Model
}) =>
    ({
        field: (id || 'component') as NoInfer<Source>,
        headerName: getCk33Info(authStore, maxLevel).label,
        renderCell: ({ row }) => displayCK33(dataToRecord(row)),
    }) as const satisfies Column

const sort = <Source extends string = 'component'>({
    id,
    maxLevel,
}: { id?: Source; maxLevel?: CK33Levels } = {}) =>
    ({
        id: (id || 'component') as NoInfer<Source>,
        label: getCk33Info(authStore, maxLevel).label,
    }) as const satisfies SortBy

const self = {
    source,
    sort,
    gridColumn,
    dataCardRow,
    filter,
    value: displayCK33,
    formatCode: formatCK33CodeByLevel,
}

export default self
