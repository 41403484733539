import { useRecordContext } from 'react-admin'

import { Order } from 'appTypes'
import { ListBase } from 'components'
import { ResourceContextProviderWithClearEffect, type SortPayload } from 'core'
import { getPtTaxResource, type PtTaxModel, ptTaxPreferencesResource } from 'resources/pricingTiers'
import { type ShopModel } from 'resourcesBase'

import { ListHeader, TaxesList } from './components'

const PricingTiersTaxes = () => {
    const record = useRecordContext<ShopModel>()

    return (
        <ResourceContextProviderWithClearEffect value={getPtTaxResource(record?.id)}>
            <ListBase
                sort={defaultSort}
                isLoading={!record}
                preferencesResource={ptTaxPreferencesResource}
            >
                <ListHeader />
                <TaxesList preferencesResource={ptTaxPreferencesResource} />
            </ListBase>
        </ResourceContextProviderWithClearEffect>
    )
}

export default PricingTiersTaxes

const defaultSort: SortPayload<PtTaxModel> = {
    field: 'created',
    order: Order.DESC,
}
