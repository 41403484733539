import { type FC } from 'react'

import Icons from 'assets/icons'
import { AddButton, CollapsibleInfoCard, InfoCardRowBase, InfoCardTitle } from 'components'
import { ItemsTotal } from 'resources/jobs'
import { Box, Button } from 'ui'

import { ItemsAddMenu, ItemsContent } from '../../components'

const DirectlyAddedCard: FC = () => {
    return (
        <CollapsibleInfoCard
            disableActiveState
            defaultOpen
        >
            <InfoCardRowBase label={<InfoCardTitle>Directly Added</InfoCardTitle>}>
                <ItemsAddMenu renderToggler={(open) => <AddButton onClick={open} />} />
            </InfoCardRowBase>
            <ItemsContent />
            <Box py="20px">
                <ItemsAddMenu
                    renderToggler={(open) => (
                        <Button
                            size="large"
                            startIcon={<Icons.Add />}
                            onClick={open}
                        >
                            add line item
                        </Button>
                    )}
                />
            </Box>
            <ItemsTotal
                sx={{ mb: 0 }}
                total={100}
            />
        </CollapsibleInfoCard>
    )
}

export default DirectlyAddedCard
