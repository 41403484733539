import { type FC, type ReactElement } from 'react'

import { useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'

import ManageFeeForm from './ManageFeeForm'

interface Props {
    children: (params: () => void) => ReactElement
}

const ManageFeeDrawerToggler: FC<Props> = ({ children }) => {
    const open = useOpenUtilityDrawer()

    const readOnly = false

    return children(() => {
        open({
            drawerArgs: {
                title: readOnly ? 'View Fee' : 'Edit Fee',
                renderWrapper: (params) => <UtilityDrawerEditor {...params} />,
                renderContent: () => <ManageFeeForm isInputDisabled={() => readOnly} />,
                renderTopRight: readOnly ? null : (render) => render(),
                renderBottomRight: readOnly ? null : (render) => render(),
            },
        })
    })
}

export default ManageFeeDrawerToggler
