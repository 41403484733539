import { useResourceContext } from 'react-admin'

import Icons from 'assets/icons'
import { ViewHeader } from 'components'
import { PTPartDrawerToggler } from 'resources/pricingTiers'
import { FixedOnMobile, IconBox, Button } from 'ui'

const ListHeader = () => {
    const resource = useResourceContext()
    return (
        <ViewHeader
            title="Customer Price Tiers"
            pt="28px"
            pb="20px"
        >
            <ViewHeader.Content>
                <IconBox title="Personalize your pricing approach by segmenting customers into tiers, customizing markups on individual parts, and easily providing either discounts or premium pricing to align with different customer requirements and increase profitability.">
                    <Icons.InfoOutlined />
                </IconBox>
            </ViewHeader.Content>
            <ViewHeader.Content placement="right">
                <FixedOnMobile>
                    <PTPartDrawerToggler resource={resource}>
                        {(open) => (
                            <Button
                                onClick={open}
                                variant="contained"
                                startIcon={<Icons.Add />}
                            >
                                create
                            </Button>
                        )}
                    </PTPartDrawerToggler>
                </FixedOnMobile>
            </ViewHeader.Content>
        </ViewHeader>
    )
}

export default ListHeader
