import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import TagManager from 'react-gtm-module'

import { pathJoin } from 'utils'

const env = {
    _get(name: string, defaultValue: any): any {
        return process.env[`REACT_APP_${name}`] || defaultValue
    },
    _parse(name: string, defaultValue: any): any {
        const val = this._get(name)
        return val ? JSON.parse(val) : defaultValue
    },
    str(name: string, defaultValue: string): string {
        return this._get(name, defaultValue)
    },
    bool(name: string, defaultValue: boolean): boolean {
        return this._parse(name, defaultValue)
    },
}

const VERSION = env.str('VERSION', '')

const ENVIRONMENT = env.str('ENVIRONMENT', 'local')

const GOOGLE_TAG_MANAGER_ID = env.str('GOOGLE_TAG_MANAGER_ID', '')
if (GOOGLE_TAG_MANAGER_ID) {
    TagManager.initialize({ gtmId: GOOGLE_TAG_MANAGER_ID })
}
const intercomKey = env.str('INTERCOM_KEY', '')

export const supportLauncherSelector = 'support-launcher'

if (intercomKey) {
    window.intercomSettings = {
        // eslint-disable-next-line camelcase
        api_base: 'https://api-iam.intercom.io',
        // eslint-disable-next-line camelcase
        app_id: intercomKey,
        // eslint-disable-next-line camelcase
        custom_launcher_selector: '.' + supportLauncherSelector,
    }
}

const sentryDsn = env.str('SENTRY_DSN', '')
let _sentry: typeof Sentry | null = null
if (sentryDsn) {
    _sentry = Sentry
    _sentry.init({
        dsn: sentryDsn,
        release: VERSION,
        autoSessionTracking: false,
        integrations: [new Integrations.BrowserTracing()],
        environment: ENVIRONMENT,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    })
}

const LANDING_PAGE_URL = 'https://fleetpal.io'

export const landingPageUrl = {
    root: LANDING_PAGE_URL,
    contact: pathJoin(LANDING_PAGE_URL, 'contact'),
    pricing: pathJoin(LANDING_PAGE_URL, 'pricing'),
}

export const config = {
    DEBUG: env.bool('DEBUG', false),
    LAUNCH_DARKLY_CLIENT_ID: env.str('LAUNCH_DARKLY_CLIENT_ID', ''),
    API_ENDPOINT: env.str('API_ENDPOINT', 'http://localhost:8000'),
    API_TOKEN_KEY: 'api_token',
    VAPID_PUBLIC_KEY: env.str('VAPID_PUBLIC_KEY', ''),
    GOOGLE_CLIENT_ID: env.str('GOOGLE_CLIENT_ID', ''),
    STRIPE_PUBLIC_KEY: env.str('STRIPE_PUBLIC_KEY', ''),
    GOOGLE_MAP_API_KEY: env.str('GOOGLE_MAP_API_KEY', ''),
    MUI_X_KEY: env.str('MUI_X_KEY', ''),
    SENTRY: _sentry,
    SAMSARA_APP_ID: env.str('SAMSARA_APP_ID', ''),
    MOTIVE_APP_ID: env.str('MOTIVE_APP_ID', ''),
    VERSION,
}

export const urls = {
    root: '/',
    login: '/login',
    logout: '/logout',
    setPassword: '/set-password',
    passwordReset: '/reset-password',
    passwordResetDone: '/reset-password/done',
    passwordResetConfirm: '/reset-password/:uid/:token',
    userProfile: '/profile',
    tos: 'https://www.iubenda.com/terms-and-conditions/56685224',
    repairShops: '/repair-shops',
    units: '/units',
    unitsArchived: '/units/archived',
    workOrders: '/work-orders',
    issues: '/issues',
    inventory: '/inventory',
    purchaseOrders: '/purchase-orders',
    configure: '/configure',
    members: '/members',
    company: '/company',
    customers: '/customers',
    changePlan: '/change-plan',
    parts: '/parts',
    vendorParts: '/vendor-parts',
    invitationsExpired: '/invitations/expired',
    invitationsAccept: '/invitations/accept/:token',
    invitationAuth: '/invitations/auth/:token',
    deactivate: '/deactivate',
    deactivated: '/deactivated',
    paymentHistory: '/payment-history',
    invoices: '/invoices',

    error404: '/error404',
    blank: '/notfound',
    reports: '/reports',
    vendors: '/vendors',
}

export const supportEmail = 'support@fleetpal.io'
