import { inject, observer } from 'mobx-react'

import Icons from 'assets/icons'
import { type AuthStore } from 'core/auth'
import { type BillingModel } from 'resourcesBase'
import { StyledElement, Typography } from 'ui'

export type TooltipPlanType = string

interface TooltipDescriptionProps {
    subscription: BillingModel['subscription']
}
const TooltipDescription = inject('auth')(
    observer(({ subscription, auth }: TooltipDescriptionProps & { auth?: AuthStore }) => {
        const freeWOCount = auth.billing.initialFreeWorkOrdersCount

        const planFeatures =
            subscription === 'free'
                ? [
                      freeWOCount + ' Work Orders Instantly on Signup',
                      'Parts Manager',
                      'Units Manager',
                      'Vendors Manager',
                      'Unlimited Units',
                      'Unlimited Users',
                  ]
                : [
                      'Unlimited Work Orders',
                      '24/7 Customer Support',
                      'NFC Keychain',
                      freeWOCount + ' Work Orders Instantly on Signup',
                      'Parts Manager',
                      'Units Manager',
                      'Vendors Manager',
                      'Unlimited Units',
                      'Unlimited Users',
                  ]

        return (
            <>
                {planFeatures.map((feature) => (
                    <StyledElement
                        key={feature}
                        sx={{ display: 'flex', width: '100%' }}
                    >
                        <Icons.Check color="success" />
                        <Typography
                            sx={{ ml: '6px', mt: '3px' }}
                            variant="body2"
                            color="text.primary"
                        >
                            {feature}
                        </Typography>
                    </StyledElement>
                ))}
            </>
        )
    }),
)

export default TooltipDescription
