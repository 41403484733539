import { type FC, type ReactElement } from 'react'

import { useRecordContext } from 'react-admin'

import { type Identifier } from 'appTypes'
import { useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'
import {
    type InvoiceModel,
    invoicesResource,
    InvoiceStatusKeys,
    InvoiceTypeKeys,
} from 'resources/invoices'

import PartForm, { type PartFormProps } from './PartForm'

interface InvoicePartDrawerProps extends Pick<PartFormProps, 'isDisabled'> {
    children: (open: () => void) => ReactElement
    id?: Identifier
    type?: InvoiceTypeKeys
}

const InvoicePartDrawer = ({
    id,
    children,
    type = InvoiceTypeKeys.DIRECT,
    isDisabled,
}: InvoicePartDrawerProps) => {
    const open = useOpenUtilityDrawer()

    return children(() => {
        open({
            extraArgs: {
                resource: invoicesResource,
                id,
            },
            drawerArgs: {
                title: id ? <EditTitle /> : 'Create Part',
                renderWrapper: (props) => <UtilityDrawerEditor {...props} />,
                renderContent: () => (
                    <FormContent
                        isDisabled={isDisabled}
                        id={id}
                        type={type}
                    />
                ),
                renderBottomRight: (renderButton) => renderButton(),
            },
        })
    })
}

export default InvoicePartDrawer

const EditTitle: FC = () => {
    const record = useRecordContext<InvoiceModel>()

    if (!record) {
        return null
    }

    if (record.status !== InvoiceStatusKeys.OPEN) {
        return <>View Part</>
    }

    return <>Edit Part</>
}

interface FormContentProps extends Pick<PartFormProps, 'isDisabled'> {
    id?: Identifier
    type: InvoiceTypeKeys
}

const FormContent: FC<FormContentProps> = ({ isDisabled, id, type: typeProp }) => {
    const record = useRecordContext<InvoiceModel>()

    if (id && !record) {
        return null
    }

    const type = record?.type || typeProp

    return (
        <PartForm
            isHidden={(source) => {
                if (type === InvoiceTypeKeys.DIRECT) {
                    return source === 'woQuantity'
                }
            }}
            isDisabled={(source) => {
                if (!id) {
                    return isDisabled?.(source)
                }

                if (record?.status !== InvoiceStatusKeys.OPEN) {
                    return true
                }
            }}
        />
    )
}
