import { useMemo } from 'react'

import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js'
import { type StripeElementStyle } from '@stripe/stripe-js'

import { useTheme } from 'lib'
import { GridContainerColumns, GridItem } from 'ui'

import { InputWrapper } from './InputWrapper'

export const FormContent = () => {
    const theme = useTheme()

    const style: StripeElementStyle = useMemo(
        () => ({
            base: {
                ...theme.typography.inputText,
                color: theme.palette.text.main,
            },
            invalid: {
                color: theme.palette.error.main,
            },
        }),
        [],
    )

    return (
        <>
            <InputWrapper>
                {({ onChange }) => (
                    <CardNumberElement
                        options={{
                            showIcon: true,
                            style,
                        }}
                        onChange={onChange}
                    />
                )}
            </InputWrapper>
            <div />
            <GridContainerColumns>
                <GridItem xs={6}>
                    <InputWrapper>
                        {({ onChange }) => (
                            <CardExpiryElement
                                options={{ style }}
                                onChange={onChange}
                            />
                        )}
                    </InputWrapper>
                </GridItem>

                <GridItem xs={6}>
                    <InputWrapper>
                        {({ onChange }) => (
                            <CardCvcElement
                                options={{ style }}
                                onChange={onChange}
                            />
                        )}
                    </InputWrapper>
                </GridItem>
            </GridContainerColumns>
        </>
    )
}
