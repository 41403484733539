import { type ReactElement } from 'react'

import { useOpenUtilityDrawer, UtilityDrawerForm } from 'components'
import {
    api,
    basePermissions,
    serialize,
    type Serializer,
    updatedMessage,
    useResourcePermissions,
    useSubmit,
} from 'core'

import { type InvoicingSettings } from '../types'

import InvoicingSettingsForm from './InvoicingSettingsForm'

interface InvoicingSettingsDrawerTogglerProps {
    children: (params: { onClick: () => void }) => ReactElement
    resource: string
    settings: InvoicingSettings
}

const InvoicingSettingsDrawerToggler = ({
    children,
    resource,
    settings,
}: InvoicingSettingsDrawerTogglerProps) => {
    const permissions = useResourcePermissions()

    const disabled = !permissions[basePermissions.update]
    const open = useOpenUtilityDrawer()

    const handleSubmit = useSubmit(
        async (formData) => {
            await api.put(resource, serialize(formData, serializer))
        },
        {
            successMessage: updatedMessage,
        },
    )

    return children({
        onClick: () => {
            open({
                drawerArgs: {
                    title: 'Settings',
                    renderWrapper: (params) => (
                        <UtilityDrawerForm
                            {...params}
                            onSubmit={handleSubmit}
                            record={settings}
                        />
                    ),
                    renderContent: () => <InvoicingSettingsForm disabled={disabled} />,
                    renderBottomRight: (render) => render({ formType: 'edit' }),
                },
                extraArgs: {
                    resource: { resource, name: 'invoicing_settings' },
                },
            })
        },
    })
}

export default InvoicingSettingsDrawerToggler

const serializer: Serializer<InvoicingSettings> = [
    { name: 'invoiceOnWorkOrderClose', parse: 'boolean' },
    { name: 'printoutIncludeCompanyLogo', parse: 'boolean' },
    { name: 'printoutShowPartNumbers', parse: 'boolean' },
]
