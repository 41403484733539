import Icons from 'assets/icons'
import images from 'assets/images'
import {
    type CardListConfig,
    type DatagridColumnsProps,
    List,
    ListBase,
    type ListSortContentProps,
} from 'components'
import { type GridActionViewProps } from 'components/Datagrid/DatagridAction'
import { type SortPayload } from 'core'
import { type ActionChildren, deleteOneAction } from 'core/actions'
import { formatDate } from 'lib'
import { TagDrawerToggler, TagField, type TagModel, tagsResource } from 'resources/tags'
import { PageContent, Typography } from 'ui'

import { Header } from './components'

const defaultSort: SortPayload<TagModel> = {
    field: 'created',
    order: 'DESC',
}

const action = (record: TagModel, children: ActionChildren<GridActionViewProps>) => [
    <TagDrawerToggler
        id={record.id}
        key="edit"
    >
        {(open) =>
            children({
                title: 'View/Edit',
                Icon: Icons.Edit,
                onClick: open,
            })
        }
    </TagDrawerToggler>,
    deleteOneAction({
        children,
        id: record.id,
        confirmConfig: {
            title: 'Are you sure you want to delete this Tag?',
            content: record.assignedObjects
                ? `This tag is applied to a total of ${record.assignedObjects} items.`
                : null,
        },
    }),
]

const columnsCfg: DatagridColumnsProps<TagModel> = {
    resetColumns: {},
    checkboxSelection: null,
    columns: [
        {
            field: 'name',
            headerName: 'Name',
            renderCell: ({ row }) => <TagField tag={row} />,
            flex: 1,
        },
        {
            field: 'createdByData',
            headerName: 'Created by',
            valueGetter: ({ value }) => (value ? value.name || value.email : value),
            flex: 0.5,
        },
        {
            field: 'created',
            headerName: 'Created on',
            valueFormatter: ({ value }) =>
                formatDate(value, (formats) => formats.shortenedDateTime),
            flex: 0.5,
        },
    ],
    actions: ({ row }, { children }) => action(row, children),
}
const cardsCfg: CardListConfig<TagModel> = {
    titleSource: (record) => <TagField tag={record} />,
    disableTitleLink: true,
    defaultImage: null,
    imageSource: null,
    details: [
        {
            source: 'createdByData',
            label: 'Created by',
            render: (value) => (value ? value.name || value.email : null),
        },
        {
            source: 'created',
            label: 'Created on',
            render: (value) => formatDate(value, (formats) => formats.shortenedDateTime),
        },
    ],
    actions: (row, { children }) => action(row, children),
}

const sortCfg: ListSortContentProps<TagModel & { createdBy: string }> = {
    sortBy: [
        { id: 'name', label: 'Name' },
        { id: 'createdBy', label: 'Created by' },
        { id: 'created', label: 'Created on' },
    ],
}

const TagsManagement = () => {
    return (
        <ListBase
            sort={defaultSort}
            resource={tagsResource.resource}
        >
            <Header />
            <PageContent sx={{ paddingTop: '16px' }}>
                <List
                    listFTUProps={{
                        title: 'No Items',
                        secondaryTitle: 'Would you like to create one?',
                        linkText: (
                            <TagDrawerToggler>
                                {(open) => (
                                    <Typography
                                        variant="body1"
                                        onClick={open}
                                        color={(theme) => theme.palette.primary.main}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        Create Tag
                                    </Typography>
                                )}
                            </TagDrawerToggler>
                        ),
                        linkAction: (e) => {
                            e.preventDefault()
                        },
                        imageSrc: images.tags,
                    }}
                    sortCfg={sortCfg}
                    columnsCfg={columnsCfg}
                    cardsCfg={cardsCfg}
                    disableManageColumns
                    disableExportButton
                />
            </PageContent>
        </ListBase>
    )
}

export default TagsManagement
