import { useState } from 'react'

import { inject, observer } from 'mobx-react'

import Icons from 'assets/icons'
import { type AuthStore } from 'core/auth'
import { alpha, globalClassNames } from 'lib'
import { freeSubscriptionBillingColorVariant } from 'resources/billing'
import { UserIcon } from 'resources/user'
import { DataAvatar, Typography, BoxContainer, StyledElement, Stack } from 'ui'

import SidebarHide from '../SidebarHide'
import SidebarTooltip from '../SidebarTooltip'

import SidebarProfileMenu from './SidebarProfileMenu'

const SidebarProfile = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

        const handleMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
            setAnchorEl(event.currentTarget)
        }

        const handleMenuClose = () => {
            setAnchorEl(null)
        }

        const billing = auth.billing

        return (
            <>
                <BoxContainer
                    sx={{
                        px: '16px',
                        minHeight: '48px',
                        cursor: 'pointer',
                    }}
                    onClick={handleMenuClick}
                >
                    <SidebarTooltip
                        title="Settings"
                        placement="right"
                        arrow
                    >
                        <DataAvatar
                            imageSrc={auth.user.avatar}
                            defaultImage={<UserIcon fontSize="small" />}
                            sx={[
                                billing &&
                                    billing.subscription === 'free' && {
                                        boxSizing: 'border-box',
                                        borderWidth: '2px',
                                        borderStyle: 'solid',
                                        borderColor: (theme) =>
                                            freeSubscriptionBillingColorVariant(billing).color(
                                                theme,
                                            ),
                                    },
                                {
                                    width: 32,
                                    height: 32,
                                    backgroundColor: (theme) =>
                                        alpha(theme.palette.primary.main, 0.08),
                                    '& svg': {
                                        color: (theme) => theme.palette.primary.main,
                                    },
                                },
                            ]}
                        />
                    </SidebarTooltip>

                    <SidebarHide>
                        <BoxContainer
                            justifyContent="space-between"
                            width="100%"
                            ml="28px"
                            overflow="hidden"
                        >
                            <Stack overflow="hidden">
                                <StyledElement sx={{ display: 'grid', pr: '16px' }}>
                                    <Typography
                                        variant="menuItem"
                                        className={globalClassNames.ellipsis}
                                    >
                                        {auth.user.name}
                                    </Typography>
                                </StyledElement>
                                <Typography
                                    className={globalClassNames.ellipsis}
                                    variant="chartTitle"
                                >
                                    {auth.user.membership.roleData.label} •{' '}
                                    <span>{auth.user.membership.company.name}</span>
                                </Typography>
                            </Stack>
                            <Icons.ArrowDropDownOutlined
                                sx={{
                                    color: (theme) => theme.palette.text.primary,
                                    opacity: 0.54,
                                }}
                            />
                        </BoxContainer>
                    </SidebarHide>
                </BoxContainer>
                <SidebarProfileMenu
                    onClose={handleMenuClose}
                    anchorEl={anchorEl}
                />
            </>
        )
    }),
)

export default SidebarProfile
