import {
    TextInput,
    TextareaInput,
    costMaskParams,
    inputQuantityMaskParams,
    useUtilityDrawerContext,
} from 'components'
import { maxLengthValidationText, requiredValidation } from 'core'
import { CK15Input, CK18Input, CK33InputWithoutParts } from 'resources/vmrs'
import { type JobLaborModel } from 'resources/workOrders'
import { GridContainerColumns, GridItem, SectionTitleSmall } from 'ui'

import { type WorkOrderItemsExtra } from '../config/constants'

import JobLaborPartFormTotal from './JobLaborPartFormTotal'
const descriptionValidation = [maxLengthValidationText, requiredValidation]

const JobLaborForm = () => {
    const { extra } = useUtilityDrawerContext()
    const { disabledFields: disabled } = extra as WorkOrderItemsExtra

    return (
        <>
            <SectionTitleSmall>Basic Details</SectionTitleSmall>
            <CK33InputWithoutParts
                disabled={disabled}
                required
            />
            <TextareaInput<JobLaborModel>
                source="description"
                label="Description"
                disabled={disabled}
                validate={descriptionValidation}
            />
            <CK18Input
                source="cause"
                disabled={disabled}
                label="Cause"
            />
            <CK15Input disabled={disabled} />
            <GridContainerColumns>
                <GridItem xs={6}>
                    <TextInput
                        source="hours"
                        label="Qty/Hours"
                        {...inputQuantityMaskParams}
                        validate={requiredValidation}
                        disabled={disabled}
                    />
                </GridItem>
                <GridItem xs={6}>
                    <TextInput
                        source="hourlyRate"
                        label="Rate"
                        {...costMaskParams}
                        disabled={disabled}
                        validate={requiredValidation}
                    />
                </GridItem>
                <JobLaborPartFormTotal
                    title="Labor Total"
                    inputOne="hours"
                    inputTwo="hourlyRate"
                />
            </GridContainerColumns>
        </>
    )
}

export default JobLaborForm
