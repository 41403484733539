import { useRecordContext } from 'react-admin'

import { Order } from 'appTypes'
import { ListBase } from 'components'
import { ResourceContextProviderWithClearEffect, type SortPayload } from 'core'
import {
    getPtServicesResource,
    type PtServiceModel,
    ptServicesPreferencesResource,
} from 'resources/pricingTiers'
import { type ShopModel } from 'resourcesBase'

import { ServicesList, ListHeader } from './components'

const PricingTiersServices = () => {
    const record = useRecordContext<ShopModel>()

    return (
        <ResourceContextProviderWithClearEffect value={getPtServicesResource(record?.id)}>
            <ListBase
                preferencesResource={ptServicesPreferencesResource}
                sort={defaultSort}
                isLoading={!record}
            >
                <ListHeader />
                <ServicesList preferencesResource={ptServicesPreferencesResource} />
            </ListBase>
        </ResourceContextProviderWithClearEffect>
    )
}

export default PricingTiersServices

const defaultSort: SortPayload<PtServiceModel> = {
    field: 'created',
    order: Order.DESC,
}
