import {
    type CardListConfig,
    type DatagridColumnsProps,
    type FilterConfig,
    LinkArrowButton,
    type ListSortContentProps,
    PeriodRangeFilter,
    PeriodRangeWithTypeFilter,
} from 'components'
import { DatagridLink } from 'components/Datagrid'
import { ResourceContextProviderWithClearEffect, excludeNulls, type SortPayload } from 'core'
import { getListData } from 'core/data'
import { formatDuration, globalClassNames } from 'lib'
import { tagFields } from 'resources/tags'
import {
    type UnitAggregates,
    unitResource,
    isUnitArchived,
    unitAspects,
    type ReportDowntimeModel,
    ArchivedUnitBadge,
} from 'resources/units'
import unitFields from 'resources/units/fields'
import { CK2Field, ck34Fields } from 'resources/vmrs'
import { PageContent, BoxContainer, StyledElement } from 'ui'

import { navigateToUnit } from '../CostPerMeter/CostPerMeter'
import { ListBaseWithPeriod, ListWithPeriodSelector, ReportsHeader } from '../components'

import { UnitCardTitleLink } from './components'

const defaultSort: SortPayload<ReportDowntimeModel> = {
    field: 'downtime',
    order: 'DESC',
}
const formatDurationForDowntime = (value: number) => {
    if (!value) {
        return null
    }
    return formatDuration(value, true)
}

const columnsCfg: DatagridColumnsProps<ReportDowntimeModel> = {
    // TO-DO add all columns from units
    resetColumns: {
        vin: false,
        licensePlate: false,
        model: false,
        modelYear: false,
        engineModel: false,
        engineHp: false,
        transmissionModel: false,
        transmissionGears: false,
        color: false,
        tireSize: false,
        status: false,
        created: false,
        engineVmrsManufacturer: false,
        transmissionVmrsManufacturer: false,
        vmrsEquipmentCategory: false,
        vmrsManufacturer: false,
    },
    constantColumns: {
        number: true,
    },
    checkboxSelection: false,
    columns: [
        {
            field: unitFields.number.source,
            headerName: unitFields.number.label,
            renderCell: (params) => {
                const { value, ...restParams } = params
                return (
                    <DatagridLink
                        {...restParams}
                        resource={unitResource.resource}
                        value={
                            <BoxContainer>
                                <StyledElement className={globalClassNames.ellipsis}>
                                    {value}
                                </StyledElement>
                                {isUnitArchived(params.row) && <ArchivedUnitBadge />}
                            </BoxContainer>
                        }
                    />
                )
            },
        },
        unitFields.name.tableColumn({
            headerName: unitFields.name.longLabel,
            dataToValue: (record) => record.name,
        }),
        {
            field: 'downtimeEmergency',
            headerName: 'Emergency',
            valueGetter: ({ value }) => formatDurationForDowntime(value),
        },
        {
            field: 'downtimeNonScheduled',
            headerName: 'Non-Scheduled',
            valueGetter: ({ value }) => formatDurationForDowntime(value),
        },
        {
            field: 'downtimeScheduled',
            headerName: 'Scheduled',
            valueGetter: ({ value }) => formatDurationForDowntime(value),
        },
        {
            field: 'downtimePercent',
            headerName: 'Relative',
            renderCell: ({ value }) => `${Math.round(value)}%`,
        },
        {
            field: 'downtime',
            headerName: 'Total Downtime',
            valueGetter: ({ value }) => formatDurationForDowntime(value),
        },

        unitFields.created.tableColumn({
            dataToValue: (record) => record.created,
        }),
        unitFields.licensePlate.tableColumn({
            dataToValue: (record) => record.licensePlate,
        }),
        unitFields.vin.tableColumn({
            dataToValue: (record) => record.vin,
        }),
        CK2Field.column({
            dataToRecord: (data) => data.vmrsEquipmentCategoryData,
            id: 'vmrsEquipmentCategory',
        }),
        unitFields.model.tableColumn({
            dataToValue: (record) => record.model,
        }),
        unitFields.modelYear.tableColumn({
            dataToValue: (record) => record.modelYear,
        }),
        unitFields.color.tableColumn({
            dataToValue: (record) => record.color,
        }),

        unitFields.status.tableColumn({ label: 'Unit Status' }),
    ],
    actions: null,
}

const cardsCfg: CardListConfig<ReportDowntimeModel> = {
    titleSource: (record) => <UnitCardTitleLink record={record} />,
    defaultImage: null,
    disableTitleLink: true,
    details: [
        unitFields.name.dataCardRow({
            headerName: unitFields.name.longLabel,
            dataToValue: (record) => record.name,
        }),
        {
            source: 'downtimeEmergency',
            label: 'Emergency',
            render: (value) => formatDurationForDowntime(value),
        },
        {
            source: 'downtimeNonScheduled',
            label: 'Non-Scheduled',
            render: (value) => formatDurationForDowntime(value),
        },
        {
            source: 'downtimeScheduled',
            label: 'Scheduled',
            render: (value) => formatDurationForDowntime(value),
        },
        {
            source: 'downtimePercent',
            label: 'Relative',
            render: (value) => `${Math.round(value)}%`,
        },
        {
            source: 'downtime',
            label: 'Total Downtime',
            render: (value) => formatDurationForDowntime(value),
        },
    ],
    action: (record) => <LinkArrowButton path={navigateToUnit(record)} />,
}

const sortCfg: ListSortContentProps<ReportDowntimeModel> = {
    sortBy: [
        unitFields.number.sort(),
        unitFields.name.sort({ label: unitFields.name.longLabel }),
        {
            id: 'downtimeEmergency',
            label: 'Emergency',
        },
        { id: 'downtimeNonScheduled', label: 'Non-Scheduled' },
        { id: 'downtimeScheduled', label: 'Scheduled' },
        { id: 'downtimePercent', label: 'Relative' },
        { id: 'downtime', label: 'Total Downtime' },
        unitFields.created.sort(),
        unitFields.licensePlate.sort(),
        unitFields.vin.sort(),
        CK2Field.sort({ id: 'vmrsEquipmentCategory' }),
        unitFields.model.sort(),
        unitFields.modelYear.sort(),
        unitFields.color.sort(),
        unitFields.status.sort({ label: 'Unit Status' }),
    ],
}

const preferencesResource = {
    ...unitResource,
    name: 'downtime',
}

const Downtime = () => {
    const filtersCfg: FilterConfig<ReportDowntimeModel> = {
        filters: [
            unitFields.number.filter(),
            unitFields.name.sort({ label: unitFields.name.longLabel }),
            {
                id: 'downtimeEmergency',
                label: 'Emergency',
                filterType: 'range',
                renderComponent: (props) => (
                    <PeriodRangeWithTypeFilter
                        {...props}
                        choices={['h', 'd']}
                    />
                ),
            },
            {
                id: 'downtimeNonScheduled',
                label: 'Non-Scheduled',
                filterType: 'range',
                renderComponent: (props) => (
                    <PeriodRangeWithTypeFilter
                        {...props}
                        choices={['h', 'd']}
                    />
                ),
            },
            {
                id: 'downtimeScheduled',
                label: 'Scheduled',
                filterType: 'range',
                renderComponent: (props) => (
                    <PeriodRangeWithTypeFilter
                        {...props}
                        choices={['h', 'd']}
                    />
                ),
            },
            {
                id: 'downtimePercent',
                label: 'Relative',
                filterType: 'range',
                renderComponent: (props) => <PeriodRangeFilter {...props} />,
            },
            {
                id: 'downtime',
                label: 'Total Downtime',
                filterType: 'range',
                renderComponent: (props) => (
                    <PeriodRangeWithTypeFilter
                        {...props}
                        choices={['h', 'd']}
                    />
                ),
            },
            unitFields.created.filter(),
            unitFields.vin.filter(),
            unitFields.licensePlate.filter(),
            unitFields.status.filter({ label: 'Unit Status' }),
            CK2Field.filter({ id: 'vmrsEquipmentCategory', label: 'Equipment Category' }),
            ck34Fields.self.filter({ id: 'vmrsManufacturer', label: 'Manufacturer/Make' }),
            unitFields.model.filter(),
            unitFields.modelYear.filter(),
            ck34Fields.self.filter({ id: 'engineVmrsManufacturer', label: 'Engine Make' }),
            unitFields.engineModel.filter(),
            unitFields.engineHp.filter(),
            ck34Fields.self.filter({
                id: 'transmissionVmrsManufacturer',
                label: 'Transmission Make',
            }),
            unitFields.transmissionModel.filter(),
            unitFields.transmissionGears.filter(),
            unitFields.color.filter(),
            unitFields.tireSize.filter(),
            tagFields.self.filter(),
            { id: 'archived', label: 'Archived Unit' },
        ],
    }

    return (
        <ResourceContextProviderWithClearEffect value={unitResource}>
            <ListBaseWithPeriod
                preferencesResource={preferencesResource}
                sort={defaultSort}
                filter={{ withAspects: [unitAspects.downtime], ...excludeNulls }}
            >
                <ReportsHeader
                    renderTotal={(list) => {
                        const listData = getListData<UnitAggregates>(list)
                        return `${Math.round(listData.downtimePercent || 0)}% | ${formatDuration(
                            listData.downtime || 0,
                            true,
                        )}`
                    }}
                >
                    Downtime
                </ReportsHeader>
                <PageContent>
                    <ListWithPeriodSelector
                        exportFileName="downtime-by-repair-class"
                        filtersCfg={filtersCfg}
                        sortCfg={sortCfg}
                        columnsCfg={columnsCfg}
                        cardsCfg={cardsCfg}
                        preferencesResource={preferencesResource}
                    />
                </PageContent>
            </ListBaseWithPeriod>
        </ResourceContextProviderWithClearEffect>
    )
}

export default Downtime
