import { useRecordContext } from 'react-admin'

import { Order } from 'appTypes'
import { ListBase } from 'components'
import { ResourceContextProviderWithClearEffect, type SortPayload } from 'core'
import { getPtFeeResource, type PtFeeModel, ptFeePreferencesResource } from 'resources/pricingTiers'
import { type ShopModel } from 'resourcesBase'

import { FeeList, ListHeader } from './components'

const PricingTiersFees = () => {
    const record = useRecordContext<ShopModel>()

    return (
        <ResourceContextProviderWithClearEffect value={getPtFeeResource(record?.id)}>
            <ListBase
                sort={defaultSort}
                isLoading={!record}
                preferencesResource={ptFeePreferencesResource}
            >
                <ListHeader />
                <FeeList preferencesResource={ptFeePreferencesResource} />
            </ListBase>
        </ResourceContextProviderWithClearEffect>
    )
}

export default PricingTiersFees

const defaultSort: SortPayload<PtFeeModel> = {
    field: 'created',
    order: Order.DESC,
}
