import { type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import {
    TextInput,
    UtilityDrawerEdit,
    useOpenUtilityDrawer,
    costNonNegativeMaskParams,
} from 'components'
import {
    basePermissions,
    useResourcePermissions,
    type AuthStore,
    validateMaxLength,
    type Serializer,
} from 'core'
import { shopFields, shopResource } from 'resources/shops'
import { type ShopModel } from 'resourcesBase'
import { Alert } from 'ui'

interface SettingsDrawerTogglerProps {
    children: (params: { onClick: () => void }) => ReactElement
    id: Identifier
    auth?: AuthStore
}

const laborValidate = validateMaxLength(10)

const SettingsForm = ({ disabled }: { disabled: boolean }) => {
    return (
        <>
            <Alert
                severity="info"
                sx={{ mb: '40px' }}
            >
                Automatically fill-in the labor rate in the WO job for this repair shop. You can
                still edit the rate in the line item.
            </Alert>
            <TextInput
                source={shopFields.hourlyLaborRate.source}
                label={shopFields.hourlyLaborRate.label}
                validate={laborValidate}
                {...costNonNegativeMaskParams}
                disabled={disabled}
            />
        </>
    )
}

const settingsSerializer: Serializer<ShopModel> = ['laborHourlyRate']
export const SettingsDrawerToggler = ({ children, id }: SettingsDrawerTogglerProps) => {
    const permissions = useResourcePermissions()

    const disabled = !permissions[basePermissions.update]
    const open = useOpenUtilityDrawer()
    return children({
        onClick: () => {
            open({
                drawerArgs: {
                    title: 'Settings',
                    renderWrapper: (params) => (
                        <UtilityDrawerEdit
                            {...params}
                            serializer={settingsSerializer}
                        />
                    ),
                    renderContent: () => <SettingsForm disabled={disabled} />,
                },
                extraArgs: {
                    id,
                    resource: shopResource,
                },
            })
        },
    })
}

export default SettingsDrawerToggler
