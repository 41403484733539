import { type ReactElement } from 'react'

import { SendToContactDrawer } from 'resources/contacts'
import { customersResource } from 'resources/customers'
import { type InvoiceModel, invoicesResource } from 'resources/invoices'

interface SendInvoiceDrawerProps {
    children: (open: () => void) => ReactElement
    invoice: InvoiceModel
}

const SendInvoiceDrawer = ({ children, invoice }: SendInvoiceDrawerProps) => {
    return (
        <SendToContactDrawer
            title="Send Invoice"
            resource={invoicesResource.resource}
            record={invoice}
            sendToFormConfig={{
                resource: customersResource.resource,
                resourceId: invoice.customer,
                alertText: 'Invoice can be sent only to contacts with an email address.',
            }}
        >
            {children}
        </SendToContactDrawer>
    )
}

export default SendInvoiceDrawer
