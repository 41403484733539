import { type Identifier } from 'appTypes'
import { type ResourceType } from 'core'

import { getPricingTierResource, PricingTierTypes } from '../utils'

export const getPtPartResource = (id: Identifier): ResourceType => ({
    resource: getPricingTierResource(id, PricingTierTypes.PARTS),
    name: 'pricing-tiers-parts',
})

export const ptPartPreferencesResource: ResourceType = {
    resource: 'pricing-tiers-parts',
    name: 'pricing-tiers-parts',
}
