import { type FC, type ReactElement } from 'react'

import { useSidebarContext } from './SidebarContext'

const SidebarHide: FC<{ children: ReactElement }> = ({ children }) => {
    const { open } = useSidebarContext()

    if (!open) {
        return null
    }

    return children
}

export default SidebarHide
