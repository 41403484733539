import { type FC } from 'react'

import {
    EditButton,
    InfoCard,
    InfoCardHeader,
    InfoCardRowBase,
    InfoCardRows,
    InfoCardTitle,
    MoneyField,
} from 'components'
import { Divider } from 'ui'

import FeeRow from './FeeRow'
import ManageTotalDrawerToggler from './ManageTotalDrawerToggler'
import TaxRow from './TaxRow'

const TotalCard: FC = () => {
    return (
        <InfoCard>
            <InfoCardHeader
                title="Total"
                action={
                    <ManageTotalDrawerToggler>
                        {(open) => <EditButton onClick={open} />}
                    </ManageTotalDrawerToggler>
                }
            />
            <InfoCardRowBase label={<InfoCardTitle>Subtotal</InfoCardTitle>}>
                <MoneyField value={200} />
            </InfoCardRowBase>
            <InfoCardRows>
                <FeeRow />

                <TaxRow />
            </InfoCardRows>
            <Divider />
            <InfoCardRows offsetBottom="0">
                <InfoCardRowBase label={<InfoCardTitle>Grand Total</InfoCardTitle>}>
                    <InfoCardTitle>
                        <MoneyField value={200} />
                    </InfoCardTitle>
                </InfoCardRowBase>
            </InfoCardRows>
        </InfoCard>
    )
}

export default TotalCard
