import { startCase, toLower } from './helpers'

export const removeTrailingSlash = (path: string) => {
    return path.charAt(path.length - 1) === '/' ? path.slice(0, -1) : path
}

export const capitalize = <W extends string = string>(w: W): Capitalize<W> => {
    return (w.charAt(0).toUpperCase() + w.slice(1)) as Capitalize<W>
}

export const capitalizeWithLowerCase = (w: string) => {
    return w.charAt(0).toUpperCase() + w.slice(1).toLowerCase()
}

export const prettyText = (w: string) => {
    return capitalizeWithLowerCase(w).replaceAll('_', ' ')
}

export const uppercaseFormat = <W extends string = string>(value: W): Uppercase<W> =>
    (value?.toUpperCase?.() ?? '') as Uppercase<W>
export const lowerCaseFormat = <W extends string = string>(value: W): Lowercase<W> =>
    (value?.toLowerCase?.() ?? '') as Lowercase<W>

export const precisionFormat2 = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
})

export const makeid = (length = 8) => {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}

export const pathJoin = (...pathnames: (string | number)[]): string => {
    return String(
        pathnames.reduce((path, pathname) => {
            if (pathname) {
                return path + '/' + pathname
            }

            return path
        }),
    )
}

export const titleCase = (str: string): string => startCase(toLower(str))

export const propertyToText = (source: string): string => {
    if (!source) {
        return ''
    }

    return source.replace(/([A-Z])/g, ' $1').trim()
}

export const stringJoin = (...strings: string[]) => {
    return strings.reduce((result, currentString) => {
        if (currentString) {
            if (result) {
                return `${result}, ${currentString}`
            }
            return currentString
        }
        return result
    }, '')
}

const nonAlphaNumbericRegex = /[^a-zA-Z\u0400-\u04FF0-9]/g
const multipleSpaceRegex = /\s{2,}/g

export const getInitials = (name: string): string => {
    if (!name) {
        return ''
    }
    const sanitizedName = name.replace(nonAlphaNumbericRegex, ' ').trim()

    if (!sanitizedName) {
        return ''
    }

    const words = sanitizedName.replace(multipleSpaceRegex, ' ').split(' ')
    const firstInitial = words[0][0]
    let lastInitial = ''

    // If the first letter of the first word is a number, get the second letter of the first word if exist
    if (words[0][1] && !isNaN(Number(words[0][0]))) {
        lastInitial = words[0][1]
    } else if (words[1]) {
        lastInitial = words[1][0]
    }

    return (firstInitial + lastInitial).toUpperCase()
}

export const displayBooleanValue = (value: boolean): string => (value ? 'Yes' : 'No')

export type BooleanValue = 'Yes' | 'No'

export const booleanValue = {
    positive: 'Yes',
    negative: 'No',
} satisfies { [key: string]: BooleanValue }

export const pluralText = (amount: number, text: string) =>
    amount ? `${amount} ${text}${amount === 1 ? '' : 's'}` : ''

export const htmlSymbols = {
    dot: <>&#x2022;</>,
}

const yearRegex = /^\d{4}$/
export const isValidYear = (year: string | number) => {
    return yearRegex.test(String(year))
}
