import { displayBooleanValue } from 'utils'

export const excludeNulls = { excludeNulls: displayBooleanValue(true) }

export const getFilterReference = (base: string, source: string) =>
    base + '/' + source + '_filter_values'

export const createResource = <ResourceType extends string, NameType extends string>({
    name,
    resource,
}: {
    name: NameType
    resource: ResourceType
}) => {
    return {
        name,
        resource,
    } as const
}
