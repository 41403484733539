import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import { useOpenUtilityDrawer, UtilityDrawerForm } from 'components'
import { api, serialize, useSubmit } from 'core'
import { purchaseInvoiceSerializer } from 'resources/purchaseInvoices'
import { formatPOTotal, poResource, type PurchaseOrderModel } from 'resources/purchaseOrders'
import { PrimaryButton } from 'ui'
import { pathJoin } from 'utils'

import { DrawerTotal, PoInvoiceForm } from '../../components'

const CloseCreditPoButton = () => {
    const record = useRecordContext<PurchaseOrderModel>()
    const open = useOpenUtilityDrawer()

    const submit = useSubmit(async (data) => {
        await api.post(
            pathJoin(poResource.resource, record.id, 'close'),
            serialize(data, purchaseInvoiceSerializer),
        )
    })

    return (
        <PrimaryButton
            startIcon={<Icons.CloseCreditPO />}
            onClick={() => {
                open({
                    drawerArgs: {
                        title: 'Close PO',
                        renderWrapper: (params) => (
                            <UtilityDrawerForm
                                {...params}
                                onSubmit={submit}
                            />
                        ),
                        renderContent: () => (
                            <PoInvoiceForm defaultTerm={record.vendorData.paymentTerm} />
                        ),
                        renderAboveFooter: () => (
                            <DrawerTotal
                                text="Purchase Order Total"
                                totalContent={formatPOTotal(record.total)}
                            />
                        ),
                        renderBottomRight(render) {
                            return render({
                                label: 'Close PO',
                                icon: <Icons.Check />,
                            })
                        },
                    },
                })
            }}
            disabled={!record.items || record.status === 'CANCELED' || record.status === 'CLOSED'}
        >
            Close PO
        </PrimaryButton>
    )
}

export default CloseCreditPoButton
