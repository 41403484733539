import { type Theme } from 'lib'
import { type BillingModel } from 'resourcesBase'

export type billingColorVariants = 'variant1' | 'variant2' | 'variant3'

export interface BillingVariantColors
    extends Record<billingColorVariants, (theme: Theme) => string> {}

const variantColors: BillingVariantColors = {
    variant1: (theme) => theme.palette.success.main,
    variant2: (theme) => theme.palette.warning.main,
    variant3: (theme) => theme.palette.error.main,
}

export const freeSubscriptionBillingColorVariant = (billing: BillingModel) => {
    const percentage = (billing.allUsedWorkOrdersCount / billing.allFreeWorkOrdersCount) * 100

    let variant: billingColorVariants = 'variant1'

    if (percentage > 40 && percentage <= 80) {
        variant = 'variant2'
    } else if (percentage > 80) {
        variant = 'variant3'
    }

    return {
        variant,
        color: variantColors[variant],
    }
}
