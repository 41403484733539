import { type FC } from 'react'

import { useMediaQuery } from 'lib'
import { Tooltip, type TooltipProps } from 'ui'

import { useSidebarContext } from './SidebarContext'

const SidebarTooltip: FC<TooltipProps> = (props) => {
    const { open } = useSidebarContext()

    const matches = useMediaQuery((theme) => theme.breakpoints.up(theme.props.mobileViewBreakpoint))

    return (
        <>
            <Tooltip
                {...props}
                title={matches && !open ? props.title : ''}
            />
        </>
    )
}

export default SidebarTooltip
