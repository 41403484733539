import { type FC } from 'react'

import { Header, InnerHeader, type TabType } from 'components'

const ConfigureHeader: FC<{ tabs: TabType[] }> = ({ tabs }) => {
    return (
        <InnerHeader
            tabs={tabs}
            tabWidth="218px"
            backTo="/"
        >
            <Header.Info>
                <Header.Content>
                    <Header.Title>Configure</Header.Title>
                </Header.Content>
            </Header.Info>
        </InnerHeader>
    )
}

export default ConfigureHeader
