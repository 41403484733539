import Icons from 'assets/icons'
import images from 'assets/images'
import { type BillingModel } from 'resourcesBase'
import { Spacer, Img, Typography } from 'ui'

import { TooltipContainer } from './Tooltip/TooltipContainer'
import TooltipDescription from './Tooltip/TooltipDescription'

interface PlanTitleProps {
    subscription: BillingModel['subscription']
}

const PlanTitle = ({ subscription }: PlanTitleProps) => {
    return (
        <Spacer mt="16px">
            {subscription === 'free' ? (
                <Img
                    src={images.logoBillingFree}
                    width="32px"
                    height="32px"
                />
            ) : (
                <Img
                    src={images.fleetpalSmall}
                    width="32px"
                    height="32px"
                />
            )}
            <Typography variant="subtitle1">
                {subscription === 'free' ? ' Early Access' : 'Fleetpal Pro'}
            </Typography>
            <TooltipContainer title={<TooltipDescription subscription={subscription} />}>
                <Icons.InfoOutlined
                    sx={(theme) => ({
                        width: '12px',
                        height: '12px',
                        '&:hover': {
                            color: theme.palette.text.primary,
                        },
                        color: theme.palette.action.disabled,
                    })}
                />
            </TooltipContainer>
        </Spacer>
    )
}

export default PlanTitle
