import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { type AuthStore } from 'core/auth'

import { getCk33Info } from '../utils'

interface Props {
    excludeLevelAndBelow?: number
}

export const CK33Label: FC<Props> = inject('auth')(
    observer(({ excludeLevelAndBelow, auth }: Props & { auth: AuthStore }) => {
        return <span>{getCk33Info(auth, excludeLevelAndBelow).label}</span>
    }),
)
