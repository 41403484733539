import { type ComponentProps } from 'react'

import { inject, observer } from 'mobx-react'

import { urls } from 'configs'
import { type AuthStore } from 'core'
import { Link, type Theme, alpha } from 'lib'
import { freeSubscriptionBillingColorVariant } from 'resources/billing'
import { StyledElement, Typography, Button } from 'ui'

import FreeWoBar from './FreeWoBar'

const emptyColor = (theme: Theme) => alpha(theme.palette.text.main, 0.26)

export interface EarlyAccessProps {
    buttonProps?: Partial<ComponentProps<typeof Button<typeof Link | 'div'>>>
}

const EarlyAccess = inject('auth')(
    observer(({ auth, buttonProps }: { auth?: AuthStore } & EarlyAccessProps) => {
        const billing = auth.billing

        const color = freeSubscriptionBillingColorVariant(billing).color

        const usedFreeWo = billing.allUsedWorkOrdersCount
        const totalFreeWo = billing.allFreeWorkOrdersCount

        const totalBars = new Array(totalFreeWo)
            .fill(0)
            .map((_, i) => (i < usedFreeWo ? color : emptyColor))

        return (
            <>
                <StyledElement sx={{ display: 'flex', mb: '4px' }}>
                    <Typography variant="h4">
                        {usedFreeWo}/{totalFreeWo}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.primary"
                        sx={{ ml: '8px', mt: '18px' }}
                    >
                        Free work orders
                    </Typography>
                </StyledElement>

                <StyledElement sx={{ display: 'flex', gap: '6px' }}>
                    {totalBars.map((statusColor, i) => (
                        <FreeWoBar
                            key={`progressBar-${i}`}
                            statusColor={statusColor}
                        />
                    ))}
                </StyledElement>
                <Typography
                    variant="body2"
                    color="text.primary"
                    sx={{ mt: '20px' }}
                >
                    You’ve used {usedFreeWo} out of your included {totalFreeWo} work orders.
                </Typography>
                <Button
                    sx={{ mt: '20px', height: '42px' }}
                    fullWidth
                    variant="contained"
                    component={Link}
                    to={urls.changePlan}
                    children="GO UNLIMITED"
                    {...buttonProps}
                />
            </>
        )
    }),
)

export default EarlyAccess
