import { type ReactElement, type FC } from 'react'

import { useCreatePath } from 'react-admin'

import { NotificationMessage } from 'core'
import {
    InvoiceDrawerToggler,
    type InvoiceModel,
    invoicesResource,
    InvoiceTypeKeys,
} from 'resources/invoices'
import { type WorkOrderModel } from 'resources/workOrders'

interface WoInvoiceDrawerProps {
    record: WorkOrderModel
    children: (open: () => void) => ReactElement
}
const WoInvoiceDrawer: FC<WoInvoiceDrawerProps> = ({ record, children }) => {
    const createPath = useCreatePath()
    return (
        <InvoiceDrawerToggler
            defaultValues={{
                shop: record.shop,
                customer: record.unitData.customer,
                workOrder: record.id,
                unit: record.unit,
            }}
            type={InvoiceTypeKeys.WORK_ORDER}
            successMessage={({ defaultMessages, response }) => {
                const invoice = response as InvoiceModel
                return {
                    title: defaultMessages.created,
                    message: (
                        <NotificationMessage.Navigate
                            to={createPath({
                                resource: invoicesResource.resource,
                                type: 'edit',
                                id: invoice.id,
                            })}
                        >
                            {invoice.number}
                        </NotificationMessage.Navigate>
                    ),
                }
            }}
        >
            {children}
        </InvoiceDrawerToggler>
    )
}

export default WoInvoiceDrawer
