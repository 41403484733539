import { type PtPartModel } from 'resources/pricingTiers'

export const deletePartAction = (record: PtPartModel) => {
    if (record.customersCount) {
        return {
            confirmConfig: {
                title: `${record.name} Price Tier is Currently Assigned to a Customer.`,
                content: (
                    <>
                        If you delete this tier, all affected customers will use the "Default"
                        setting.
                        <br />
                        <br />
                        Do you wish to continue?
                    </>
                ),
            },
        }
    }
    return {
        confirmConfig: {
            title: 'You are about to delete a Customer Price Tier.',
            content: 'All related part markups will be deleted. This cannot be undone.',
        },
    }
}
