import { useFormContext } from 'react-hook-form'

import { costMaskParams, TextareaInput, TextInput } from 'components'
import { requiredValidation, validateMaxLength } from 'core'
import { PartInput } from 'resources/parts'
import { CK33Input, CK34Input } from 'resources/vmrs'
import { GridContainerColumns, GridItem, SectionTitleSmall } from 'ui'

import { type InvoicePart } from '../../types'

export interface PartFormProps {
    isDisabled?: (source: keyof InvoicePart) => boolean
    isHidden?: (source: keyof InvoicePart) => boolean
}

const defaultDisabled = () => false

const qtyAndCostValidator = [validateMaxLength(10, 'Invalid value'), requiredValidation]
const markupValidation = [validateMaxLength(10, 'Invalid value')]

const PartForm = ({ isDisabled = defaultDisabled, isHidden }: PartFormProps) => {
    const { setValue } = useFormContext()

    return (
        <>
            <SectionTitleSmall>Basic Details</SectionTitleSmall>

            <PartInput
                contextType="invoice"
                disabled={isDisabled('part')}
                onSelectedChange={({ selected }) => {
                    setValue('description', selected?.description || '')
                    setValue('price', selected?.cost ?? '', {
                        shouldTouch: true,
                        shouldDirty: true,
                    })
                    setValue('unitOfMeasure', selected?.unitOfMeasure || '')
                    setValue('manufacturer', selected?.manufacturer || '')
                    setValue('component', selected?.component || '')
                }}
            />

            <TextareaInput
                source="description"
                label="Description"
                disabled
            />

            <CK33Input
                source="component"
                disabled
            />

            <CK34Input
                label="Manufacturer"
                disabled
                source="manufacturer"
            />

            <GridContainerColumns>
                <GridItem xs={6}>
                    <TextInput
                        source="unitOfMeasure"
                        label="UOM"
                        disabled
                    />
                </GridItem>
            </GridContainerColumns>

            <TextInput
                source="cost"
                label="Average Cost"
                {...costMaskParams}
                disabled
            />

            <TextInput
                source="price"
                label="Markup/Margin"
                {...costMaskParams}
                disabled={isDisabled('price')}
                validate={markupValidation}
            />

            <TextInput
                source="markup"
                label="Markup/Margin"
                {...costMaskParams}
                disabled={isDisabled('markup')}
                validate={markupValidation}
            />

            {isHidden?.('woQuantity') ? null : (
                <TextInput
                    source="woQuantity"
                    label="Work Order Quantity"
                    disabled
                />
            )}

            <TextInput
                source="quantity"
                label="Quantity"
                disabled={isDisabled('quantity')}
                validate={qtyAndCostValidator}
            />
        </>
    )
}

export default PartForm
