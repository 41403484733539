import { type FC } from 'react'

import { type Identifier } from 'appTypes'
import { costMaskParams, DateInput, TextInput } from 'components'
import { requiredValidation } from 'core'
import { PaymentTermInput } from 'resources/paymentTerms'

import Attachments from './Attachments'

export interface PurchaseInvoiceFormProps {
    getSource?: (source: string) => string
    disabledInputs?: boolean
    defaultValues?: {
        term?: Identifier
    }
}

export const PurchaseInvoiceForm: FC<PurchaseInvoiceFormProps> = ({
    getSource = getSourceDefault,
    disabledInputs: disabled,
    defaultValues,
}) => {
    return (
        <>
            <TextInput
                source={getSource('number')}
                disabled={disabled}
                label="Invoice Number"
                validate={requiredValidation}
            />

            <TextInput
                disabled={disabled}
                source={getSource('amount')}
                label="Amount"
                validate={requiredValidation}
                {...costMaskParams}
            />

            <DateInput
                disabled={disabled}
                source={getSource('date')}
                label="Date"
                defaultValue={new Date()}
                validate={requiredValidation}
            />

            <PaymentTermInput
                source={getSource('paymentTerm')}
                label="Payment Term"
                disabled={disabled}
                defaultValue={defaultValues?.term}
            />
            <Attachments
                disabled={disabled}
                getSource={getSource}
            />
        </>
    )
}

const getSourceDefault = (source: string) => source
