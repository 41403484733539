import { type FC } from 'react'

import { inject, observer } from 'mobx-react'
import { Link } from 'react-admin'

import Icons from 'assets/icons'
import { supportLauncherSelector, urls } from 'configs'
import { useLogout, type AuthStore, useNotify } from 'core'
import { alpha, useMediaQuery, styled, globalClassNames, useLocationChange } from 'lib'
import { UserIcon } from 'resources/user'
import {
    paperClasses,
    listClasses,
    Stack,
    Menu,
    MenuList,
    DataAvatar,
    Typography,
    StyledElement,
} from 'ui'

import SidebarProfileBilling from './SidebarProfileBilling'
import SidebarProfileMenuItem from './SidebarProfileMenuItems'

const StyledSpan = styled('span')`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 2px;
    display: inline-block;
`
const ProfileMenuHeader = styled(Stack)`
    gap: 8px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 16px 0px;
    border-bottom: ${({ theme }) => `1px solid ` + alpha(theme.palette.text.main, 0.12)};
`

interface SidebarProfileMenuProps {
    anchorEl: HTMLElement | null
    onClose: () => void
}

const SidebarProfileMenu: FC<SidebarProfileMenuProps> = ({ anchorEl, onClose }) => {
    const open = Boolean(anchorEl)
    const matches = useMediaQuery((theme) => theme.breakpoints.up(theme.props.mobileViewBreakpoint))

    return (
        <Menu
            sx={{
                [`& .${paperClasses.root}`]: {
                    width: 264,
                    padding: 0,
                },
                [`& .${listClasses.root}`]: {
                    padding: 0,
                },
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={
                matches
                    ? { vertical: 'center', horizontal: 'right' }
                    : { vertical: 'top', horizontal: 'right' }
            }
            transformOrigin={
                matches
                    ? { vertical: 'bottom', horizontal: 'left' }
                    : { vertical: 'bottom', horizontal: 'center' }
            }
            onClose={onClose}
        >
            <li role="menuitem">
                <MenuContent onClose={onClose} />
            </li>
        </Menu>
    )
}

const MenuContent: FC<Omit<SidebarProfileMenuProps, 'anchorEl'> & { auth?: AuthStore }> = inject(
    'auth',
)(
    observer(({ auth, onClose }) => {
        const notify = useNotify()
        const { user } = auth
        const logout = useLogout({
            onSuccess: () => {
                notify({
                    title: "You've been successfully logged out.",
                    type: 'info',
                })
            },
        })
        useLocationChange(() => onClose())

        return (
            <>
                <ProfileMenuHeader>
                    <DataAvatar
                        sx={(theme) => ({
                            bgcolor: alpha(theme.palette.primary.main, 0.08),
                            color: theme.palette.primary.main,
                            width: '64px',
                            height: '64px',
                        })}
                        imageSrc={user.avatar}
                        defaultImage={<UserIcon fontSize="20px" />}
                    />
                    <StyledElement sx={{ display: 'grid', px: '16px' }}>
                        <Typography
                            variant="alertTitle"
                            className={globalClassNames.ellipsis}
                        >
                            {user.name}
                        </Typography>
                    </StyledElement>
                    <Typography
                        sx={{
                            width: '100%',
                            boxSizing: 'border-box',
                            padding: '0px 12px',
                            display: 'flex',
                            justifyContent: 'center',
                            whiteSpace: 'nowrap',
                        }}
                        variant="inputLabel"
                    >
                        {user.membership.roleData.label} •
                        <StyledSpan>{user.membership.company.name}</StyledSpan>
                    </Typography>
                </ProfileMenuHeader>
                <SidebarProfileBilling />
                <Stack
                    sx={{
                        gap: 5,
                        justifyContent: 'center',
                        alignItems: 'left',
                        padding: '8px 0px',
                    }}
                >
                    <MenuList>
                        <Link
                            to={urls.userProfile}
                            role="menuitem"
                        >
                            <SidebarProfileMenuItem
                                Icon={Icons.Profile}
                                text="Personal Account"
                            />
                        </Link>
                        <Link
                            to={urls.company}
                            role="menuitem"
                        >
                            <SidebarProfileMenuItem
                                Icon={Icons.DomainOutlined}
                                text="Company Account"
                            />
                        </Link>
                        <SidebarProfileMenuItem
                            Icon={Icons.Support}
                            text="Help and Support"
                            className={supportLauncherSelector}
                        />
                        <SidebarProfileMenuItem
                            onClick={() => logout()}
                            Icon={Icons.Logout}
                            text="Log Out"
                        />
                    </MenuList>
                </Stack>
            </>
        )
    }),
)

export default SidebarProfileMenu
