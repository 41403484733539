import { type Identifier } from 'appTypes'
import { type ResourceType } from 'core'

export const customerPricingPreferencesResource: ResourceType = {
    resource: 'customer-pricing',
    name: 'customer-pricing',
}

// In FE we make out version of ID so it can differ from the original ID
const defaultOptionPrefix = 'default-'
export const getDefaultOptionId = (id: Identifier) => defaultOptionPrefix + id
export const getOptionId = (id: Identifier) =>
    isIdDefaultOption(id) ? (id as string).slice(defaultOptionPrefix.length) : id
export const isIdDefaultOption = (id: Identifier) => (id as string).startsWith(defaultOptionPrefix)
