import { inject, observer } from 'mobx-react'

import { type AuthStore } from 'core/auth'
import { type GetCk33InfoArgs, getCk33Info } from 'resources/vmrs'

export const partCk33Label = (args: GetCk33InfoArgs) => getCk33Info(args).label

export const PartCk33Label = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => <>{partCk33Label(auth)}</>),
)
