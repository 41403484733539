import { useResourceContext } from 'react-admin'

import Icons from 'assets/icons'
import { ViewHeader } from 'components'
import { PTTaxDrawerToggler } from 'resources/pricingTiers'
import { FixedOnMobile, IconBox, Button } from 'ui'

export const ListHeader = () => {
    const resource = useResourceContext()

    return (
        <ViewHeader
            title="Taxes"
            pt="28px"
            pb="20px"
        >
            <ViewHeader.Content>
                <IconBox title="Please set up your local tax rates in the system. This enables you to accurately calculate and add the applicable taxes to your invoices.">
                    <Icons.InfoOutlined />
                </IconBox>
            </ViewHeader.Content>
            <ViewHeader.Content placement="right">
                <FixedOnMobile>
                    <PTTaxDrawerToggler resource={resource}>
                        {(open) => (
                            <Button
                                variant="contained"
                                startIcon={<Icons.Add />}
                                onClick={open}
                            >
                                create
                            </Button>
                        )}
                    </PTTaxDrawerToggler>
                </FixedOnMobile>
            </ViewHeader.Content>
        </ViewHeader>
    )
}
