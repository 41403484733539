import { type FC } from 'react'

import { useRecordContext } from 'react-admin'

import { ListBase } from 'components'
import { useArchivedContext } from 'context'
import { type SortPayload, type ResourceType } from 'core'
import { unitAspects, type UnitModel, unitResource, UnitsListBase } from 'resources/units'
import { displayBooleanValue } from 'utils'

import Header from './Header'

const defaultSort: SortPayload<UnitModel> = {
    field: 'created',
    order: 'DESC',
}
const preferencesResource: ResourceType = {
    name: 'customer-units',
    resource: 'customer-units',
}
export const CustomerUnitsList: FC = () => {
    const record = useRecordContext()
    const { isArchived } = useArchivedContext()

    return (
        <ListBase
            key={String(isArchived)}
            isLoading={!record}
            sort={defaultSort}
            resource={unitResource.resource}
            filter={{
                withAspects: [unitAspects.costPerMeter, unitAspects.totals],
                customer: record?.name,
                archived: displayBooleanValue(isArchived),
            }}
            preferencesResource={preferencesResource}
        >
            <Header isArchived={isArchived} />
            <UnitsListBase
                removeFilters
                disableExportButton
            />
        </ListBase>
    )
}
