import { useListContext } from 'react-admin'

import Icons from 'assets/icons'
import { useOpenUtilityDrawer, UtilityDrawerAddNewButton } from 'components'
import { useResource } from 'core'
import { basePermissions, renderOnPermission } from 'core/permissions'
import { Button, FixedOnMobile } from 'ui'

import { MemberInviteWrapper } from './MemberInviteDrawer'
import MemberInviteFormContent from './MemberInviteFormContent'

export const MemberInviteButton = renderOnPermission(() => {
    const open = useOpenUtilityDrawer()
    const listContext = useListContext()
    const resource = useResource()

    return (
        <FixedOnMobile>
            <Button
                size="medium"
                variant="contained"
                startIcon={<Icons.PersonAddAltOutlined />}
                onClick={() =>
                    open({
                        drawerArgs: {
                            title: 'Invite New Users',
                            renderWrapper: (params) => <MemberInviteWrapper {...params} />,
                            renderContent: () => <MemberInviteFormContent />,
                            renderTopRight: () => <UtilityDrawerAddNewButton />,
                            renderBottomRight: (renderButton) =>
                                renderButton({
                                    icon: <Icons.Invitations fontSize="large" />,
                                    label: 'Invite',
                                }),
                        },
                        extraArgs: {
                            listContext,
                            resource,
                        },
                    })
                }
            >
                INVITE USER
            </Button>
        </FixedOnMobile>
    )
}, basePermissions.createBulk)
