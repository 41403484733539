import images from 'assets/images'
import { urls } from 'configs'
import { Link } from 'lib'
import { Typography } from 'ui'

import DVIRPageBase from './DVIRPageBase'

const DVIRNoConnection = ({ preventConnect }: { preventConnect?: boolean }) => {
    return (
        <DVIRPageBase
            image={images.image1}
            title="No Integration "
            subtitle={
                preventConnect ? undefined : (
                    <>
                        Connect an integration to view
                        <br />
                        any reported issues
                    </>
                )
            }
            action={
                preventConnect ? undefined : (
                    <Typography
                        component={Link}
                        to={urls.configure + '/integrations'}
                        mt="16px"
                        color="primary"
                        aria-label="Connect"
                    >
                        Connect
                    </Typography>
                )
            }
        />
    )
}

export default DVIRNoConnection
