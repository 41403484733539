import Icons from 'assets/icons'
import { ListTotalBadge, ViewHeader } from 'components'
import { useMediaQuery } from 'lib'
import { TagDrawerToggler } from 'resources/tags'
import { BoxContainer, Button, FixedOnMobile, Spacer } from 'ui'

export const Header = () => {
    const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))

    return (
        <BoxContainer
            padding="28px 28px 4px 28px"
            justifyContent="space-between"
        >
            <Spacer>
                <ViewHeader
                    title="Tags Management"
                    sx={{ margin: 0 }}
                />
                <ListTotalBadge />
            </Spacer>
            <TagDrawerToggler>
                {(open) => (
                    <FixedOnMobile>
                        <Button
                            startIcon={<Icons.Add />}
                            variant="contained"
                            size={isXSmall ? 'large' : 'small'}
                            onClick={open}
                        >
                            CREATE TAG
                        </Button>
                    </FixedOnMobile>
                )}
            </TagDrawerToggler>
        </BoxContainer>
    )
}
