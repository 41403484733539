import { useListContext, useRecordContext } from 'react-admin'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { useNotify, useConfirm } from 'core'
import {
    type ActionChildren,
    type BaseActionRenderParams,
    type ListActionExtendedOption,
} from 'core/actions'
import api from 'core/api'
import { useFinalErrorHandler } from 'core/errors'
import { shopResource } from 'resources/shops'

interface UnassignActionProps {
    children: ActionChildren<{}, ListActionExtendedOption>
    id: Identifier
}

const unassignActionConfig: BaseActionRenderParams = {
    title: 'Unassign',
    Icon: Icons.CancelOutlined,
}
const useUnassignFromList = () => {
    const record = useRecordContext()
    const errorHandler = useFinalErrorHandler()
    const notify = useNotify()
    const confirm = useConfirm()

    return (id: Identifier | Identifier[], onSuccess?: () => void) => {
        confirm({
            title: 'Are you sure you want to unassign the user(s)?',
            confirmButtonProps: {
                startIcon: <Icons.CancelOutlined />,
                children: 'Unassign',
                color: 'error',
                variant: 'text',
            },
            onConfirm: async () => {
                try {
                    await api
                        .post(`${shopResource.resource}/${record.id}/remove_members`, {
                            members: typeof id === 'object' ? id : [id],
                        })
                        .then(() => {
                            notify({
                                title: 'Successfully unassigned user(s)',
                                type: 'success',
                            })
                            onSuccess?.()
                        })
                } catch (err) {
                    errorHandler(err)
                }
            },
        })
    }
}

const UnassignOneFromListAction = ({ children, id }: UnassignActionProps) => {
    const unassignFromList = useUnassignFromList()
    return children({
        ...unassignActionConfig,
        onClick: () => {
            unassignFromList(id)
        },
    })
}

export const unassignOneFromListAction = (params: UnassignActionProps) => (
    <UnassignOneFromListAction
        {...params}
        key="unassign"
    />
)

const UnassignManyFromListAction = ({ children }: Pick<UnassignActionProps, 'children'>) => {
    const listContext = useListContext()
    const selectedIds = listContext.selectedIds
    const unassignFromList = useUnassignFromList()
    return children(
        {
            ...unassignActionConfig,
            onClick: () => {
                unassignFromList(selectedIds)
            },
        },
        {
            listContext,
        },
    )
}

export const unassignManyFromListAction = (params: Pick<UnassignActionProps, 'children'>) => (
    <UnassignManyFromListAction
        {...params}
        key="unassign"
    />
)
