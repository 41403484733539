import { useTelematics } from 'resources/telematics'
import { Badge, BoxContainer } from 'ui'

const label = 'INTEGRATIONS'

const Label = () => {
    const { errorCount, hasUnseenVehicles } = useTelematics()

    if (errorCount || hasUnseenVehicles) {
        return (
            <BoxContainer>
                {label}
                <Badge
                    sx={{
                        marginLeft: '8px',
                    }}
                    color={errorCount ? 'error' : 'primary'}
                    variant="dot"
                />
            </BoxContainer>
        )
    }
    return <BoxContainer>{label}</BoxContainer>
}

export default Label
