import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { Header, InnerHeader, type TabType } from 'components'
import { type AuthStore } from 'core'
import { companyFields } from 'resources/company'
import { SvgIcon } from 'ui'

interface Props {
    tabs: TabType[]
}

const CompanyHeader: FC<Props> = inject('auth')(
    observer(({ tabs, auth }: Props & { auth: AuthStore }) => {
        const company = auth.currentCompany

        return (
            <InnerHeader
                tabs={tabs}
                tabWidth="188px"
            >
                <Header.Info
                    avatar={
                        <Header.Avatar
                            imageSrc={companyFields.avatar.getValue(company)}
                            defaultImage={
                                <SvgIcon
                                    component={companyFields.avatar.Icon}
                                    inheritViewBox
                                />
                            }
                        />
                    }
                >
                    <Header.Content>
                        <Header.Title>Company Account</Header.Title>
                    </Header.Content>
                </Header.Info>
            </InnerHeader>
        )
    }),
)

export default CompanyHeader
