import { type FC } from 'react'

import { useShowContext } from 'react-admin'

import { Show, tabRoutes } from 'components'
import { Routes } from 'lib'
import { type InvoiceModel, InvoiceStatusKeys, invoicesResource } from 'resources/invoices'
import { PaymentsList, PaymentsPage } from 'resources/payments'

import InvoiceLineItems from '../LineItems'
import InvoiceOverview from '../Overview'

import { InvoiceShowHeader } from './components'

const InvoiceShowView: FC = () => {
    const { record } = useShowContext<InvoiceModel>()
    const readOnly =
        record?.status === InvoiceStatusKeys.CLOSED || record?.status === InvoiceStatusKeys.CANCELED

    const paymentResource = `${invoicesResource.resource}/${record?.id}/payments`

    const { tabs, routes } = tabRoutes([
        {
            value: '',
            label: 'Overview',
            element: <InvoiceOverview />,
        },
        {
            value: 'line-items',
            label: 'Line Items',
            element: <InvoiceLineItems />,
        },
        {
            value: 'payments',
            label: 'Payments',
            element: (
                <PaymentsList resource={paymentResource}>
                    <PaymentsPage readOnly={readOnly} />
                </PaymentsList>
            ),
        },
    ])

    return (
        <>
            <InvoiceShowHeader tabs={tabs} />
            <Routes>{routes}</Routes>
        </>
    )
}

const InvoiceShow: FC = () => {
    return (
        <Show>
            <InvoiceShowView />
        </Show>
    )
}

export default InvoiceShow
