import { inject, observer } from 'mobx-react'

import images from 'assets/images'
import { urls } from 'configs'
import { type AuthStore } from 'core'
import { Link, darken } from 'lib'
import {
    renderOnBilling,
    type BillingVariantColors,
    freeSubscriptionBillingColorVariant,
} from 'resources/billing'
import { type BillingModel } from 'resourcesBase'
import { Box, Divider, StatusText, Img, Typography, Button } from 'ui'

// TODO: Billing why it has different colors?
const billingVariantColors: BillingVariantColors = {
    variant1: (theme) => theme.palette.charts.greenBody,
    variant2: (theme) => theme.palette.charts.yellowBody,
    variant3: (theme) => theme.palette.charts.red,
}

const FreeSubscriptionStatusText = ({ billing }: { billing: BillingModel }) => {
    const colorVariant = freeSubscriptionBillingColorVariant(billing).variant
    return (
        <StatusText
            statusColor={(theme) => billingVariantColors[colorVariant](theme)}
            textColor={(theme) => darken(billingVariantColors[colorVariant](theme), 0.2)}
        >
            {billing.allUsedWorkOrdersCount}/{billing.allFreeWorkOrdersCount}
        </StatusText>
    )
}

const SidebarProfileBilling = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const billing = auth.billing
        const subscription = billing.subscription
        return (
            <>
                <Box
                    p="14px 16px 18px"
                    bgcolor={(theme) => theme.palette.gray}
                >
                    <Typography
                        variant="subtitle2"
                        color="text.primary"
                        mb="12px"
                        display="flex"
                        alignItems="center"
                    >
                        <Img
                            src={
                                subscription === 'pro'
                                    ? images.fleetpalSmall
                                    : images.logoBillingFree
                            }
                            sx={{ width: '16px', height: '16px', mr: '8px' }}
                        />
                        {subscription === 'pro' ? 'Fleetpal Pro' : 'Early Access'}
                    </Typography>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography
                            variant="body2"
                            color="text.primary"
                        >
                            {subscription === 'pro' ? 'Work Orders' : 'Free Work Orders'}
                        </Typography>
                        {subscription === 'pro' ? (
                            <StatusText statusColor={(theme) => theme.palette.primary.main}>
                                {billing.workOrdersForPeriodCount}
                            </StatusText>
                        ) : (
                            <FreeSubscriptionStatusText billing={billing} />
                        )}
                    </Box>
                    {subscription === 'free' && (
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mt="16px"
                        >
                            <Button
                                variant="contained"
                                size="small"
                                component={Link}
                                to={urls.changePlan}
                            >
                                Upgrade
                            </Button>
                        </Box>
                    )}
                </Box>
                <Divider light />
            </>
        )
    }),
)

export default renderOnBilling(SidebarProfileBilling)
