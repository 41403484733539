import { type NoInfer } from 'appTypes'
import { type Column, type DataListDetails, formatMoney } from 'components'

import { type InvoiceModel } from '../types'

const source = 'amountDue' as const satisfies keyof InvoiceModel
const label = 'Balande Due'

interface Data extends Pick<InvoiceModel, 'amountDue'> {}

const value = (record: Data) => formatMoney(record.amountDue)

const tableColumn = <Source extends string = typeof source>() =>
    ({
        field: source as NoInfer<Source>,
        headerName: label,
        renderCell: (cell) => value(cell.row),
        align: 'right',
        headerAlign: 'right',
    }) as const satisfies Column

const sort = <Source extends string = typeof source>() =>
    ({
        id: source as NoInfer<Source>,
        label,
    }) as const

const dataCardRow = <Source extends string = typeof source>() =>
    ({
        source: source as NoInfer<Source>,
        label,
        render: (_, data) => value(data),
    }) as const satisfies DataListDetails<any, any>

const balanceDue = {
    source,
    label,
    value,
    tableColumn,
    dataCardRow,
    sort,
}

export default balanceDue
