import { type ComponentProps } from 'react'

import Icons from 'assets/icons'
import { InfoCard, InfoCardHeader } from 'components'
import { urls } from 'configs'
import { useGetBillingInvoices } from 'resources/billing'
import { type SvgIcon, LinkButton, Typography } from 'ui'

interface InvoiceLinkProps extends ComponentProps<typeof LinkButton> {
    Icon: typeof SvgIcon
}

const InvoiceLink = ({ children, Icon, ...props }: InvoiceLinkProps) => {
    return (
        <LinkButton
            display="flex"
            alignItems="center"
            gap="4px"
            {...props}
        >
            <Icon sx={{ width: '16px', height: '16px' }} />
            {children}
        </LinkButton>
    )
}

const CompanyPaymentHistory = () => {
    // TODO: BE - add limit and filter to this endpoint
    const { data } = useGetBillingInvoices()

    const firstInvoice = data?.find((invoice) => invoice.invoicePdf)
    const invoicePdf = firstInvoice?.invoicePdf

    return (
        <InfoCard>
            <InfoCardHeader title={<Typography variant="subtitle2">Payment history</Typography>} />
            <InvoiceLink
                disabled={!invoicePdf}
                download
                href={invoicePdf}
                mb="12px"
                Icon={Icons.Export}
            >
                Download Last Receipt
            </InvoiceLink>
            <InvoiceLink
                Icon={Icons.ReceiptOutlined}
                to={urls.paymentHistory}
            >
                View Payment History
            </InvoiceLink>
        </InfoCard>
    )
}

export default CompanyPaymentHistory
