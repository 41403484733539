import { useGetRoles } from 'resources/roles'
import { Grid, PageContent, Typography } from 'ui'

import { UserRoleCard } from './components'

const UserRoles = () => {
    const roles = useGetRoles()

    if (roles.isError || roles.isFetching || !roles.data?.length) {
        return null
    }

    return (
        <PageContent>
            <Typography
                variant="h6"
                mb="16px"
                color="text.primary"
            >
                User Roles
            </Typography>

            <Grid
                container
                spacing={4}
            >
                {roles.data.map((role) => (
                    <Grid
                        item
                        md={4}
                        xs={12}
                        key={role.id}
                    >
                        <UserRoleCard role={role} />
                    </Grid>
                ))}
            </Grid>
        </PageContent>
    )
}

export default UserRoles
