import { type FC, useState } from 'react'

import { inject, observer } from 'mobx-react'

import Icons from 'assets/icons'
import { type AuthStore } from 'core/auth'
import { globalClassNames } from 'lib'
import { shopFields, RsSelectorMenu } from 'resources/shops'
import { DataAvatar, Typography, BoxContainer, StyledElement } from 'ui'

import SidebarHide from './SidebarHide'
import SidebarTooltip from './SidebarTooltip'

const SidebarRsSelector: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
        const disableMenu = auth.user.membership.shops.length === 1

        const handleMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
            setAnchorEl(event.currentTarget)
        }

        const handleMenuClose = () => {
            setAnchorEl(null)
        }

        return (
            <>
                <BoxContainer
                    sx={{
                        px: '16px',
                        minHeight: '48px',
                        cursor: 'pointer',
                    }}
                    onClick={disableMenu ? undefined : handleMenuClick}
                >
                    <SidebarTooltip
                        title="Repair Shop"
                        placement="right"
                        arrow
                    >
                        <DataAvatar
                            sx={{
                                width: 32,
                                height: 32,
                                '& > *': auth.shop
                                    ? undefined
                                    : {
                                          width: 20,
                                          height: 20,
                                          color: '#FFFFFF !important',
                                      },
                            }}
                            defaultImage={
                                <shopFields.avatar.Icon
                                    record={auth.shop}
                                    fontWeight={400}
                                    variant="body2"
                                />
                            }
                            avatarOpacity={auth.shop ? 1 : 0.54}
                            color={(theme) => auth.shop?.avatarColor || theme.palette.text.primary}
                        />
                    </SidebarTooltip>

                    <SidebarHide>
                        <BoxContainer
                            justifyContent="space-between"
                            width="100%"
                        >
                            <StyledElement sx={{ display: 'grid', pr: '16px' }}>
                                <Typography
                                    variant="menuItem"
                                    sx={{
                                        marginLeft: '28px',
                                    }}
                                    className={globalClassNames.ellipsis}
                                >
                                    {auth.shop ? auth.shop.name : 'None'}
                                </Typography>
                            </StyledElement>
                            {!disableMenu && (
                                <Icons.ArrowDropDownOutlined
                                    sx={{
                                        color: (theme) => theme.palette.text.primary,
                                        opacity: 0.54,
                                    }}
                                />
                            )}
                        </BoxContainer>
                    </SidebarHide>
                </BoxContainer>
                <RsSelectorMenu
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                />
            </>
        )
    }),
)

export default SidebarRsSelector
