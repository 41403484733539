import { useResourceContext } from 'react-admin'

import Icons from 'assets/icons'
import { ViewHeader } from 'components'
import { PTLaborDrawerToggler } from 'resources/pricingTiers'
import { FixedOnMobile, IconBox, Button } from 'ui'

const ListHeader = () => {
    const resource = useResourceContext()

    return (
        <ViewHeader
            title="Labor Sale Rates"
            pt="28px"
            pb="20px"
        >
            <ViewHeader.Content>
                <IconBox title="The labor rate is the hourly cost you charge customers for labor job items. ">
                    <Icons.InfoOutlined />
                </IconBox>
            </ViewHeader.Content>
            <ViewHeader.Content placement="right">
                <FixedOnMobile>
                    <PTLaborDrawerToggler resource={resource}>
                        {(open) => (
                            <Button
                                variant="contained"
                                startIcon={<Icons.Add />}
                                onClick={open}
                            >
                                create
                            </Button>
                        )}
                    </PTLaborDrawerToggler>
                </FixedOnMobile>
            </ViewHeader.Content>
        </ViewHeader>
    )
}

export default ListHeader
