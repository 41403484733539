import { type FC, type ReactElement } from 'react'

import { CheckboxInput, useOpenUtilityDrawer, UtilityDrawerForm } from 'components'
import { isInputDisabledDefault } from 'utils'

interface Props {
    children: (params: () => void) => ReactElement
}

const ManageTotalDrawerToggler: FC<Props> = ({ children }) => {
    const open = useOpenUtilityDrawer()

    const readOnly = false

    return children(() => {
        open({
            drawerArgs: {
                title: 'Remove Fees and Taxes',
                renderContent: () => <Content isInputDisabled={() => readOnly} />,
                renderWrapper: (params) => <UtilityDrawerForm {...params} />,
                renderBottomRight: (render) => render({ formType: 'edit' }),
            },
        })
    })
}

export default ManageTotalDrawerToggler

interface ContentProps {
    isInputDisabled?: (source: string) => boolean
}

const Content: FC<ContentProps> = ({ isInputDisabled = isInputDisabledDefault }) => {
    return (
        <>
            <CheckboxInput
                source="removeFee"
                label="Remove fees for this invoice"
                disabled={isInputDisabled('removeFee')}
                helperText={false}
            />

            <CheckboxInput
                source="removeTax"
                label="Remove taxes for this invoice"
                disabled={isInputDisabled('removeTax')}
            />
        </>
    )
}
