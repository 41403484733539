import { type FC } from 'react'

import { type Size } from 'appTypes'
import { cssVariables, styled, themeColor, type ThemeColorType } from 'lib'

import SvgIcon from './SvgIcon'

interface Props {
    size?: Size
    color?: ThemeColorType
    component: FC
}

const IconElement: FC<Props> = styled(SvgIcon, {
    shouldForwardProp: (prop: keyof Props) => prop !== 'size' && prop !== 'color',
    name: 'IconElement',
    slot: 'root',
})<Props>`
    width: ${({ size }) => size || `var(${cssVariables.iconSize}, 20px)`};
    height: ${({ size }) => size || `var(${cssVariables.iconSize}, 20px)`};
    color: ${({ theme, color }) => themeColor(color, theme) || 'inherit'};
`

IconElement.defaultProps = {
    // @ts-ignore
    inheritViewBox: true,
}

export default IconElement
