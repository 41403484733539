import { Navigate, Route, Routes } from 'lib'
import { PageContent } from 'ui'

import PricingTiersFees from './Fees'
import PricingTiersLabors from './Labor'
import PricingTiersParts from './Parts'
import PricingTiersServices from './Services'
import PricingTiersTaxes from './Taxes'
import { TabsSwitch } from './component'

export const RSPricingTiers = () => {
    return (
        <PageContent>
            <TabsSwitch />
            <Routes>
                <Route
                    path="parts"
                    element={<PricingTiersParts />}
                />
                <Route
                    path="labor"
                    element={<PricingTiersLabors />}
                />
                <Route
                    path="services"
                    element={<PricingTiersServices />}
                />
                <Route
                    path="fees"
                    element={<PricingTiersFees />}
                />
                <Route
                    path="taxes"
                    element={<PricingTiersTaxes />}
                />
                <Route
                    element={
                        <Navigate
                            to="parts"
                            replace
                        />
                    }
                    path="*"
                />
            </Routes>
        </PageContent>
    )
}
