import { supportEmail } from 'configs'
import { Alert, Anchor, Section, SectionTitle } from 'ui'

const DeleteCompanyAccountSection = () => {
    return (
        <Section>
            <SectionTitle>Delete Company Account</SectionTitle>

            <Alert
                variant="standard"
                severity="info"
            >
                If you want to permanently delete your company account, please contact us at
                <Anchor href={`mailto:${supportEmail}`}> {supportEmail}</Anchor>
            </Alert>
        </Section>
    )
}

export default DeleteCompanyAccountSection
