import { useEffect, useMemo, useState } from 'react'

import { debounce as debounceFunc } from 'utils'

const useDebounce = (cb, { time = 500 } = {}) => {
    const debounce = useMemo(() => {
        return debounceFunc((...args) => {
            cb(...args)
        }, time)
    }, [])

    useEffect(() => {
        return debounce.cancel
    }, [debounce])

    return debounce
}
export const useNativeDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value)

    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value)
            }, delay)

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler)
            }
        },
        [value, delay], // Only re-call effect if value or delay changes
    )

    return debouncedValue
}

export default useDebounce
