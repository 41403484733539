import { type FC } from 'react'

import Icons from 'assets/icons'
import { useTelematicsContext } from 'resources/telematics'
import { Badge, badgeClasses } from 'ui'

const icon = (
    <Icons.Menu
        sx={{
            color: (theme) => theme.palette.primary.main + '!important',
            width: '20px',
        }}
    />
)
const MenuButton: FC = () => {
    const { errorCount, hasUnseenVehicles } = useTelematicsContext()

    if (errorCount || hasUnseenVehicles) {
        return (
            <Badge
                color={errorCount ? 'error' : 'primary'}
                variant="dot"
                sx={{
                    [`& .${badgeClasses.badge}`]: {
                        mt: '5px',
                    },
                }}
            >
                {icon}
            </Badge>
        )
    }

    return icon
}

export default MenuButton
