import { PageContent } from 'ui'

import { InvoicingSection } from './components'

const RepairShopInvoices = () => {
    return (
        <PageContent>
            <InvoicingSection />
        </PageContent>
    )
}

export default RepairShopInvoices
