import { type FC } from 'react'

import { SmallSection } from 'ui'

import WoItemsCard from './WoItemsCard'

const WoItemsSection: FC = () => {
    return (
        <SmallSection>
            <WoItemsCard />
        </SmallSection>
    )
}

export default WoItemsSection
