import { type FC } from 'react'

import { alpha, globalClassNames } from 'lib'
import { MenuItem, SvgIcon, type MenuItemProps, Typography } from 'ui'

interface ProfileMenuItemProps extends Pick<MenuItemProps, 'onClick' | 'className'> {
    text: string
    Icon: FC
    RightIcon?: FC
}

const SidebarProfileMenuItem = ({
    text,
    Icon,
    onClick,
    RightIcon,
    className,
}: ProfileMenuItemProps) => {
    return (
        <MenuItem
            sx={{
                padding: '6px 16px 6px 13px',
                gap: '17px',
            }}
            role="none"
            onClick={onClick}
            className={className}
        >
            <SvgIcon
                component={Icon}
                sx={{
                    width: '20px',
                    height: '20px',
                    color: (theme) => alpha(theme.palette.text.main, 0.54),
                }}
                inheritViewBox
            />
            <Typography
                variant="menuItem"
                className={globalClassNames.ellipsis}
            >
                {text}
            </Typography>
            {RightIcon && (
                <SvgIcon
                    component={RightIcon}
                    sx={{
                        width: '24px',
                        height: '24px',
                        color: (theme) => alpha(theme.palette.text.main, 0.54),
                        ml: 'auto',
                    }}
                />
            )}
        </MenuItem>
    )
}

export default SidebarProfileMenuItem
