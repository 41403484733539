import { type FC, useState } from 'react'

import { inject, observer } from 'mobx-react'

import { type AuthStore } from 'core/auth'
import { shopFields, RsSelectorMenu } from 'resources/shops'
import { DataAvatar } from 'ui'

export const RsButton: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

        const handleMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
            setAnchorEl(event.currentTarget)
        }

        const handleMenuClose = () => {
            setAnchorEl(null)
        }

        return (
            <>
                <DataAvatar
                    sx={{
                        width: 24,
                        height: 24,
                        '& > *': {
                            width: '16px !important',
                            height: '16px !important',
                            color: auth.shop ? undefined : '#FFFFFF !important',
                        },
                    }}
                    defaultImage={
                        <shopFields.avatar.Icon
                            record={auth.shop}
                            fontWeight={400}
                            variant="chartLabel"
                        />
                    }
                    avatarOpacity={auth.shop ? 1 : 0.54}
                    color={(theme) => auth.shop?.avatarColor || theme.palette.text.primary}
                    onClick={handleMenuClick}
                />
                <RsSelectorMenu
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                />
            </>
        )
    }),
)
