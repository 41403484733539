import { useShowContext } from 'react-admin'

import { InfoCard, InfoCardDetails, InfoCardHeader, SkeletonSliderCardContainer } from 'components'
import { shopFields } from 'resources/shops'
import { type ShopModel } from 'resourcesBase'
import { Skeleton } from 'ui'

const ShopRateCardSkeleton = () => {
    return (
        <SkeletonSliderCardContainer
            sx={{
                height: '101px',
                gap: '10px',
            }}
        >
            <Skeleton
                height="8px"
                width="362px"
                sx={{
                    my: '20px',
                }}
            />
            <Skeleton />
        </SkeletonSliderCardContainer>
    )
}

const ShopRateCard = () => {
    const { isLoading } = useShowContext()
    if (isLoading) {
        return <ShopRateCardSkeleton />
    }
    return (
        <InfoCard>
            <InfoCardHeader title="Shop Rate Details" />
            <InfoCardDetails<ShopModel>
                details={[
                    {
                        label: shopFields.hourlyLaborRate.label,
                        source: (record) => shopFields.hourlyLaborRate.value(record),
                    },
                ]}
            />
        </InfoCard>
    )
}

export default ShopRateCard
