import { type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import { useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'
import { type Serializer } from 'core'

import { type PtTaxModel } from '../types'
import { ptTaxPreferencesResource } from '../utils'

import { PTTaxForm } from './PTTaxForm'

export interface PTTaxDrawerTogglerProps {
    children: (open: () => void) => ReactElement
    resource: string
    id?: Identifier
}

const PTTaxDrawerToggler = ({ children, id, resource }: PTTaxDrawerTogglerProps) => {
    const open = useOpenUtilityDrawer()

    return children(() => {
        open({
            drawerArgs: {
                title: id ? 'Edit Tax' : 'Create Tax',
                renderWrapper: (params) => (
                    <UtilityDrawerEditor
                        {...params}
                        serializer={ptTaxSerializer}
                    />
                ),
                renderContent: () => <PTTaxForm />,
            },
            extraArgs: {
                id,
                resource: {
                    resource,
                    name: ptTaxPreferencesResource.name,
                },
            },
        })
    })
}

export default PTTaxDrawerToggler

const ptTaxSerializer: Serializer<PtTaxModel> = [
    'name',
    { name: 'markupPercent', parse: 'number' },
    { name: 'isDefault', parse: 'boolean' },
]
