import { styled, useLocation, useNavigate } from 'lib'
import { ToggleButton, ToggleButtonGroup, Typography } from 'ui'

const getValue = (pathname: string) => {
    return pathname.split('/').pop()
}
export const TabsSwitch = () => {
    const { pathname } = useLocation()
    const navigate = useNavigate()

    return (
        <ToggleButtonGroup
            value={getValue(pathname)}
            exclusive
            onChange={(e, value) => {
                if (value) {
                    navigate(value)
                }
            }}
            sx={{
                '&>*': {
                    width: 93,
                    height: 40,
                },
            }}
        >
            <StyledButton value="parts">
                <Typography variant="componentButtonSmall">Parts</Typography>
            </StyledButton>
            <StyledButton value="labor">
                <Typography variant="componentButtonSmall">Labor</Typography>
            </StyledButton>
            <StyledButton value="services">
                <Typography variant="componentButtonSmall">Services</Typography>
            </StyledButton>
            <StyledButton value="fees">
                <Typography variant="componentButtonSmall">Fees</Typography>
            </StyledButton>
            <StyledButton value="taxes">
                <Typography variant="componentButtonSmall">Taxes</Typography>
            </StyledButton>
        </ToggleButtonGroup>
    )
}

const StyledButton = styled(ToggleButton)`
    ${({ theme }) => `
        color:${theme.palette.text.secondary}
    `}
`
