import { inject, observer } from 'mobx-react'

import Icons from 'assets/icons'
import images from 'assets/images'
import { DialogSelector, type DialogSelectorProps } from 'components'
import { type AuthStore } from 'core/auth'

import self from '../../fields/self'
import { type CK33Model } from '../../types'
import { CK33Levels, ck33Resource, getCk33Info } from '../../utils'

import { BackButton } from './BackButton'
import { CK33Breadcrumbs } from './Breadcrumbs'
import { CurrentLevel } from './CurrentLevel'
import { OptionMenu } from './OptionsMenu'
import { favoritesSource } from './utils'

interface CK33InputProps
    extends Partial<Omit<DialogSelectorProps<CK33Model>, 'defaultSelectorValueSource'>> {
    excludeLevelAndBelow?: CK33Levels
}

export const CK33Input = inject('auth')(
    observer(({ auth, excludeLevelAndBelow, ...props }: CK33InputProps & { auth?: AuthStore }) => {
        const { levels, label } = getCk33Info(auth, excludeLevelAndBelow)

        const canBeSelected = (level: number) => {
            if (level === CK33Levels.GROUP && !auth.companySettings.ck33GroupsSelectable) {
                return false
            }

            return true
        }

        return (
            <DialogSelector<CK33Model>
                defaultFilter={{ level: levels[0] }}
                source="component"
                titleSource={(record) => (record ? self.value(record) : label)}
                reference={ck33Resource.resource}
                itemPrimary={(record) => {
                    const code = self.formatCode(record.code, record.level)
                    if (record.favorite) {
                        return (
                            <>
                                {code}{' '}
                                <Icons.Star
                                    sx={{
                                        color: (theme) => theme.palette.charts.orange,
                                        width: '16px',
                                        height: '16px',
                                    }}
                                />
                            </>
                        )
                    }

                    return code
                }}
                itemSecondary="text"
                defaultSelectorProps={{
                    label,
                }}
                renderListItem={(record, render) => (
                    <OptionMenu
                        canBeSelected={canBeSelected}
                        levels={levels}
                        key={record.id}
                        choice={record}
                        renderToggler={(open) => {
                            return render({
                                onSelect: open,
                            })
                        }}
                    />
                )}
                defaultSelectorValueSource={(data) => {
                    return data.selected ? self.value(data.selected) : ''
                }}
                filter={{
                    search: {
                        placeholder: 'Search by Code or Name',
                    },
                    select: {
                        items: [
                            {
                                value: '',
                                Icon: Icons.ListOutlined,
                            },
                            {
                                value: true,
                                Icon: Icons.Star,
                            },
                        ],
                        source: favoritesSource,
                        makeFilters: (filter) => {
                            if (filter[favoritesSource]) {
                                return { ...filter, level: levels.filter(canBeSelected) }
                            }
                            return filter
                        },
                    },
                }}
                appBar={{
                    leftButton: <BackButton />,
                    paddingX: '12px',
                }}
                renderTop={() => <CK33Breadcrumbs />}
                renderNextToResultCount={() => <CurrentLevel levels={levels} />}
                noResults={({ filterValues, searchValue }) => {
                    if (filterValues[favoritesSource] && !searchValue) {
                        return {
                            image: images.favorites,
                            title: 'No Favorites Yet!',
                            text: "Use the 'Add to Favorites' action in the list of items to save them here.",
                        }
                    }
                }}
                {...props}
            />
        )
    }),
)

export const CK33InputWithoutParts = (props: CK33InputProps) => {
    return (
        <CK33Input
            excludeLevelAndBelow={CK33Levels.COMPONENT}
            {...props}
        />
    )
}
