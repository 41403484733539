import { type FC, type ReactNode } from 'react'

import { inject, observer } from 'mobx-react'

import { FixedTopPart } from 'components/pages/header'
import { type AuthStore } from 'core/auth'
import { styled } from 'lib'
import { BillingPaymentIssue } from 'resources/billing'
import { TelematicsSyncBar } from 'resources/telematics'

import AppHeader from '../AppHeader'
import GlobalProviders from '../GlobalProvider'
import Main from '../Main'
import Sidebar from '../Sidebar'
import SidebarBackdrop from '../Sidebar/SidebarBackdrop'

import NoRepairShops from './NoRepairShops'

interface MainLayoutProps {
    children: ReactNode
}

const MainLayout: FC<MainLayoutProps> = inject('auth')(
    observer(({ children, auth }: MainLayoutProps & { auth: AuthStore }) => {
        const membership = auth.getMembership()

        if (!membership.shops.length) {
            return <NoRepairShops />
        }

        return (
            <GlobalProviders>
                <FixedTopPart>
                    <BillingPaymentIssue />
                    <TelematicsSyncBar />
                    <AppHeader />
                </FixedTopPart>
                <Content>
                    <Sidebar />
                    <SidebarBackdrop />
                    <Main sx={{ flex: 1 }}>{children}</Main>
                </Content>
            </GlobalProviders>
        )
    }),
)

const Content = styled('div')`
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    overflow-x: hidden;
`

export default MainLayout
