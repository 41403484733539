import { type FC } from 'react'

import { useRecordContext } from 'react-admin'

import { CardDefaultSkeleton, InfoCard, InfoCardDetails } from 'components'
import { type InvoiceModel } from 'resources/invoices'

const CustomerPriceTiersCard: FC = () => {
    const record = useRecordContext<InvoiceModel>()

    if (!record) {
        return <CardDefaultSkeleton />
    }

    return (
        <InfoCard>
            <InfoCardDetails
                details={[
                    {
                        label: 'Parts',
                        source: () => 'test',
                    },
                    {
                        label: 'Labor rate',
                        source: () => 'test',
                    },
                    {
                        label: 'Service',
                        source: () => 'test',
                    },
                    {
                        label: 'Fees',
                        source: () => 'test',
                    },
                    {
                        label: 'Taxes',
                        source: () => 'test',
                    },
                ]}
            />
        </InfoCard>
    )
}

export default CustomerPriceTiersCard
