import { type FC } from 'react'

import { ViewHeader } from 'components'
import { GridContainerColumns, GridItemLayout, PageContent } from 'ui'

import { DirectlyAddedSection, TotalSection, WoItemsSection } from './sections'

const InvoiceLineItems: FC = () => {
    return (
        <PageContent>
            <GridContainerColumns>
                <GridItemLayout>
                    <ViewHeader title="Line Items" />
                    <WoItemsSection />
                    <DirectlyAddedSection />
                    <TotalSection />
                </GridItemLayout>
            </GridContainerColumns>
        </PageContent>
    )
}

export default InvoiceLineItems
