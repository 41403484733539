import { basePermissions } from 'core'
import { companyPermissionName } from 'resources/company'
import { partsResource } from 'resources/parts'
import { unitResource } from 'resources/units'
import { woResource, woPermissions } from 'resources/workOrders'

type TranslatorType = {
    id: string
    label: string
    permissions: {
        [key: string]: string
    }
}

const { retrieve, create, update, destroy, archive, pdf } = basePermissions

const permissionsTranslator: TranslatorType[] = [
    {
        id: companyPermissionName,
        label: 'Company',
        permissions: {
            [retrieve]: 'View',
            [update]: 'Edit',
            [destroy]: 'Deactivate',
        },
    },
    {
        id: 'shops',
        label: 'Repair Shops',
        permissions: {
            [retrieve]: 'View',
            [update]: 'Edit',
        },
    },
    {
        id: 'members',
        label: 'Users',
        permissions: {
            [retrieve]: 'View Users',
            [create]: 'Invite Users',
            [update]: 'Edit User Role',
            [destroy]: 'Delete Users',
        },
    },
    {
        id: 'subscription',
        label: 'Billing',
        permissions: {
            [retrieve]: 'View Subscription',
            [create]: 'Create Subscription',
        },
    },
    {
        id: woResource.name,
        label: 'Work Orders',
        permissions: {
            [create]: 'Create',
            [retrieve]: 'View',
            [update]: 'Edit',
            [destroy]: 'Delete',
            [pdf]: 'Export as PDF',
        },
    },
    {
        id: woResource.name,
        label: 'Work Order Status',
        permissions: {
            [woPermissions.close]: 'Open to Closed',
            [woPermissions.cancel]: 'Open to Canceled',
            [woPermissions.reopen]: 'Closed to Open',
        },
    },
    {
        id: unitResource.name,
        label: 'Units',
        permissions: {
            [create]: 'Create',
            [retrieve]: 'View',
            [update]: 'Edit',
            [archive]: 'Archive',
            [destroy]: 'Delete',
        },
    },
    {
        id: partsResource.name,
        label: 'Parts',
        permissions: {
            [create]: 'Create',
            [retrieve]: 'View',
            [update]: 'Edit',
            [destroy]: 'Delete',
        },
    },
]

export default permissionsTranslator
