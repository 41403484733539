import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { urls } from 'configs'
import { type AuthStore } from 'core/auth'
import {
    globalClassNames,
    matchPath,
    styled,
    useMediaQuery,
    useNavigate,
    useQueryClient,
    useResetQueries,
} from 'lib'
import { type ShopModel } from 'resourcesBase'
import {
    DataAvatar,
    SimpleScrollbar,
    Typography,
    BoxContainer,
    StyledElement,
    Menu,
    MenuItem,
    Stack,
} from 'ui'

import avatar from '../fields/avatar'

interface Props {
    anchorEl: HTMLElement | null
    onClose: () => void
}

export const RsSelectorMenu: FC<Props> = inject('auth')(
    observer(({ auth, anchorEl, onClose }: Props & { auth: AuthStore }) => {
        const reset = useResetQueries()
        const matches = useMediaQuery((theme) =>
            theme.breakpoints.up(theme.props.mobileViewBreakpoint),
        )
        const queryClient = useQueryClient()
        const shops = auth.user.membership.shops
        const navigate = useNavigate()

        if (shops.length === 1) {
            return null
        }

        const open = Boolean(anchorEl)
        const selectedShopId = auth.shopId

        const setShop = (shopId: Identifier | null) => {
            auth.setShop(shopId)
            if (matchPath(urls.root, window.location.pathname)) {
                reset()
            } else {
                navigate(urls.root)
                queryClient.clear()
            }
            onClose()
        }

        return (
            <Menu
                anchorOrigin={
                    matches
                        ? { vertical: 'center', horizontal: 'right' }
                        : { vertical: 'bottom', horizontal: 'right' }
                }
                transformOrigin={
                    matches
                        ? { vertical: 'bottom', horizontal: 'left' }
                        : { vertical: 'top', horizontal: 'center' }
                }
                onClose={onClose}
                open={open}
                anchorEl={anchorEl}
            >
                <li>
                    <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        my="8px"
                        ml="12px"
                        component="div"
                    >
                        Repair Shops
                    </Typography>
                    <SimpleScrollbar
                        sx={{
                            maxHeight: '330px',
                            [`& .simplebar-content`]: {
                                width: '300px',
                            },
                        }}
                    >
                        <StyledElement
                            as="ul"
                            sx={{ padding: 0 }}
                        >
                            <Item
                                onClick={() => setShop(null)}
                                selected={!selectedShopId}
                            >
                                <BoxContainer gap="8px">
                                    <DataAvatar
                                        sx={{
                                            '& > *': {
                                                color: '#FFFFFF !important',
                                            },
                                        }}
                                        defaultImage={<Icons.Shop />}
                                        avatarOpacity={0.54}
                                        color={(theme) => theme.palette.text.primary}
                                    />
                                    <Typography variant="menuItem">View All</Typography>
                                </BoxContainer>
                            </Item>

                            {shops.map((shop) => (
                                <ShopRow
                                    key={shop.id}
                                    shop={shop}
                                    selected={shop.id === selectedShopId}
                                    setShop={setShop}
                                />
                            ))}
                        </StyledElement>
                    </SimpleScrollbar>
                </li>
            </Menu>
        )
    }),
)

interface ShopRowProps {
    shop: ShopModel
    selected: boolean
    setShop: (shopId: Identifier) => void
}

const ShopRow: FC<ShopRowProps> = ({ shop, selected, setShop }) => {
    const address = getAddressValue(shop)
    const details = getCityStateZipValue(shop)

    return (
        <Item
            selected={selected}
            onClick={() => setShop(shop.id)}
        >
            <Stack
                gap="8px"
                direction="row"
                alignItems="center"
                overflow="hidden"
            >
                <DataAvatar
                    defaultImage={<avatar.Icon record={shop} />}
                    avatarOpacity={1}
                    color={shop.avatarColor}
                />
                <Stack overflow="hidden">
                    <Typography
                        variant="menuItem"
                        className={globalClassNames.ellipsis}
                    >
                        {shop.name}
                    </Typography>
                    {address ? (
                        <Typography
                            variant="chartsBody"
                            className={globalClassNames.ellipsis}
                        >
                            {address}
                        </Typography>
                    ) : null}
                    {details ? (
                        <Typography
                            variant="chartsBody"
                            className={globalClassNames.ellipsis}
                        >
                            {details}
                        </Typography>
                    ) : null}
                </Stack>
            </Stack>
        </Item>
    )
}

const getAddressValue = (shop: Pick<ShopModel, 'address' | 'address2'>): string =>
    [shop.address, shop.address2].filter(Boolean).join(', ')

const getCityStateZipValue = (shop: Pick<ShopModel, 'city' | 'zipCode' | 'stateData'>): string =>
    [shop.city, shop.stateData?.name, shop.zipCode].filter(Boolean).join(', ')

const Item = styled(MenuItem)`
    padding-top: 12px;
    padding-bottom: 12px;
`
