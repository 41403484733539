import { observer } from 'mobx-react'
import { useShowContext } from 'react-admin'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import images from 'assets/images'
import {
    List,
    ViewHeader,
    ListBase,
    ListTotalBadge,
    type CardListConfig,
    ListAvatar,
    type DatagridColumnsProps,
    ListFilterDateRangeValueInput,
    type ListSortContentProps,
    type FilterConfig,
    ListFilterRangeInput,
    formatMoney,
    ResourceLinkButton,
    costMaskParams,
} from 'components'
import { urls } from 'configs'
import { multiselectAction, type SortPayload } from 'core'
import { formatDate } from 'lib'
import { useInventoryContext } from 'resources/inventory'
import { type PartModel } from 'resources/parts'
import { purchaseHistoryResource } from 'resources/purchaseHistory'
import { vendorFields, vendorsResource } from 'resources/vendors'
import { IconBox, PageContent, Typography } from 'ui'

import {
    PurchaseHistoryDrawerToggler,
    type PurchaseHistoryModel,
    actions,
    bulkActions,
    createResourcePath,
} from './components'

const defaultSort: SortPayload<PurchaseHistoryModel> = {
    field: 'purchaseDate',
    order: 'DESC',
}
const sortCfg: ListSortContentProps<PurchaseHistoryModel> = {
    sortBy: [
        { id: 'vendor', label: 'Vendor' },
        { id: 'purchaseDate', label: 'Purcahse Date' },
        { id: 'price', label: 'Part Cost' },
    ],
}
const filterCfg: FilterConfig<PurchaseHistoryModel> = {
    filters: [
        { id: 'vendor', label: 'Vendor' },
        {
            id: 'purchaseDate',
            label: 'Purcahse Date',
            filterType: 'range',
            renderComponent: (props) => <ListFilterDateRangeValueInput {...props} />,
        },
        {
            id: 'price',
            label: 'Part Cost',
            filterType: 'range' as const,
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
    ],
}
const columnsConfig: DatagridColumnsProps<PurchaseHistoryModel & { avatar: string }> = {
    constantColumns: {
        purchaseDate: true,
    },
    columns: [
        {
            field: 'avatar',
            headerName: 'Avatar',
            maxWidth: 72,
            renderCell: ({ row }) => (
                <ListAvatar
                    linkProps={{ 'aria-label': `View Vendor ${row.vendorData.name}` }}
                    id={row.id}
                    customPath={{
                        resource: vendorsResource.resource,
                        id: row.vendor,
                        type: 'edit',
                    }}
                    imageSrc={row.vendorData.logo}
                    defaultImage={<vendorFields.avatar.Icon />}
                />
            ),
        },
        {
            field: 'vendor',
            headerName: 'Vendor',
            renderCell: ({ row }) => (
                <ResourceLinkButton
                    to={{ type: 'edit', id: row.vendor, resource: vendorsResource.resource }}
                >
                    {row.vendorData.name}
                </ResourceLinkButton>
            ),
        },
        {
            headerName: 'Purchase Date',
            field: 'purchaseDate',
            valueFormatter: ({ value }) =>
                formatDate(value, (dateFormats) => dateFormats.shortenedDate),
        },
        {
            headerName: 'Part Cost',
            field: 'price',
            renderCell: ({ value }) => formatMoney(value),
        },
    ],
    actions: ({ id }, { children }) => actions(id, children),
}
const navigateTo = (id: Identifier) => `${urls.vendors}/${id}`
const cardsConfig: CardListConfig<PurchaseHistoryModel> = {
    titleSource: (record) => record.vendorData.name,
    imageSource: (record) => record.vendorData.logo,
    titleLink: (record) => navigateTo(record.vendor),
    defaultImage: <vendorFields.avatar.Icon />,
    details: [
        {
            label: 'Purchase Date',
            source: 'purchaseDate',
            render: (value) => formatDate(value, (dateFormats) => dateFormats.shortenedDate),
        },
        {
            label: 'Part Cost',
            source: 'price',
            render: (value) => formatMoney(value),
        },
    ],
    actions: ({ id }, { children }) => [
        ...actions(id, children),
        multiselectAction({
            children,
            id,
        }),
    ],
}

const PurchaseHistory = observer(() => {
    const { record } = useShowContext<PartModel>()
    const inventoryView = useInventoryContext()

    if (!record) {
        return null
    }

    const path = createResourcePath(record.id)

    return (
        <PageContent>
            <ListBase<PurchaseHistoryModel>
                resource={path}
                sort={defaultSort}
                preferencesResource={purchaseHistoryResource}
            >
                <ViewHeader title="Purchase History">
                    <ViewHeader.Content>
                        <ListTotalBadge />
                    </ViewHeader.Content>
                    {inventoryView ? null : (
                        <ViewHeader.Content placement="rightMobile">
                            <PurchaseHistoryDrawerToggler>
                                {(open) => (
                                    <IconBox
                                        onClick={open}
                                        title="Add Manual Entry"
                                    >
                                        <Icons.Add />
                                    </IconBox>
                                )}
                            </PurchaseHistoryDrawerToggler>
                        </ViewHeader.Content>
                    )}
                </ViewHeader>
                <List
                    preferencesResource={purchaseHistoryResource}
                    exportFileName={`${record.number}-purchase-history`}
                    bulkActions={bulkActions}
                    columnsCfg={columnsConfig}
                    cardsCfg={cardsConfig}
                    sortCfg={sortCfg}
                    filtersCfg={filterCfg}
                    listFTUProps={{
                        title: 'No Data',

                        action: inventoryView ? null : (
                            <PurchaseHistoryDrawerToggler>
                                {(onClick) => (
                                    <Typography
                                        color="primary.main"
                                        onClick={onClick}
                                        sx={{ cursor: 'pointer' }}
                                        variant="body1"
                                    >
                                        Add Manual Entry
                                    </Typography>
                                )}
                            </PurchaseHistoryDrawerToggler>
                        ),
                        imageSrc: images.purchaseHistoryFtu,
                        linkText: null,
                    }}
                />
            </ListBase>
        </PageContent>
    )
})

export default PurchaseHistory
