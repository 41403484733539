import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import { ViewHeader } from 'components'
import { basePermissions, useResourcePermissions } from 'core/permissions'
import { type ShopModel } from 'resourcesBase'
import { IconBox } from 'ui'

import SettingsDrawerToggler from './SettingsDrawerToggler'

const SettingsHeader = () => {
    const record = useRecordContext<ShopModel>()
    const permissions = useResourcePermissions()

    const hideAction = !permissions[basePermissions.update]
    return (
        <ViewHeader
            title="Settings"
            loading={!record}
        >
            {hideAction || (
                <ViewHeader.Content placement="rightMobile">
                    <SettingsDrawerToggler id={record?.id}>
                        {({ onClick }) => (
                            <IconBox
                                onClick={onClick}
                                title="Edit"
                            >
                                <Icons.Edit />
                            </IconBox>
                        )}
                    </SettingsDrawerToggler>
                </ViewHeader.Content>
            )}
        </ViewHeader>
    )
}
export default SettingsHeader
