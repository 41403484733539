import { type ReactNode } from 'react'

import { type ListControllerProps } from 'react-admin'

import { type DataRecord } from 'appTypes'
import { type ResourceType } from 'core/resource'
import { type SortPayload } from 'core/types'

import ListBaseViewMode from './ListBaseViewMode'
import ListSortDrawerProvider from './ListSortDrawerContext'
import ListViewProvider from './ListViewContext'
import ListFilterDrawerProvider from './filter/ListFilterDrawerContext'

export interface ListBaseProps<RecordType extends DataRecord = any> extends ListControllerProps {
    sort: SortPayload<RecordType>
    preferencesResource?: ResourceType
    children: ReactNode
    isLoading?: boolean
}

const ListBase = <RecordType extends DataRecord = any>({
    children,
    sort,
    preferencesResource,
    isLoading,
    ...rest
}: ListBaseProps<RecordType>) => {
    return (
        <ListViewProvider preferencesResource={preferencesResource}>
            <ListBaseViewMode
                {...rest}
                sort={sort}
                preferencesResource={preferencesResource}
                isLoading={isLoading}
            >
                <ListFilterDrawerProvider>
                    <ListSortDrawerProvider defaultSort={sort}>{children}</ListSortDrawerProvider>
                </ListFilterDrawerProvider>
            </ListBaseViewMode>
        </ListViewProvider>
    )
}

export default ListBase
