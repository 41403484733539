import { type Size } from 'appTypes'
import { styled, generateNotForwardedProps, themeColor, type ThemeColorType } from 'lib'
import { Box } from 'ui/layouts'

interface StatusProps {
    size?: Size

    iconColor?: ThemeColorType
}

const shouldForwardProp = generateNotForwardedProps<StatusProps>(['size', 'iconColor'])

const Status = styled(Box, {
    name: 'Status',
    slot: 'root',
    shouldForwardProp,
})<StatusProps>`
    border-radius: 50%;
    width: ${({ size }) => size};
    min-width: ${({ size }) => size};
    height: ${({ size }) => size};
    background-color: ${({ theme, iconColor }) => themeColor(iconColor, theme)};
    display: inline-block;
`

Status.defaultProps = {
    size: '10px',
    iconColor: (theme) => theme.palette.primary.main,
}

export default Status
