import { useShowContext } from 'react-admin'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import images from 'assets/images'
import {
    type CardListConfig,
    type DatagridColumnsProps,
    type FilterConfig,
    List,
    ListAvatar,
    ListBase,
    type ListSortContentProps,
} from 'components'
import { urls } from 'configs'
import {
    type ActionChildren,
    deleteOneAction,
    multiselectAction,
    ResourceContextProviderWithClearEffect,
    type ResourceType,
    type SortPayload,
} from 'core'
import { globalClassNames } from 'lib'
import {
    type AssignmentModel,
    assignmentsResource,
    assignmentFields,
    deleteAssignmentParams,
    AssignmentsDrawer,
    assignmentsBulkAction,
} from 'resources/assignments'
import { createdField } from 'resources/base'
import { type CustomerModel } from 'resources/customers'
import { ArchivedUnitBadge, isUnitArchived, unitFields } from 'resources/units'
import { CK2Field } from 'resources/vmrs'
import { Anchor, BoxContainer, LinkButton, StyledElement } from 'ui'

import Header from './Header'

const defaultSort: SortPayload<AssignmentModel> = {
    field: 'start',
    order: 'DESC',
}
const CustomerAssignmentsList = () => {
    const { record, isLoading } = useShowContext<CustomerModel>()
    return (
        <ResourceContextProviderWithClearEffect value={assignmentsResource}>
            <ListBase
                isLoading={isLoading}
                sort={defaultSort}
                filter={{
                    customerId: record?.id,
                }}
                preferencesResource={preferencesResource}
            >
                <Header />
                <List
                    bulkActions={assignmentsBulkAction}
                    isLoading={isLoading}
                    preferencesResource={preferencesResource}
                    cardsCfg={cardsCfg}
                    columnsCfg={columnsCfg}
                    filtersCfg={filtersCfg}
                    sortCfg={sortCfg}
                    listFTUProps={{
                        title: 'No Assignments',
                        linkText: (
                            <AssignmentsDrawer defaultValues={{ customer: record?.id }}>
                                {(open) => <Anchor onClick={open}>Create Assignment</Anchor>}
                            </AssignmentsDrawer>
                        ),
                        imageSrc: images.expirations,
                        linkAction: () => {
                            //
                        },
                        action: null,
                    }}
                    exportFileName={`${record?.name}-assignments`}
                />
            </ListBase>
        </ResourceContextProviderWithClearEffect>
    )
}

export default CustomerAssignmentsList

const preferencesResource: ResourceType = {
    name: 'customer-assignments',
    resource: 'customer-assignments',
}

const actions = (record: AssignmentModel, children: ActionChildren, cardActions?: boolean) => {
    const actions = [
        <AssignmentsDrawer
            key="edit"
            id={record.id}
            readOnly={isUnitArchived(record.unitData)}
        >
            {(open) =>
                children({
                    title: 'View/Edit',
                    Icon: Icons.Edit,
                    onClick: open,
                })
            }
        </AssignmentsDrawer>,
        deleteOneAction({
            children,
            id: record.id,
            ...deleteAssignmentParams,
        }),
    ]
    if (cardActions) {
        return [
            actions[0],
            multiselectAction({
                children,
                id: record.id,
            }),
            ...actions.slice(1),
        ]
    }

    return actions
}

const navigateTo = (id: Identifier) => {
    return `${urls.units}/${id}`
}

const cardsCfg: CardListConfig<AssignmentModel & { vmrsEquipmentCategory: string }> = {
    titleSource: (record) => (
        <LinkButton to={navigateTo(record.unit)}>
            <BoxContainer>
                <StyledElement className={globalClassNames.ellipsis}>
                    {record.unitData.number}
                </StyledElement>
                {isUnitArchived(record.unitData) && <ArchivedUnitBadge />}
            </BoxContainer>
        </LinkButton>
    ),
    disableTitleLink: true,
    avatarColor: (_, record) => unitFields.avatar.unitAvatarColor(isUnitArchived(record.unitData)),
    titleLink: (record) => navigateTo(record.unitData.id),
    subTitleSource: (record) => assignmentFields.status.value(record.status),
    imageSource: (record) => record.unitData.photo,
    defaultImage: (record) => (
        <unitFields.avatar.DefaultIcon isArchived={isUnitArchived(record.unitData)} />
    ),
    details: [
        CK2Field.cardRow({
            id: 'vmrsEquipmentCategory',
            dataToRecord: (record: AssignmentModel) => record.unitData.vmrsEquipmentCategoryData,
        }),
        assignmentFields.startDate.dataCardRow(),
        assignmentFields.endDate.dataCardRow(),
        assignmentFields.accumulatedCost.dataCardRow(),
    ],
    actions: (record, { children }) => actions(record, children, true),
}

const columnsCfg: DatagridColumnsProps<
    AssignmentModel & { photo: string; vmrsEquipmentCategory: string }
> = {
    resetColumns: {
        [createdField.source]: false,
    },
    constantColumns: {
        unit: true,
    },
    columns: [
        {
            field: 'photo',
            headerName: 'Avatar',
            maxWidth: 72,
            renderCell: ({ row }) => {
                const isArchived = isUnitArchived(row.unitData)
                return (
                    <ListAvatar
                        linkProps={{
                            'aria-label': `View unit with unit number ${row.unitData.number}`,
                        }}
                        id={row.unitData.id}
                        imageSrc={row.unitData.photo}
                        color={unitFields.avatar.unitAvatarColor(isArchived)}
                        defaultImage={<unitFields.avatar.DefaultIcon isArchived={isArchived} />}
                        customPath={navigateTo(row.unitData.id)}
                    />
                )
            },
        },
        {
            field: unitFields.self.source,
            headerName: unitFields.self.label,
            renderCell: ({ row }) => {
                return (
                    <LinkButton to={navigateTo(row.unit)}>
                        <BoxContainer>
                            <StyledElement className={globalClassNames.ellipsis}>
                                {row.unitData.number}
                            </StyledElement>
                            {isUnitArchived(row.unitData) && <ArchivedUnitBadge />}
                        </BoxContainer>
                    </LinkButton>
                )
            },
        },
        CK2Field.column({
            id: 'vmrsEquipmentCategory',
            dataToRecord: (record: AssignmentModel) => record.unitData.vmrsEquipmentCategoryData,
        }),
        assignmentFields.startDate.tableColumn(),
        assignmentFields.endDate.tableColumn(),
        assignmentFields.status.tableColumn(),
        createdField.tableColumn({ dataToValue: (record: AssignmentModel) => record.created }),
        assignmentFields.accumulatedCost.tableColumn(),
    ],
    actions: ({ row }, { children }) => actions(row, children),
}

const filtersCfg: FilterConfig<AssignmentModel & { vmrsEquipmentCategory: string }> = {
    filters: [
        unitFields.self.filter(),
        CK2Field.filter({ id: 'vmrsEquipmentCategory' }),
        assignmentFields.startDate.filter(),
        assignmentFields.endDate.filter(),
        assignmentFields.status.filter(),
        assignmentFields.accumulatedCost.filter(),
        createdField.filter(),
    ],
}

const sortCfg: ListSortContentProps<AssignmentModel & { vmrsEquipmentCategory: string }> = {
    sortBy: [
        unitFields.self.sort(),
        CK2Field.sort({ id: 'vmrsEquipmentCategory' }),
        assignmentFields.startDate.sort(),
        assignmentFields.endDate.sort(),
        assignmentFields.accumulatedCost.sort(),
        createdField.sort(),
    ],
}
