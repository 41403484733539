import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import Icons from 'assets/icons'
import { InfoCard, formatMoney } from 'components'
import { type AuthStore } from 'core'
import { alpha, formatDate, withColor } from 'lib'
import { renderOnBilling, useOpenPaymentLimit, useOpenPaymentMethod } from 'resources/billing'
import {
    StyledElement,
    IconBox,
    Spacer,
    BoxContainer,
    Typography,
    Button,
    Alert,
    IconButton,
    Stack,
    Anchor,
} from 'ui'
import { capitalize } from 'utils'

import { TooltipContainer } from '../CurrentPlan/components/Tooltip/TooltipContainer'

import { BreakdownTooltip } from './components'

const CurrentBill: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const billing = auth.billing
        const subscription = billing.subscription
        const openPaymentLimit = useOpenPaymentLimit()
        const openPaymentMethod = useOpenPaymentMethod()

        return (
            <InfoCard sx={{ padding: '20px' }}>
                <Stack>
                    <Typography
                        variant="chartTitle"
                        color={withColor('text.secondary')}
                    >
                        Current Bill
                    </Typography>
                    <StyledElement sx={{ mt: '16px' }}>
                        <StyledElement sx={{ display: 'flex' }}>
                            <Typography variant="h4">
                                {formatMoney(billing.toBePaidWorkOrdersForPeriodTotal)}
                            </Typography>

                            <TooltipContainer title={<BreakdownTooltip billing={billing} />}>
                                <IconButton
                                    aria-label="Breakdown"
                                    sx={{
                                        ml: 'auto',
                                        width: '30px',
                                        height: '30px',
                                    }}
                                    disabled={billing.toBePaidWorkOrdersForPeriodTotal === 0}
                                    component="div"
                                >
                                    <Icons.Calculate
                                        sx={{
                                            width: '15px',
                                            height: '15px',
                                            color: (theme) =>
                                                alpha(theme.palette.text.primary, 0.54),
                                        }}
                                    />
                                </IconButton>
                            </TooltipContainer>
                        </StyledElement>
                        {subscription === 'pro' && (
                            <>
                                {billing.paymentLimit || billing.paymentLimit === 0 ? (
                                    <Spacer>
                                        <Typography
                                            variant="chartsBody"
                                            color={withColor('text.secondary')}
                                        >
                                            Limit {formatMoney(billing.paymentLimit)}
                                        </Typography>
                                        <Anchor
                                            // TODO: mui Link does not have typography,
                                            // if component prop is not set
                                            onClick={openPaymentLimit}
                                            component="button"
                                            display="inline-block"
                                        >
                                            Edit
                                        </Anchor>
                                    </Spacer>
                                ) : (
                                    <Button
                                        onClick={openPaymentLimit}
                                        size="small"
                                        startIcon={<Icons.Add />}
                                        variant="text"
                                    >
                                        Add Limit
                                    </Button>
                                )}
                                <Alert
                                    sx={{ mt: '16px', mb: '20px' /* '24px'*/ }}
                                    severity="info"
                                >
                                    Automatic billing on{' '}
                                    {formatDate(
                                        billing.currentPeriodEnd,
                                        (dateFormats) => dateFormats.shortenedDate,
                                    )}
                                </Alert>
                            </>
                        )}
                        <Typography
                            variant="chartTitle"
                            color={withColor('text.secondary')}
                        >
                            Payment Methods
                        </Typography>
                        <BoxContainer
                            sx={{
                                mt: '27px',
                            }}
                        >
                            <StyledElement>
                                <Typography
                                    variant="body2"
                                    color={withColor('text.primary')}
                                    sx={{ lineHeight: 0.9 }}
                                >
                                    {billing.card.brand &&
                                        capitalize(billing.card.brand) +
                                            ' **** ' +
                                            billing.card.last4}
                                </Typography>
                                <Typography
                                    variant="tooltip"
                                    color={withColor('text.disabled')}
                                >
                                    {billing.card.brand &&
                                        'EXP ' +
                                            (billing.card.expMonth.length > 1 ? '' : '0') +
                                            billing.card.expMonth +
                                            '/' +
                                            billing.card.expYear.substring(2, 4)}
                                </Typography>
                            </StyledElement>
                            <IconBox
                                sx={{ ml: 'auto' }}
                                onClick={() => {
                                    openPaymentMethod('change')
                                }}
                            >
                                <Icons.Edit />
                            </IconBox>
                        </BoxContainer>
                    </StyledElement>
                </Stack>
            </InfoCard>
        )
    }),
)

export default renderOnBilling(CurrentBill)
