import { type FC } from 'react'

import { useRecordContext } from 'react-admin'

import { type Identifier } from 'appTypes'
import images from 'assets/images'
import {
    type CardListConfig,
    type DatagridColumnsProps,
    type FilterConfig,
    List,
    ListBase,
    ListFilterDateRangeValueInput,
    ListFilterValueInput,
    type ListSortContentProps,
    formatMoney,
} from 'components'
import { type ResourceType, ResourceContextProviderWithClearEffect, type SortPayload } from 'core'
import { formatDate, globalClassNames } from 'lib'
import {
    type PartInInventoryModel,
    type InventoryHistoryModel,
    inventoryItemsResource,
} from 'resources/inventory'
import { getReasonForQTYAdjustmentLabel } from 'resources/reasonForQtyAdjustment'
import { Box, Tooltip, Chip, PageContent, BoxContainer } from 'ui'
import { capitalizeWithLowerCase } from 'utils'

import { InventoryHistoryHeader } from './components'

const defaultSort: SortPayload = {
    field: 'created',
    order: 'DESC',
}

const ChangeChip = ({ label }: { label: string }) => {
    return (
        <Chip
            component="span"
            sx={{
                overflow: 'hidden',
            }}
            label={label}
            variant="filled"
            size="extraSmall"
        />
    )
}

const ChangeField = ({ record }: { record: InventoryHistoryModel }) => {
    return (
        <BoxContainer
            gap="8px"
            overflow="hidden"
        >
            {record.quantityAdjustment ? <ChangeChip label="Quantity" /> : null}
            {record.costAdjustment ? <ChangeChip label="Cost" /> : null}
        </BoxContainer>
    )
}

const getEventType = (type: InventoryHistoryModel['type']): string => {
    return eventTypeMap[type]
}

const filterCfg: FilterConfig<InventoryHistoryModel & { change: string }> = {
    filters: [
        {
            label: 'Event Types',
            id: 'type',
            renderComponent: (props) => (
                <ListFilterValueInput
                    {...props}
                    inputText={(option) => option.id}
                    makeItemLabel={(record) => {
                        return getEventType(record.id as InventoryHistoryModel['type'])
                    }}
                />
            ),
        },
        {
            label: 'Change',
            id: 'change',
            renderComponent: (props) => (
                <ListFilterValueInput
                    {...props}
                    inputText={(option) => option.id}
                    makeItemLabel={(record) => {
                        return capitalizeWithLowerCase(record.id as string)
                    }}
                />
            ),
        },
        {
            label: 'Date',
            id: 'created',
            filterType: 'range',
            renderComponent: (props) => <ListFilterDateRangeValueInput {...props} />,
        },

        {
            label: 'Reason For QTY Adjustment',
            id: 'quantityAdjustmentReason',
            renderComponent: (props) => (
                <ListFilterValueInput
                    {...props}
                    inputText={(option) => option.id}
                    makeItemLabel={(record) => {
                        return getReasonForQTYAdjustmentLabel(record.id as string)
                    }}
                />
            ),
        },

        {
            label: 'Responsible User',
            id: 'createdBy',
        },
    ],
}
const sortCfg: ListSortContentProps<InventoryHistoryModel> = {
    sortBy: [
        { label: 'Event Types', id: 'type' },
        {
            label: 'Date',
            id: 'created',
        },
        { label: 'QTY Before Change', id: 'quantityBefore' },
        { label: 'QTY After Change', id: 'quantityAfter' },
        { label: 'Cost Before Change', id: 'costBefore' },
        { label: 'Cost After Change', id: 'costAfter' },
    ],
}

const eventTypeMap: {
    [key in InventoryHistoryModel['type']]: string
} = {
    PURCHASE_ORDER: 'Purchase Order',
    MANUAL: 'Manual Adjust',
    WORK_ORDER: 'Work Order',
}

const getCreatedBy = (userData: InventoryHistoryModel['createdBy']) => {
    if (!userData) {
        return null
    }
    return [userData.name, userData.email].filter(Boolean).join(' • ')
}

const columnsCfg: DatagridColumnsProps<InventoryHistoryModel & { change: string }> = {
    checkboxSelection: false,
    actions: null,
    constantColumns: {
        change: true,
        created: true,
        type: true,
    },
    columns: [
        {
            headerName: 'Event Types',
            field: 'type',
            valueFormatter: ({ value }) => getEventType(value),
        },
        {
            headerName: 'Date',
            field: 'created',
            valueFormatter: ({ value }) =>
                formatDate(value, (dateFormats) => dateFormats.shortenedDateTime),
        },
        {
            headerName: 'Change',
            field: 'change',
            renderCell: ({ row }) => <ChangeField record={row} />,
        },
        { headerName: 'QTY Before Change', field: 'quantityBefore' },
        { headerName: 'QTY After Change', field: 'quantityAfter' },
        {
            headerName: 'Reason For QTY Adjustment',
            field: 'quantityAdjustmentReason',
            valueFormatter: ({ value }) => getReasonForQTYAdjustmentLabel(value),
        },
        {
            headerName: 'Cost Before Change',
            field: 'costBefore',
            headerAlign: 'right',
            align: 'right',
            renderCell: ({ value }) => formatMoney(value),
        },
        {
            headerName: 'Cost After Change',
            field: 'costAfter',
            headerAlign: 'right',
            align: 'right',
            renderCell: ({ value }) => formatMoney(value),
        },
        {
            headerName: 'Responsible User',
            field: 'createdBy',
            renderCell: ({ value }) => getCreatedBy(value),
        },
        {
            headerName: 'Comment',
            field: 'comment',
            renderCell: ({ value }) =>
                value ? (
                    <Tooltip title={value}>
                        <Box className={globalClassNames.ellipsis}>{value}</Box>
                    </Tooltip>
                ) : (
                    value
                ),
            flex: 1,
        },
    ],
}

const cardsCfg: CardListConfig<InventoryHistoryModel & { change: string }> = {
    titleSource: (record) => getEventType(record.type),
    disableTitleLink: true,
    defaultImage: undefined,
    details: [
        {
            label: 'Date',
            source: 'created',
            render: (value) => formatDate(value, (dateFormats) => dateFormats.shortenedDateTime),
        },
        {
            label: 'Change',
            source: 'change',
            render: (v, record) => <ChangeField record={record} />,
        },
        { label: 'QTY Before Change', source: 'quantityBefore' },
        { label: 'QTY After Change', source: 'quantityAfter' },
        {
            label: 'Reason For QTY Adjustment',
            source: 'quantityAdjustmentReason',
            render: (value) => getReasonForQTYAdjustmentLabel(value),
        },
        {
            label: 'Cost Before Change',
            source: 'costBefore',
            render: (value) => formatMoney(value),
        },
        {
            label: 'Cost After Change',
            source: 'costAfter',
            render: (value) => formatMoney(value),
        },
        {
            label: 'Responsible User',
            source: 'createdBy',
            render: (value) => getCreatedBy(value),
        },
        { label: 'Comment', source: 'comment' },
    ],
}

const inventoryHistoryResource = (id: Identifier): ResourceType => ({
    name: 'inventory-transactions',
    resource: `${inventoryItemsResource.resource}/${id}/transactions`,
})

const preferencesResource: ResourceType = {
    name: 'transactions',
    resource: 'transactions',
}

const InventoryHistory: FC = () => {
    const record = useRecordContext<PartInInventoryModel>()

    if (!record) {
        return null
    }

    const shopName = record.inventoryItem.shopData.name

    return (
        <PageContent>
            <ResourceContextProviderWithClearEffect
                value={inventoryHistoryResource(record.inventoryItem.id)}
            >
                <ListBase
                    sort={defaultSort}
                    isLoading={!record}
                    preferencesResource={preferencesResource}
                >
                    <InventoryHistoryHeader />
                    <List
                        preferencesResource={preferencesResource}
                        columnsCfg={columnsCfg}
                        cardsCfg={cardsCfg}
                        sortCfg={sortCfg}
                        filtersCfg={filterCfg}
                        exportFileName={`${record.number}-${shopName}-inventory-history`}
                        listFTUProps={{
                            title: 'No Data',
                            imageSrc: images.purchaseHistoryFtu,
                            linkText: null,
                        }}
                    />
                </ListBase>
            </ResourceContextProviderWithClearEffect>
        </PageContent>
    )
}

export default InventoryHistory
