import { type NoInfer } from 'appTypes'
import { type ListFilterChoice, type SortBy, type Column, type DataListDetails } from 'components'

import { WorkOrderLinkButton } from '../components'
import { type WorkOrderModel } from '../types'

const source = 'workOrder' as const
const label = 'Work Order'

const value = (record: WorkOrderModel) => record?.number

const linkValue = (record: WorkOrderModel) => <WorkOrderLinkButton workOrder={record} />

const tableColumn = <Source extends string = typeof source>({
    id,
    dataToRecord,
}: {
    id?: Source
    dataToRecord: (data) => WorkOrderModel
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: label,
        renderCell: ({ row }) => value(dataToRecord(row)),
    }) as const satisfies Column

const dataCardRow = <Source extends string = typeof source>({
    id,
    dataToRecord,
}: {
    id?: Source
    dataToRecord: (data) => WorkOrderModel
}) =>
    ({
        source: (id || source) as NoInfer<Source>,
        label,
        render: (_, data) => value(dataToRecord(data)),
    }) as const satisfies DataListDetails<any, any>

const filter = () =>
    ({
        id: source,
        label,
    }) as const satisfies ListFilterChoice

const sort = () =>
    ({
        id: source,
        label,
    }) as const satisfies SortBy

const self = {
    source,
    label,
    value,
    linkValue,
    tableColumn,
    dataCardRow,
    sort,
    filter,
}

export default self
