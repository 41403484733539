import { InfoCard, InfoCardDetails, InfoCardHeader } from 'components'
import { shopFields } from 'resources/shops'
import { type ShopModel } from 'resourcesBase'

const DetailsCard = () => {
    return (
        <InfoCard>
            <InfoCardHeader title="Basic Details" />
            <InfoCardDetails<ShopModel>
                details={[
                    {
                        label: shopFields.phoneNumber.label,
                        source: (record) => shopFields.phoneNumber.value(record),
                    },
                    {
                        label: shopFields.contactPerson.label,
                        source: (record) => shopFields.contactPerson.value(record),
                    },
                    {
                        label: shopFields.emailAddress.label,
                        source: (record) => shopFields.emailAddress.value(record),
                    },
                    {
                        label: shopFields.website.label,
                        source: (record) => shopFields.website.value(record),
                    },
                ]}
            />
        </InfoCard>
    )
}

export default DetailsCard
