import { type FC } from 'react'

import Icons from 'assets/icons'
import { DataAvatar } from 'ui'

import { type VendorPartModel } from '../types'

interface Props {
    record: Pick<VendorPartModel, 'image' | 'vendorData'>
}

const Avatar: FC<Props> = ({ record }) => (
    <DataAvatar
        imageSrc={record.image}
        defaultImage={record.vendorData ? <Icons.VendorParts /> : <Icons.Parts />}
    />
)

const avatar = {
    Vendor: Icons.VendorParts,
    Icon: Icons.Parts,
    Value: Avatar,
}

export default avatar
