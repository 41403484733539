import { TextInput } from 'react-admin'

import Icons from 'assets/icons'
import {
    type ActionChildren,
    basePermissions,
    maxLengthValidationText,
    renderOnPermission,
    requiredValidation,
    useConfirm,
} from 'core'
import { type InvoiceModel } from 'resources/invoices'
import { Alert } from 'ui'

import SendInvoiceDrawer from './SendInvoiceDrawer'

const descriptionValidation = [maxLengthValidationText, requiredValidation]

interface ActionProps {
    children: ActionChildren
}

export const ReopenAction = renderOnPermission(({ children }: ActionProps) => {
    const confirm = useConfirm()

    return children({
        Icon: Icons.Undo,
        onClick: () => {
            confirm({
                title: 'Reopen Invoice',
                closeOnError: false,
                content:
                    'Reopening this invoice will revert it to an editable state, allowing you to modify the details of all line items.',
                onConfirm: () => {
                    console.log('reopen')
                },
                confirmButtonProps: {
                    children: 'Reopen',
                    startIcon: <Icons.Undo />,
                },
                awaitOnConfirm: true,
            })
        },
        title: 'Reopen',
    })
}, basePermissions.update)

export const ResetAction = renderOnPermission(({ children }: ActionProps) => {
    const confirm = useConfirm()

    return children({
        Icon: Icons.RefreshOutlined,
        onClick: () => {
            confirm({
                title: 'Are you sure you want to reset this Invoice?',
                closeOnError: false,
                content:
                    'The invoice will be restored to its original form as it was generated from the work order.',
                onConfirm: () => {
                    console.log('reset')
                },
                confirmButtonProps: {
                    children: 'Reset',
                    startIcon: <Icons.RefreshOutlined />,
                },
                awaitOnConfirm: true,
            })
        },
        title: 'Reset',
    })
}, basePermissions.update)

export const CancelAction = renderOnPermission(({ children }: ActionProps) => {
    const confirm = useConfirm()

    return children({
        Icon: Icons.CancelOutlined,
        onClick: () => {
            confirm({
                title: 'Are you sure you want to cancel this Invoice?',
                closeOnError: false,
                content: (
                    <>
                        <Alert
                            severity="warning"
                            sx={{ mb: '22px' }}
                        >
                            Cancelling a invoice is an irreversible action
                        </Alert>
                        <TextInput
                            source="reason"
                            validate={descriptionValidation}
                            label="Reason for Cancellation"
                            multiline
                            rows={3}
                        />
                    </>
                ),
                onConfirm: async ({ formValues }) => {
                    console.log(formValues)
                },
                confirmButtonProps: {
                    children: 'Confirm',
                },
                awaitOnConfirm: true,
            })
        },
        title: 'Cancel',
    })
}, basePermissions.update)

export const SendInvoiceAction = ({ record, children }: ActionProps & { record: InvoiceModel }) => {
    return (
        <SendInvoiceDrawer invoice={record}>
            {(open) =>
                children({
                    title: 'Send Invoice',
                    Icon: Icons.SendOutlined,
                    onClick: open,
                })
            }
        </SendInvoiceDrawer>
    )
}
