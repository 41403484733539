import 'simplebar-react/dist/simplebar.min.css'
import SimpleBarReact from 'simplebar-react'

import { styled } from 'lib'

const SimpleScrollbar = styled(SimpleBarReact)`
    .simplebar-scrollbar::before {
        background-color: #ababab;
    }
`

export default SimpleScrollbar
