import { type FC } from 'react'

import Icons from 'assets/icons'
import { InfoCardRowBase, MoneyField } from 'components'
import { ManageTaxDrawerToggler } from 'resources/taxes'

import ItemLabel from './ItemLabel'

const TaxRow: FC = () => {
    return (
        <InfoCardRowBase
            label={
                <ManageTaxDrawerToggler>
                    {(open) => (
                        <ItemLabel
                            Icon={Icons.Tax}
                            onClick={open}
                        >
                            Local Tax
                        </ItemLabel>
                    )}
                </ManageTaxDrawerToggler>
            }
        >
            <MoneyField value={200} />
        </InfoCardRowBase>
    )
}

export default TaxRow
