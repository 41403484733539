import { type FC } from 'react'

import { type AxiosError } from 'axios'
import { inject, observer } from 'mobx-react'

import { Show, tabRoutes } from 'components'
import { urls } from 'configs'
import { useFinalErrorHandler, type AuthStore } from 'core'
import { Routes, useNavigate } from 'lib'
import { shopResource } from 'resources/shops'

import { RepairShopInvoices } from '../Invoices'
import { RepairShopMembers } from '../Members'
import { RepairShopOverview } from '../Overview'
import { RSPricingTiers } from '../PricingTiers'

import { RepairShopShowHeader } from './components'

const RepairShopShow: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const { tabs, routes } = tabRoutes([
            {
                value: '',
                label: 'Overview',
                element: <RepairShopOverview />,
            },
            {
                value: 'users',
                label: 'Users',
                element: <RepairShopMembers />,
            },
            auth.companySettings.hasInvoicing
                ? {
                      value: 'pricing-tiers/*',
                      path: 'pricing-tiers/parts',
                      label: 'Pricing Tiers',
                      element: <RSPricingTiers />,
                  }
                : null,
            auth.companySettings.hasInvoicing
                ? {
                      value: 'invoicing',
                      label: 'Invoicing',
                      element: <RepairShopInvoices />,
                  }
                : null,
        ])

        const errorHandler = useFinalErrorHandler()
        const navigate = useNavigate()

        return (
            <Show
                resource={shopResource.resource}
                queryOptions={{
                    onError: (err: AxiosError) => {
                        if (err.status === 404) {
                            navigate(urls.company + urls.repairShops)
                        }
                        errorHandler(err)
                    },
                }}
            >
                <>
                    <RepairShopShowHeader tabs={tabs} />
                    <Routes>{routes}</Routes>
                </>
            </Show>
        )
    }),
)

export default RepairShopShow
