import { FlexReverse, PageContent } from 'ui'

import { DetailsSection, SettingsSection } from './components'

const RepairShopOverview = () => {
    return (
        <PageContent>
            <FlexReverse>
                <DetailsSection />
                <SettingsSection />
            </FlexReverse>
        </PageContent>
    )
}

export default RepairShopOverview
