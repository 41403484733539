import { inject } from 'mobx-react'

import { createResource } from 'core'
import { type AuthStore } from 'core/auth'
import { CK33Levels, type GetCk33InfoArgs, getCk33Info } from 'resources/vmrs'

import { type DefectModel } from './types'

export const deffectResource = createResource({
    name: 'defects',
    resource: 'defects',
})

export const defectCK33Label = (args: GetCk33InfoArgs) =>
    getCk33Info(args, CK33Levels.COMPONENT).label

export const DefectCK33Label = inject('auth')(({ auth }: { auth?: AuthStore }) => (
    <>{defectCK33Label(auth)}</>
))

export const isDefectInProgress = (record: DefectModel): boolean => Boolean(record?.workOrderData)
