import { type PropsWithChildren, createContext, useContext, useRef } from 'react'

import { type GridApi } from '@mui/x-data-grid'
type ListContext = {
    gridApi: React.MutableRefObject<GridApi>
    setGridApi: (api: React.MutableRefObject<GridApi>) => void
}
const listInternalContext = createContext(null)
export const useListInternalContext = () => useContext<ListContext>(listInternalContext)
const ListInternalContextProvider = ({ children }: PropsWithChildren) => {
    const gridApi = useRef<GridApi>(null)

    const setGridApi = (api: React.MutableRefObject<GridApi>) => {
        gridApi.current = api.current
    }
    return (
        <listInternalContext.Provider value={{ gridApi, setGridApi }}>
            {children}
        </listInternalContext.Provider>
    )
}
export default ListInternalContextProvider
