import Icons from 'assets/icons'
import {
    InfoCard,
    InfoCardDetails,
    InfoCardHeader,
    type InfoCardDetailType,
    phoneMaskResolver,
} from 'components'
import { renderOnPermission, basePermissions } from 'core'
import { addressFields } from 'resources/address'
import { companyPermissionName } from 'resources/company'
import { countryFields } from 'resources/country'
import { stateFields } from 'resources/state'
import { type CompanyModel } from 'resourcesBase'
import { IconBox, Typography } from 'ui'

import CompanyInfoEditDrawerToggler from './CompanyInfoEditDrawerToggler'

const detailsDetails: InfoCardDetailType<CompanyModel>['details'] = [
    {
        label: 'Company Name',
        source: 'name',
    },
    {
        label: 'DOT Number',
        source: 'dotNumber',
    },
    {
        label: 'Phone Number',
        source: ({ phone }) => phoneMaskResolver(phone),
    },
    {
        label: 'Email',
        source: 'email',
    },
    addressFields.address1.dataCardRow({}),
    addressFields.address2.dataCardRow({}),
    {
        label: stateFields.self.label,
        source: (record) => stateFields.self.value(record.stateData),
    },
    addressFields.city.dataCardRow({}),
    addressFields.zipCode.dataCardRow({}),
    {
        label: countryFields.self.label,
        source: (record) => countryFields.self.value(record.countryData),
    },
]

const EditAction = renderOnPermission(
    () => {
        return (
            <CompanyInfoEditDrawerToggler>
                {({ onClick }) => (
                    <IconBox
                        onClick={onClick}
                        title="Edit"
                    >
                        <Icons.Edit />
                    </IconBox>
                )}
            </CompanyInfoEditDrawerToggler>
        )
    },
    basePermissions.update,
    companyPermissionName,
)

const CompanyInfoDetailsCard = () => {
    return (
        <InfoCard>
            <InfoCardHeader
                title={<Typography variant="subtitle2">Company Information </Typography>}
                action={<EditAction />}
            />
            <InfoCardDetails details={detailsDetails} />
        </InfoCard>
    )
}
export default CompanyInfoDetailsCard
