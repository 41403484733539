import { type Identifier } from 'appTypes'
import { type ResourceType } from 'core'

import { getPricingTierResource, PricingTierTypes } from '../utils'

export const getPtFeeResource = (id: Identifier): ResourceType => ({
    resource: getPricingTierResource(id, PricingTierTypes.FEES),
    name: 'pricing-tiers-fees',
})

export const ptFeePreferencesResource: ResourceType = {
    resource: 'pricing-tiers-fees',
    name: 'pricing-tiers-fees',
}
