import { forwardRef, useState } from 'react'

import { type GridColDef } from '@mui/x-data-grid'

import Icons from 'assets/icons'
import { styled, globalClassNames, withColor } from 'lib'
import {
    Switch,
    switchClasses,
    FormControlLabel,
    Box,
    Divider,
    Paper,
    Stack,
    Typography,
    Button,
} from 'ui'

import { useListInternalContext } from '../list/ListInternalContextProvider'

import { type Column, type DatagridColumnsProps } from './Datagrid'

const PanelWrapper = styled(Paper)`
    display: flex;
    max-height: 450px;
    max-width: 300px;
`
const PanelContent = styled(Stack)`
    overflow: auto;
    flex: 1 1 0%;
    max-height: 400px;
`
const GridColumnsPanelRoot = styled('div', {
    name: 'MuiDataGrid',
    slot: 'ColumnsPanel',
    overridesResolver: (props, styles) => styles.columnsPanel,
})(() => ({
    padding: '8px 16px',
}))
const GridColumnsPanelRowRoot = styled('div', {
    name: 'MuiDataGrid',
    slot: 'ColumnsPanelRow',
    overridesResolver: (props, styles) => styles.columnsPanelRow,
})(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1px 8px 1px 7px',
    [`& .${switchClasses.root}`]: {
        marginRight: theme.spacing(0.5),
    },
}))

interface GridColumnsPanelProps
    extends Pick<DatagridColumnsProps, 'resetColumns' | 'constantColumns'> {}

const GridColumnsPanel = forwardRef<HTMLDivElement, GridColumnsPanelProps>(
    ({ resetColumns, constantColumns, ...rest }, ref) => {
        const { gridApi: apiRef } = useListInternalContext()
        let columns = apiRef.current.getAllColumns()
        if (constantColumns) {
            columns = columns.filter(
                (col) => !constantColumns[col.field] && col.field !== '__check__',
            )
        }

        const [, rerender] = useState(false)
        const toggleColumn = (event) => {
            const name = event.target.name
            const columnVisibilityModel = apiRef.current.state.columns.columnVisibilityModel

            apiRef.current.setColumnVisibility(name, columnVisibilityModel[name] === false)
            rerender((p) => !p)
        }

        const getHiddenColumn = (col: GridColDef, isVisible: boolean) => {
            if (col.hideable !== false) {
                return {
                    field: col.field,
                    hide: !isVisible,
                }
            }

            return col
        }

        const toggleAllColumns = (isVisible: boolean) => {
            apiRef.current.updateColumns(columns.map((col) => getHiddenColumn(col, isVisible)))
            rerender((p) => !p)
        }

        const reset = () => {
            if (typeof resetColumns === 'undefined') {
                apiRef.current.setColumnVisibilityModel({})
                rerender((p) => !p)
                return
            }

            apiRef.current.updateColumns(
                columns.map((col) => getHiddenColumn(col, resetColumns[col.field] !== false)),
            )
            rerender((p) => !p)
        }
        if (!apiRef.current) {
            return null
        }
        return (
            <PanelWrapper
                {...rest}
                ref={ref}
            >
                <Stack
                    flex="1 1 0%"
                    overflow="hidden"
                >
                    <Box
                        sx={{
                            p: '14px 12px 0',
                        }}
                    >
                        <Typography
                            variant="subtitle1"
                            color={withColor('text.secondary')}
                            mb="12px"
                        >
                            Current Arrangement
                        </Typography>
                        <Divider />
                    </Box>
                    <PanelContent>
                        <GridColumnsPanelRoot>
                            {columns.map((column: Column) =>
                                column.hidden || column.field === 'actions' ? null : (
                                    <GridColumnsPanelRowRoot key={column.field}>
                                        <FormControlLabel
                                            sx={{
                                                overflow: 'hidden',
                                                marginRight: 0,
                                            }}
                                            componentsProps={{
                                                typography: {
                                                    overflow: 'hidden',
                                                },
                                            }}
                                            label={
                                                <div className={globalClassNames.ellipsis}>
                                                    {column.headerName || column.field}
                                                </div>
                                            }
                                            control={
                                                <Switch
                                                    disabled={column.hideable === false}
                                                    checked={
                                                        apiRef.current.state.columns
                                                            .columnVisibilityModel[column.field] !==
                                                        false
                                                    }
                                                    onChange={toggleColumn}
                                                    name={column.field}
                                                    size="small"
                                                />
                                            }
                                        />
                                    </GridColumnsPanelRowRoot>
                                ),
                            )}
                        </GridColumnsPanelRoot>
                    </PanelContent>
                    <Box
                        sx={{
                            p: '6px 16px 18px',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <Button
                            size="small"
                            onClick={() => toggleAllColumns(true)}
                        >
                            Show all
                        </Button>

                        <Button
                            sx={{ ml: '8px' }}
                            size="small"
                            onClick={() => toggleAllColumns(false)}
                        >
                            Hide all
                        </Button>

                        <Button
                            sx={{ ml: 'auto' }}
                            size="small"
                            startIcon={<Icons.RefreshOutlined />}
                            onClick={reset}
                        >
                            Reset
                        </Button>
                    </Box>
                </Stack>
            </PanelWrapper>
        )
    },
)

export default GridColumnsPanel
