import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import { ListTotalBadge, ViewHeader } from 'components'
import { basePermissions, renderOnPermission } from 'core/permissions'
import { type ShopModel } from 'resourcesBase'
import { FixedOnMobile, Button } from 'ui'

import AssignMemberDrawerToggler from './AssignMemberDrawerToggler'

const ListHeader = renderOnPermission(() => {
    const record = useRecordContext<ShopModel>()
    return (
        <ViewHeader
            title="Users"
            pb="20px"
        >
            <ViewHeader.Content>
                <ListTotalBadge />
            </ViewHeader.Content>
            <ViewHeader.Content placement="right">
                <AssignMemberDrawerToggler record={record}>
                    {({ onClick }) => (
                        <FixedOnMobile>
                            <Button
                                onClick={onClick}
                                variant="contained"
                                startIcon={<Icons.PersonAddAltOutlined />}
                            >
                                Assign User
                            </Button>
                        </FixedOnMobile>
                    )}
                </AssignMemberDrawerToggler>
            </ViewHeader.Content>
        </ViewHeader>
    )
}, basePermissions.update)

export default ListHeader
