import { type FC, type ReactNode } from 'react'

import { inject, observer } from 'mobx-react'

import {
    CardCollapsibleContent,
    type CardListConfig,
    type DatagridColumnsProps,
    type FilterConfig,
    getPhotos,
    List,
    ListAvatar,
    ListBase,
    type ListBaseProps,
    type ListBulkActions,
    ListFilterDateRangeValueInput,
    ListFilterValueInput,
    type ListProps,
    type ListSortContentProps,
    PhotoButton,
    PhotosCollapsibleContent,
    PhotosCollapsibleIconHolder,
} from 'components'
import {
    deleteManyFromListAction,
    deleteOneAction,
    editRedirectInListAction,
    type AuthStore,
    multiselectAction,
    type ResourceType,
    type SortPayload,
} from 'core'
import { customerFields } from 'resources/customers'
import {
    issueFields,
    type IssueModel,
    type IssuePriorityKeys,
    issuesResource,
    type IssueStatusKeys,
} from 'resources/issues'
import { NotesCollapsibleContent, NotesCollapsibleIconHolder } from 'resources/notes'
import { unitFields } from 'resources/units'
import { ck14Fields, ck18Fields, ck33Fields, getCk33Info } from 'resources/vmrs'
import { woFields } from 'resources/workOrders'
import { Typography, AutoGrid } from 'ui'

import { deleteConfirmConfig } from '../utils/config'

import IssueDrawerToggler, { type IssueDrawerTogglerProps } from './IssueDrawerToggler'

const defaultSort: SortPayload<IssueModel> = {
    field: issueFields.reportedDate.source,
    order: 'DESC',
}

const bulkActions: ListBulkActions = ({ children }) => [
    deleteManyFromListAction({
        children,
        confirmConfig: {
            title: 'Are you sure you want to delete the selected Issues?',
        },
    }),
]

interface IssuesListProps {
    dataConfig?: {
        unit?: false
    }
    create?: IssueCreate
    filter?: boolean
    preferencesResource?: ResourceType
    disableExport?: boolean
    listFTUProps?: ListProps['listFTUProps']
    disableActions?: boolean
    disableLink?: boolean
}

export interface IssueCreate
    extends Pick<IssueDrawerTogglerProps, 'defaultValues' | 'isInputDisabled'> {}

const IssuesList: FC<IssuesListProps> = inject('auth')(
    observer(
        ({
            auth,
            dataConfig,
            filter = true,
            preferencesResource,
            disableExport,
            listFTUProps,
            disableActions = false,
            disableLink = false,
            create,
        }: { auth: AuthStore } & IssuesListProps) => {
            const hideUnit = dataConfig?.unit === false

            const columns: DatagridColumnsProps<
                IssueModel & { domicile: string; customer: string }
            > = {
                constantColumns: {
                    name: true,
                },
                resetColumns: {
                    domicile: false,
                },
                columns: [
                    {
                        field: issueFields.avatar.source,
                        headerName: issueFields.avatar.label,
                        maxWidth: 72,
                        renderCell: (cell) => (
                            <ListAvatar
                                id={cell.id}
                                imageSrc={cell.value}
                                disableLink={disableLink}
                                defaultImage={<issueFields.avatar.Icon />}
                            />
                        ),
                    },
                    {
                        field: issueFields.name.source,
                        headerName: issueFields.name.label,
                        renderCell: ({ row }) => issueFields.name.value(row, !disableLink),
                    },
                    ...(hideUnit
                        ? []
                        : [
                              {
                                  field: unitFields.self.source,
                                  headerName: issueFields.unit.label,
                                  renderCell: ({ row }) => unitFields.self.linkValue(row.unitData),
                              },
                          ]),
                    {
                        field: issueFields.status.source,
                        headerName: issueFields.status.label,
                        renderCell: ({ row }) => issueFields.status.value(row),
                    },
                    unitFields.domicile.tableColumn({
                        label: 'Unit Domicile',
                        auth,
                        dataToValue: (row: IssueModel) => row.unitData?.domicileData?.name,
                    }),
                    {
                        field: issueFields.priority.source,
                        headerName: issueFields.priority.label,
                        renderCell: ({ row }) => issueFields.priority.value(row),
                    },
                    {
                        field: issueFields.reportedDate.source,
                        headerName: issueFields.reportedDate.label,
                        renderCell: ({ row }) => issueFields.reportedDate.value(row),
                    },
                    ck33Fields.self.gridColumn({
                        dataToRecord: (data: IssueModel) => data.componentData,
                        maxLevel: issueFields.component.maxLevel,
                    }),
                    {
                        field: ck14Fields.self.source,
                        headerName: ck14Fields.self.label,
                        renderCell: ({ row }) => ck14Fields.self.value(row),
                    },
                    {
                        field: ck18Fields.self.source,
                        headerName: ck18Fields.self.label,
                        renderCell: ({ row }) => ck18Fields.self.value(row),
                    },
                    {
                        field: issueFields.description.source,
                        headerName: issueFields.description.label,
                        renderCell: ({ row }) => issueFields.description.value(row),
                    },
                    {
                        field: issueFields.photos.source,
                        headerName: issueFields.photos.label,
                        renderCell: ({ row }) => <PhotoButton files={getPhotos(row)} />,
                        toExport: {
                            separate: [
                                {
                                    field: 'photosCount' as any,
                                    headerName: issueFields.photos.label,
                                },
                            ],
                        },
                    },
                    {
                        field: woFields.self.source,
                        headerName: woFields.self.label,
                        renderCell: ({ row }) => woFields.self.linkValue(row.workOrderData),
                    },
                    ...(auth.companySettings.hasCustomers
                        ? [
                              {
                                  field: customerFields.self.source,
                                  headerName: customerFields.self.label,
                                  renderCell: ({ row }) =>
                                      customerFields.self.value(row.unitData.customerData),
                              },
                          ]
                        : []),
                ],
                actions: disableActions
                    ? null
                    : ({ row }, { children }) => [
                          editRedirectInListAction({
                              id: row.id,
                              children,
                          }),
                          deleteOneAction({
                              children: (params) =>
                                  children({
                                      ...params,
                                      titleOnDisabled: 'Closed Issues cannot be deleted',
                                  }),
                              id: row.id,
                              confirmConfig: deleteConfirmConfig(row),
                              disabled: row.status === 'CLOSED',
                          }),
                      ],
                checkboxSelection: !disableActions,
            }

            const cardsCfg: CardListConfig<IssueModel & { customer: string }> = {
                titleSource: (record) => (
                    <Typography
                        color="primary.dark"
                        fontSize="20px"
                        fontWeight="500"
                        height="24px"
                    >
                        {issueFields.name.value(record, !disableLink)}
                    </Typography>
                ),
                disableTitleLink: true,
                subTitleSource: (record) => (
                    <AutoGrid.Row>
                        <AutoGrid.Column>{issueFields.status.value(record)}</AutoGrid.Column>
                        <AutoGrid.Column>
                            {unitFields.self.linkWithIconValue(record.unitData)}
                        </AutoGrid.Column>
                    </AutoGrid.Row>
                ),
                defaultImage: <issueFields.avatar.Icon />,
                details: [
                    {
                        source: issueFields.priority.source,
                        label: issueFields.priority.label,
                        render: (v, record) => issueFields.priority.value(record),
                    },
                    {
                        source: issueFields.reportedDate.source,
                        label: issueFields.reportedDate.label,
                        render: (v, record) => issueFields.reportedDate.value(record),
                    },
                    ck33Fields.self.dataCardRow({
                        dataToRecord: (data: IssueModel) => data.componentData,
                        maxLevel: issueFields.component.maxLevel,
                    }),
                    {
                        source: ck14Fields.self.source,
                        label: ck14Fields.self.label,
                        render: (v, record) => ck14Fields.self.value(record),
                    },
                    {
                        source: ck18Fields.self.source,
                        label: ck18Fields.self.label,
                        render: (v, record) => ck18Fields.self.value(record),
                    },
                    {
                        source: issueFields.description.source,
                        label: issueFields.description.label,
                        render: (v, record) => issueFields.description.value(record),
                    },
                    {
                        source: woFields.self.source,
                        label: woFields.self.label,
                        render: (v, record) => woFields.self.linkValue(record.workOrderData),
                    },
                    ...(auth.companySettings.hasCustomers
                        ? [
                              {
                                  source: customerFields.self.source,
                                  label: customerFields.self.label,
                                  render: (v, record) =>
                                      customerFields.self.value(record.unitData.customerData),
                              },
                          ]
                        : []),
                ],
                collapsibleContent: (record) => (
                    <CardCollapsibleContent
                        content={[
                            {
                                iconHolder: (
                                    <NotesCollapsibleIconHolder
                                        notes={record.notes}
                                        key="notesIcon"
                                    />
                                ),
                                component: (
                                    <NotesCollapsibleContent
                                        notes={record.notes}
                                        key="notesContent"
                                    />
                                ),
                            },
                            {
                                iconHolder: (
                                    <PhotosCollapsibleIconHolder
                                        record={record}
                                        key="photosIcon"
                                    />
                                ),
                                component: (
                                    <PhotosCollapsibleContent
                                        record={record}
                                        key="photosContent"
                                    />
                                ),
                            },
                        ]}
                    />
                ),
                actions: disableActions
                    ? undefined
                    : (record, { children }) => [
                          editRedirectInListAction({
                              children,
                              id: record.id,
                          }),
                          multiselectAction({
                              children,
                              id: record.id,
                          }),
                          deleteOneAction({
                              children: (params) =>
                                  children({
                                      ...params,
                                      titleOnDisabled: 'Closed Issues cannot be deleted',
                                  }),
                              id: record.id,
                              confirmConfig: deleteConfirmConfig(record),
                              disabled: record.status === 'CLOSED',
                          }),
                      ],
            }

            const sortCfg: ListSortContentProps<IssueModel & { customer: string }> = {
                sortBy: [
                    {
                        id: issueFields.name.source,
                        label: issueFields.name.label,
                    },
                    ...(hideUnit
                        ? []
                        : [
                              {
                                  id: unitFields.self.source,
                                  label: issueFields.unit.label,
                              },
                          ]),
                    {
                        id: issueFields.status.source,
                        label: issueFields.status.label,
                    },
                    {
                        id: issueFields.priority.source,
                        label: issueFields.priority.label,
                    },
                    {
                        id: issueFields.reportedDate.source,
                        label: issueFields.reportedDate.label,
                    },
                    ck33Fields.self.sort({
                        maxLevel: issueFields.component.maxLevel,
                    }),
                    {
                        id: ck14Fields.self.source,
                        label: ck14Fields.self.label,
                    },
                    {
                        id: ck18Fields.self.source,
                        label: ck18Fields.self.label,
                    },
                    {
                        id: issueFields.description.source,
                        label: issueFields.description.label,
                    },
                    {
                        id: issueFields.photos.countSource,
                        label: issueFields.photos.label,
                    },
                    {
                        id: woFields.self.source,
                        label: woFields.self.label,
                    },
                    ...(auth.companySettings.hasCustomers
                        ? [
                              {
                                  id: customerFields.self.source,
                                  label: customerFields.self.label,
                              },
                          ]
                        : []),
                ],
            }

            const filtersCfg: FilterConfig<IssueModel & { domicile: string; customer: string }> =
                filter
                    ? {
                          filters: [
                              ...(hideUnit
                                  ? []
                                  : [
                                        {
                                            id: unitFields.self.source,
                                            label: issueFields.unit.label,
                                        },
                                    ]),
                              unitFields.domicile.filter({
                                  label: 'Unit Domicile',
                                  auth,
                              }),
                              {
                                  id: issueFields.status.source,
                                  label: issueFields.status.label,
                                  renderComponent: (props) => (
                                      <ListFilterValueInput
                                          {...props}
                                          inputText={(option) => option.id}
                                          makeItemLabel={(record) =>
                                              issueFields.status.value({
                                                  status: record.id as IssueStatusKeys,
                                              })
                                          }
                                      />
                                  ),
                              },
                              {
                                  id: issueFields.priority.source,
                                  label: issueFields.priority.label,
                                  renderComponent: (props) => (
                                      <ListFilterValueInput
                                          {...props}
                                          inputText={(option) => option.id}
                                          makeItemLabel={(record) => {
                                              return issueFields.priority.value({
                                                  priority: record.id as IssuePriorityKeys,
                                              })
                                          }}
                                      />
                                  ),
                              },
                              {
                                  id: issueFields.reportedDate.source,
                                  label: issueFields.reportedDate.label,
                                  filterType: 'range',
                                  renderComponent: (props) => (
                                      <ListFilterDateRangeValueInput {...props} />
                                  ),
                              },
                              ck33Fields.self.filter<IssueModel>({
                                  id: 'component',
                                  label: getCk33Info(auth, issueFields.component.maxLevel).label,
                              }),
                              {
                                  id: ck14Fields.self.source,
                                  label: ck14Fields.self.label,
                              },
                              {
                                  id: ck18Fields.self.source,
                                  label: ck18Fields.self.label,
                              },
                              ...(auth.companySettings.hasCustomers
                                  ? [
                                        {
                                            id: customerFields.self.source,
                                            label: customerFields.self.label,
                                        },
                                    ]
                                  : []),
                          ],
                      }
                    : undefined

            return (
                <List
                    preferencesResource={preferencesResource}
                    bulkActions={bulkActions}
                    sortCfg={sortCfg}
                    columnsCfg={columns}
                    cardsCfg={cardsCfg}
                    filtersCfg={filtersCfg}
                    listFTUProps={{
                        ...listFTUProps,
                        linkText: disableActions ? null : (
                            <IssueDrawerToggler {...create}>
                                {(action) => (
                                    <Typography
                                        variant="body1"
                                        onClick={action}
                                        color={(theme) => theme.palette.primary.main}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        Create Issue
                                    </Typography>
                                )}
                            </IssueDrawerToggler>
                        ),
                        linkAction: (e) => {
                            e.preventDefault()
                        },
                        secondaryTitle: disableActions ? null : 'Would you like to create one?',
                        action: disableActions ? null : undefined,
                    }}
                    disableExportButton={disableExport}
                />
            )
        },
    ),
)

export default IssuesList

interface IssuesListBaseProps extends Pick<ListBaseProps, 'filter' | 'preferencesResource'> {
    children: ReactNode
}

export const IssuesListBase: FC<IssuesListBaseProps> = ({
    children,
    filter,
    preferencesResource,
}: IssuesListBaseProps) => {
    return (
        <ListBase
            sort={defaultSort}
            resource={issuesResource.resource}
            filter={filter}
            preferencesResource={preferencesResource}
        >
            {children}
        </ListBase>
    )
}
