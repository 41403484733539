import { type FC, useEffect, useRef, useState } from 'react'

import { useFormContext } from 'react-hook-form'

import UtilityDrawerBlock from '../UtilityDrawerBlock'

import {
    UtilityDrawerStepsContext,
    useUtilityDrawerStepsContext,
} from './UtilityDrawerStepsContext'
import UtilityDrawerStepsForm from './UtilityDrawerStepsForm'
import {
    type StepConfig,
    type StepConfigParams,
    type StepsContextProviderProps,
    type StepsData,
} from './types'

const findRedirectStep = (
    steps: StepConfig[],
    currentStep: number,
    stepInfo: StepConfigParams,
    next?: boolean,
) => {
    const nextStep = currentStep + (next ? 1 : -1)
    const skip = steps[nextStep].skip

    if (!skip || !skip(stepInfo)) {
        return nextStep
    }

    return findRedirectStep(steps, nextStep, stepInfo, next)
}

export const UtilityDrawerStepsContextProvider: FC<StepsContextProviderProps> = ({
    children,
    steps,
    parser,
    extra,
    submitEndpoint,
    defaultValues,
    initialStep,
    onSuccess,
    successMessage,
    onFormError,
    onFieldError,
}) => {
    const [step, setStep] = useState(initialStep || 0)
    const stepsData = useRef<StepsData>({
        formValues: defaultValues || {},
    })

    const currentStepConfig = steps.find((_, index) => index === step)

    const next = (data) => {
        if (data) {
            stepsData.current[step] ||= {}
            stepsData.current[step].result = data
        }
        setStep(findRedirectStep(steps, step, { stepsData: stepsData.current, extra }, true))
    }

    const prev = () => {
        setStep(findRedirectStep(steps, step, { stepsData: stepsData.current, extra }))
    }

    return (
        <UtilityDrawerStepsContext.Provider
            value={{
                step,
                steps,
                stepsData,
                currentStepConfig,
                next,
                prev,
                parser,
                extra,
                submitEndpoint,
                isLastStep: step === steps.length - 1,
                onSuccess,
                successMessage,
                onFormError,
                onFieldError,
            }}
        >
            <UtilityDrawerStepsForm defaultValues={defaultValues}>
                {children}
                <FormTrigger />
                <BlockClose />
            </UtilityDrawerStepsForm>
        </UtilityDrawerStepsContext.Provider>
    )
}

const BlockClose = () => {
    const step = useUtilityDrawerStepsContext().step
    // if step changes, activate the blocker
    const ref = useRef<number | true>(step)

    if (ref.current !== true && ref.current !== step) {
        ref.current = true
    }

    return <UtilityDrawerBlock isBlocked={ref.current === true} />
}

// trigger something so the submit button can be enabled on step change
const FormTrigger = () => {
    const { trigger, register } = useFormContext()
    const { step } = useUtilityDrawerStepsContext()

    useEffect(() => {
        register('form-reset')
        trigger('form-reset')
    }, [step])

    return null
}
