import { type FC } from 'react'

import Icons from 'assets/icons'
import { getContrastColor } from 'lib'
import { type ShopModel } from 'resourcesBase'
import { type TypographyProps, BoxContainer, SvgIcon, Typography } from 'ui'
import { getInitials } from 'utils'

const source = 'logo' as const satisfies keyof ShopModel

const avatarColorSource = 'avatarColor' as const satisfies keyof ShopModel

interface Props extends Pick<TypographyProps, 'variant'> {
    record: ShopModel
    fontWeight?: number
}

const DefaultShopAvatar: FC<Props> = ({ record, fontWeight, variant }) => {
    if (!record) {
        return <Icons.Shop />
    }
    const initials = getInitials(record.name)

    if (!initials) {
        return (
            <SvgIcon
                sx={{
                    color: `${getContrastColor(record.avatarColor)} !important`,
                }}
                component={Icons.LocationOn}
            />
        )
    }
    return (
        <Typography
            component={BoxContainer}
            justifyContent="center"
            alignContent="center"
            color={getContrastColor(record.avatarColor)}
            lineHeight="100%"
            fontWeight={fontWeight || 500}
            variant={variant}
        >
            {initials}
        </Typography>
    )
}

const avatar = {
    source,
    avatarColorSource,
    label: 'Avatar',
    avatarColorLabel: 'Avatar Color',
    Icon: DefaultShopAvatar,
}

export default avatar
