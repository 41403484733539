import { type FC } from 'react'

import { useRecordContext } from 'react-admin'

import { Header, InnerHeader, ShowHeaderSkeleton, type TabType } from 'components'
import { urls } from 'configs'
import { shopFields } from 'resources/shops'
import { type ShopModel } from 'resourcesBase'

const RepairShopShowHeader: FC<{ tabs: TabType[] }> = ({ tabs }) => {
    const record = useRecordContext<ShopModel>()

    if (!record) {
        return <ShowHeaderSkeleton />
    }

    return (
        <InnerHeader
            tabs={tabs}
            tabWidth="186px"
            backTo={urls.company + urls.repairShops}
        >
            <Header.Info
                avatar={
                    <Header.Avatar
                        imageSrc={record.logo}
                        defaultImage={<shopFields.avatar.Icon record={record} />}
                        color={record.avatarColor}
                        avatarOpacity={1}
                    />
                }
            >
                <Header.Content>
                    <Header.Title>{shopFields.name.value(record)}</Header.Title>
                </Header.Content>
            </Header.Info>
        </InnerHeader>
    )
}

export default RepairShopShowHeader
