import { ListTotalBadge, ViewHeader } from 'components'

import { MemberInviteButton } from '../../Invite'

const MembersListHeader = () => {
    return (
        <ViewHeader
            title="Users"
            pb="20px"
        >
            <ViewHeader.Content>
                <ListTotalBadge />
            </ViewHeader.Content>
            <ViewHeader.Content placement="right">
                <MemberInviteButton />
            </ViewHeader.Content>
        </ViewHeader>
    )
}

export default MembersListHeader
