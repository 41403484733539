import { type FC, type ReactNode } from 'react'

import { UtilityDrawerProvider } from 'components'
import { FormInfo } from 'context'
import {
    InputStateContextProvider,
    CompanyStateUpdate,
    SyncProvider,
    ResourceDataContextProvider,
} from 'core'
import { ResetCache } from 'core/store'
import { GoogleMapProvider } from 'lib'
import { TelematicsContextProvider } from 'resources/telematics'

import { SidebarProvider } from '../Sidebar'

const GlobalProviders: FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <SidebarProvider>
            <CompanyStateUpdate />
            <ResetCache />
            <SyncProvider>
                <FormInfo>
                    <ResourceDataContextProvider>
                        <UtilityDrawerProvider>
                            <TelematicsContextProvider>
                                <InputStateContextProvider value={null}>
                                    <GoogleMapProvider>{children}</GoogleMapProvider>
                                </InputStateContextProvider>
                            </TelematicsContextProvider>
                        </UtilityDrawerProvider>
                    </ResourceDataContextProvider>
                </FormInfo>
            </SyncProvider>
        </SidebarProvider>
    )
}

export default GlobalProviders
