import { type FC, type ReactElement } from 'react'

import { useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'

import ManageTaxForm from './ManageTaxForm'

interface Props {
    children: (params: () => void) => ReactElement
}

const ManageTaxDrawerToggler: FC<Props> = ({ children }) => {
    const open = useOpenUtilityDrawer()

    const readOnly = false

    return children(() => {
        open({
            drawerArgs: {
                title: readOnly ? 'View Tax' : 'Edit Tax',
                renderWrapper: (params) => <UtilityDrawerEditor {...params} />,
                renderContent: () => <ManageTaxForm isInputDisabled={() => readOnly} />,
                renderTopRight: readOnly ? null : (render) => render(),
                renderBottomRight: readOnly ? null : (render) => render(),
            },
        })
    })
}

export default ManageTaxDrawerToggler
