import { styled } from 'lib'

const Main = styled('main')`
    margin-left: auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    ${({ theme }) => theme.breakpoints.up('sm')} {
        width: calc(100% - ${({ theme }) => theme.props.drawerWidth}px);
    }
`

export default Main
