import Icons from 'assets/icons'
import {
    CollapsibleContent,
    InfoCardRowBase,
    InfoCardRows,
    InfoCardTitle,
    InfoCardTitleContainer,
    InfoCardTitleIcon,
    WithCollapsibleController,
} from 'components'
import { ItemsTotal } from 'resources/jobs'
import { Anchor, Divider } from 'ui'

const ItemsContent = () => {
    return (
        <>
            <InfoCardRows>
                <InfoCardRowBase
                    label={
                        <InfoCardTitleContainer>
                            <InfoCardTitleIcon Icon={Icons.ListAltOutlined} />
                            <InfoCardTitle>1 Line Items</InfoCardTitle>
                        </InfoCardTitleContainer>
                    }
                >
                    <WithCollapsibleController>
                        {({ toggleExpand }) => (
                            <Anchor
                                variant="caption"
                                onClick={toggleExpand}
                            >
                                SEE LESS
                            </Anchor>
                        )}
                    </WithCollapsibleController>
                </InfoCardRowBase>
            </InfoCardRows>
            <CollapsibleContent>
                <Divider />

                {/* <InfoCardRows>
                    <ItemRow />
                    <ItemRow />
                    <ItemRow />
                </InfoCardRows> */}
            </CollapsibleContent>
            <ItemsTotal total={100} />
        </>
    )
}

export default ItemsContent
