import { type PaymentMethod } from './types'

export const paymentMethods: PaymentMethod[] = [
    {
        id: 'CASH',
        name: 'Cash',
    },
    {
        id: 'ON_ACCOUNT',
        name: 'On Account',
    },
    {
        id: 'CARD',
        name: 'Card',
    },
    {
        id: 'CHECK',
        name: 'Check',
    },
]
