import { type Identifier } from 'appTypes'
import { type ResourceType } from 'core'

import { getPricingTierResource, PricingTierTypes } from '../utils'

export const getPtTaxResource = (id: Identifier): ResourceType => ({
    resource: getPricingTierResource(id, PricingTierTypes.TAXES),
    name: 'pricing-tiers-tax',
})

export const ptTaxPreferencesResource: ResourceType = {
    resource: 'pricing-tiers-tax',
    name: 'pricing-tiers-tax',
}
