import { type FC } from 'react'

import {
    CardCollapsibleContent,
    CollapsibleInfoCard,
    EditButton,
    InfoCardHeader,
    PhotosCollapsibleContent,
    PhotosCollapsibleIconHolder,
} from 'components'
import { globalClassNames } from 'lib'
import { NotesCollapsibleContent, NotesCollapsibleIconHolder } from 'resources/notes'
import { Anchor, Divider, Typography } from 'ui'

import { ItemsContent } from '../../components'

const WoItemsCard: FC = () => {
    return (
        <CollapsibleInfoCard
            defaultOpen
            sameParent
            disableActiveState
        >
            <InfoCardHeader
                noDivider
                title={
                    <div className={globalClassNames.ellipsis}>
                        <Anchor variant="subtitle2">Oil Change</Anchor>
                        <br />
                        <Typography variant="helperText">
                            045-011 Filter Assembly - Oil 045-011 Filter Assembly - Oil 045-011
                            Filter Assembly - Oil
                        </Typography>
                        <br />
                        <Typography variant="helperText">Preventive Maintenance</Typography>
                    </div>
                }
                action={<EditButton />}
            />
            <ItemsContent />
            <Divider sx={{ mb: '10px' }} />
            <CardCollapsibleContent
                content={[
                    {
                        iconHolder: (
                            <NotesCollapsibleIconHolder
                                notes={[]}
                                key="notesIcon"
                            />
                        ),
                        component: (
                            <NotesCollapsibleContent
                                notes={[]}
                                key="notesContent"
                            />
                        ),
                    },
                    {
                        iconHolder: (
                            <PhotosCollapsibleIconHolder
                                record={{ id: 1 }}
                                key="photosIcon"
                            />
                        ),
                        component: (
                            <PhotosCollapsibleContent
                                record={{ id: 1 }}
                                key="photosContent"
                            />
                        ),
                    },
                ]}
            />
        </CollapsibleInfoCard>
    )
}

export default WoItemsCard
