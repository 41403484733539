import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { type ListBulkActions } from 'components'
import {
    type ActionChildren,
    deleteManyFromListAction,
    deleteOneAction,
    dispatchAction,
} from 'core/actions'
import { purchaseHistoryResource } from 'resources/purchaseHistory'
import { type VendorModel } from 'resources/vendors'

import PurchaseHistoryDrawerToggler from './PurchaseHistoryDrawerToggler'

const dispatchPurchaseHistoryDelete = () => {
    dispatchAction(purchaseHistoryResource.name, {
        name: 'delete',
    })
}
export const actions = (id: Identifier, children: ActionChildren) => [
    <PurchaseHistoryDrawerToggler
        key="edit"
        id={id}
    >
        {(open) =>
            children({
                title: 'Edit',
                Icon: Icons.Edit,
                onClick: open,
            })
        }
    </PurchaseHistoryDrawerToggler>,
    deleteOneAction({
        children,
        id,
        onSuccess: dispatchPurchaseHistoryDelete,
        confirmConfig: {
            title: 'Are you sure you want to delete this record?',
        },
    }),
]

export const bulkActions: ListBulkActions = ({ children }) => [
    deleteManyFromListAction({
        children,
        onSuccess: dispatchPurchaseHistoryDelete,
        confirmConfig: {
            title: 'Are you sure you want to delete these records?',
        },
    }),
]

export interface PurchaseHistoryModel {
    id: Identifier
    vendor: Identifier
    vendorData: VendorModel
    price: number
    purchaseDate: string
}
