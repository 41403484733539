import { inject, observer } from 'mobx-react'

import { urls } from 'configs'
import { type AuthStore } from 'core'
import { Link } from 'lib'
import { BoxContainer, Typography, Button } from 'ui'

const ProPlan = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const billing = auth.billing

        return (
            <>
                <Typography
                    variant="h4"
                    mb="4px"
                >
                    {billing.workOrdersForPeriodCount}
                </Typography>
                <BoxContainer sx={{ gap: '3px' }}>
                    <Typography> Work orders </Typography>•
                    <Typography
                        variant="body2"
                        color="text.disabled"
                    >
                        This Month
                    </Typography>
                </BoxContainer>
                <Button
                    sx={{ mt: '20px', height: '42px' }}
                    fullWidth
                    variant="text"
                    component={Link}
                    to={urls.changePlan}
                >
                    Change Plan
                </Button>
            </>
        )
    }),
)

export default ProPlan
