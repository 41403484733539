import { type FC } from 'react'

import { ActionsMenu } from 'core/actions'
import { Stack, Chip } from 'ui'

import { type TagModel } from '../types'

import { TagField } from './TagField'

interface Props {
    tags: TagModel[]
    smallVariant?: boolean
}

export const TagsField: FC<Props> = ({ tags, smallVariant }) => {
    const tagsCount = tags?.length

    if (!tagsCount) {
        // TODO: It should not return '-'
        return <>-</>
    }

    return (
        <ActionsMenu
            renderToggler={(open) => (
                <Chip
                    component="span"
                    onClick={open}
                    label={`${tagsCount} Tag${tagsCount === 1 ? '' : 's'}`}
                    variant="filled"
                    size={smallVariant ? 'extraSmall' : 'small'}
                />
            )}
            actions={() => [
                <Stack
                    spacing="6px"
                    px="16px"
                    key="tags"
                    alignItems="flex-start"
                >
                    {tags.map((tag) => (
                        <TagField
                            tag={tag}
                            key={tag.id}
                        />
                    ))}
                </Stack>,
            ]}
        />
    )
}
