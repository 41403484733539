import { useRecordContext } from 'react-admin'

import { InfoCard, InfoCardHeader } from 'components'
import { type WorkOrderModel } from 'resources/workOrders'
import { Typography } from 'ui'

const ReasonForCancelation = () => {
    const workOrder = useRecordContext<WorkOrderModel>() || ({} as WorkOrderModel)

    return (
        <InfoCard>
            <InfoCardHeader title="Reason for cancellation" />
            <Typography
                variant="body2"
                color="text.primary"
                sx={{
                    wordBreak: 'break-word',
                }}
            >
                {workOrder.cancellationReason}
            </Typography>
        </InfoCard>
    )
}

export default ReasonForCancelation
