import { type DataRecord } from 'appTypes'
import { useActionsMenuContext } from 'core/actions'
import { woFields } from 'resources/workOrders'
import { Icon, BoxContainer, Typography } from 'ui'

import { type WorkOrderCreateMenuProps } from './AddWorkOrderMenu'
import useAttachWO from './UseWO/useAttachWO'

const CreateWOSection = <ParentType extends DataRecord = any>({
    parentRecord,
    cardMenuClose,
    options,
}: Pick<WorkOrderCreateMenuProps<ParentType>, 'parentRecord' | 'options'> & {
    cardMenuClose?: () => void
}) => {
    const woUse = useAttachWO()
    const { close } = useActionsMenuContext()

    const attachWo = () => {
        woUse({
            parentRecord,
            options,
        })
    }

    return (
        <BoxContainer
            gap="18px"
            p="14px 16px 0"
        >
            <Icon
                component={woFields.avatar.Icon}
                sx={{
                    color: (theme) => theme.palette.text.primary,
                    opacity: 0.54,
                    height: '20px',
                    width: '20px',
                }}
            />
            <Typography
                variant="menuItem"
                color="text.primary"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                    close()
                    cardMenuClose?.()
                    attachWo()
                }}
            >
                Create Work Order
            </Typography>
        </BoxContainer>
    )
}

export default CreateWOSection
