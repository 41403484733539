import { type ReactNode } from 'react'

import { UtilityDrawerEditor, useUtilityDrawerContext } from 'components'
import { JobItemTypeKeys } from 'resources/workOrders'

import { type LaborFormExtra } from '../WorkOrderJobCard/components'

import { jobLaborSerializer } from './constants'

interface JobLaborCreateProps {
    isVendorWo: boolean
    children: ReactNode
}

const JobLaborCreate = (props: JobLaborCreateProps) => {
    const { extra } = useUtilityDrawerContext()

    const { job, workOrder } = extra as LaborFormExtra
    const laborHourlyRate = workOrder.shopData.laborHourlyRate
    return (
        <UtilityDrawerEditor
            {...props}
            defaultValues={{
                ...(props.isVendorWo ? {} : { hourlyRate: laborHourlyRate }),
                component: job.component,
                type: JobItemTypeKeys.LABOR,
            }}
            serializer={jobLaborSerializer}
        />
    )
}
export default JobLaborCreate
