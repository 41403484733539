import { type FC } from 'react'

import { type MemberModel } from 'resourcesBase'

import MemberIcon from '../components/MemberIcon'

const source: keyof MemberModel = 'avatar'

interface Props {
    record: MemberModel
}

const Icon: FC<Props> = ({ record }) => {
    return (
        <MemberIcon
            record={record}
            fontSize="medium"
        />
    )
}

const avatar = {
    source,
    label: 'Avatar',
    Icon,
}

export default avatar
