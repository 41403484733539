import { type FC } from 'react'

import Icons from 'assets/icons'
import { useOpenUtilityDrawer, UtilityDrawerCloseButton } from 'components'
import { type Role } from 'resources/roles'
import { IconBox } from 'ui'

import { UserRolesPermissionsList } from './UserRolesPermissionsList'

interface ViewMoreActionProps {
    role: Role
}

export const ViewMoreAction: FC<ViewMoreActionProps> = ({ role }) => {
    const open = useOpenUtilityDrawer()

    return (
        <IconBox
            onClick={() => {
                open({
                    drawerArgs: {
                        title: role.label,
                        renderWrapper: (params) => <div {...params} />,
                        renderContent: () => <UserRolesPermissionsList role={role} />,
                        renderBottomRight: () => null,
                        renderBottomLeft: () => <UtilityDrawerCloseButton />,
                    },
                })
            }}
            title="View More"
        >
            <Icons.Options />
        </IconBox>
    )
}
