import { type FC } from 'react'

import Icons from 'assets/icons'
import { SelectorOption, DialogSelector, type DialogSelectorProps } from 'components'
import { customerFields } from 'resources/customers'
import { TagsInSelector } from 'resources/tags'
import { CK2Field, displayCK2 } from 'resources/vmrs'
import { Button } from 'ui'

import unitFields from '../fields'
import { type UnitModel } from '../types'
import unitResource from '../unitResource'

import UnitDrawerToggler from './UnitDrawerToggler'

interface Props
    extends Pick<
        DialogSelectorProps,
        'onSelectedChange' | 'renderAboveList' | 'referenceFilter' | 'defaultFilter' | 'resettable'
    > {
    disabled?: boolean
    required?: boolean
    source?: string
    label?: string
    contextType: string
    contextId?: string
}

export const UnitInput: FC<Props> = ({
    disabled,
    required,
    source = 'unit',
    label = 'Unit Number',
    contextType,
    contextId,
    onSelectedChange,
    renderAboveList,
    referenceFilter,
    defaultFilter,
    resettable,
}) => {
    return (
        <DialogSelector<UnitModel>
            resettable={resettable}
            referenceFilter={referenceFilter}
            defaultFilter={defaultFilter}
            reference={unitResource.resource}
            source={source}
            disabled={disabled}
            required={required}
            contextType={contextType}
            contextId={contextId}
            defaultSelectorProps={{
                label,
            }}
            renderItem={renderOption}
            defaultSelectorValueSource={({ selected }) => selected?.number}
            titleSource={() => 'Units'}
            perPage={100}
            renderNextToResultCount={({ control, onSelect }) => (
                <UnitDrawerToggler
                    shortSuccessMessage
                    onSuccess={(record) => {
                        onSelect(record.id)
                        control.refetch()
                    }}
                >
                    {({ onClick }) =>
                        control.isFetching ? null : (
                            <Button
                                startIcon={<Icons.Add />}
                                variant="contained"
                                onClick={onClick}
                            >
                                Create Unit
                            </Button>
                        )
                    }
                </UnitDrawerToggler>
            )}
            noResults={({ searchValue }) => {
                if (!searchValue) {
                    return {
                        title: 'No Units Added',
                        text: '',
                        image: (images) => images.listEmpty,
                        imageWidth: '180px',
                    }
                }
            }}
            onSelectedChange={onSelectedChange}
            renderAboveList={renderAboveList}
        />
    )
}

const renderOption = (record: UnitModel) => (
    <SelectorOption>
        <SelectorOption.Title avatar={<unitFields.avatar.Value record={record} />}>
            {record.number}
        </SelectorOption.Title>
        <SelectorOption.Details>
            {record.customer ? (
                <SelectorOption.Row
                    label={customerFields.self.label}
                    Icon={customerFields.avatar.Icon}
                >
                    {customerFields.name.value(record.customerData)}
                </SelectorOption.Row>
            ) : null}

            {record.name ? (
                <SelectorOption.Row
                    label={unitFields.name.longLabel}
                    Icon={Icons.Name}
                >
                    {record.name}
                </SelectorOption.Row>
            ) : null}

            {record.licensePlate ? (
                <SelectorOption.Row
                    label={unitFields.licensePlate.label}
                    Icon={Icons.LP}
                >
                    {record.licensePlate}
                </SelectorOption.Row>
            ) : null}

            {record.vin ? (
                <SelectorOption.Row
                    label={unitFields.vin.label}
                    Icon={Icons.Vin}
                >
                    {record.vin}
                </SelectorOption.Row>
            ) : null}

            {record.vmrsEquipmentCategory ? (
                <SelectorOption.Row
                    label={CK2Field.label}
                    Icon={Icons.EquipmentCategory}
                >
                    {displayCK2(record.vmrsEquipmentCategoryData)}
                </SelectorOption.Row>
            ) : null}

            {record.model ? (
                <SelectorOption.Row
                    label={unitFields.model.label}
                    Icon={Icons.Model}
                >
                    {record.model}
                </SelectorOption.Row>
            ) : null}

            {record.modelYear ? (
                <SelectorOption.Row
                    label={unitFields.modelYear.label}
                    Icon={Icons.CalendarTodayOutlined}
                >
                    {record.modelYear}
                </SelectorOption.Row>
            ) : null}
        </SelectorOption.Details>

        <TagsInSelector tags={record.tagsData} />
    </SelectorOption>
)
