import { type DataRecord, type NoInfer } from 'appTypes'
import { type Column, type DataListDetails, type ListFilterChoice, type SortBy } from 'components'
import { createResource } from 'core'

import { type CK47Model } from './types'

export const ck47Resource = createResource({
    name: 'ck47',
    resource: 'vmrs/ck47',
})

export const displayCK47 = (record: CK47Model) => (record ? record.description : '')

const source = 'bodyMaterial' as const
const label = 'Body Material'

const column = <Source extends string = typeof source>({
    id,
    label: labelProp,
    dataToRecord,
}: {
    id?: Source
    label?: string
    dataToRecord: (data: DataRecord) => CK47Model
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: labelProp || label,
        renderCell: ({ row }) => displayCK47(dataToRecord(row)),
    }) as const satisfies Column

const cardRow = <Source extends string = typeof source>({
    id,
    dataToRecord,
}: {
    id?: Source
    dataToRecord: (data) => CK47Model
}) =>
    ({
        source: (id || source) as NoInfer<Source>,
        label,
        render: (_, data) => displayCK47(dataToRecord(data)),
    }) as const satisfies DataListDetails<any, any>

const sort = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label,
    }) as const satisfies SortBy

const filter = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label,
    }) as const satisfies ListFilterChoice

export const CK47Field = { column, cardRow, sort, filter }
