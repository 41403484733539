import { type ReactElement } from 'react'

import { useElements, useStripe } from '@stripe/react-stripe-js'
import { type SaveButtonProps } from 'react-admin'

import { usePaymentFormIsLoading } from './PaymetFormContext'

export const PaymentFormSubmitButton = ({
    render,
}: {
    render: (params: SaveButtonProps) => ReactElement
}) => {
    const stripe = useStripe()
    const elements = useElements()
    const isLoading = usePaymentFormIsLoading()
    const disabled: boolean = !stripe || !elements || isLoading

    return render({
        label: 'Add Payment Method',
        alwaysEnable: !disabled,
        disabled,
        saving: isLoading,
    })
}
