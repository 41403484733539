import { type FC } from 'react'

import { SmallSection } from 'ui'

import TotalCard from './TotalCard'

const domicile: FC = () => {
    return (
        <SmallSection>
            <TotalCard />
        </SmallSection>
    )
}

export default domicile
