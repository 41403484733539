import { GridContainerColumns, GridItemLayout, Section } from 'ui'

import SettingsHeader from './SettingsHeader'
import ShopRateCard from './ShopRateCard'

const SettingsSection = () => {
    return (
        <Section>
            <SettingsHeader />
            <GridContainerColumns>
                <GridItemLayout>
                    <ShopRateCard />
                </GridItemLayout>
            </GridContainerColumns>
        </Section>
    )
}

export default SettingsSection
