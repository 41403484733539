import { type FC } from 'react'

import { useListContext } from 'react-admin'

import { GridContainerGrid, GridItemCard, PageContent } from 'ui'

import { type PaymentModel } from '../types'

import PaymentCard from './PaymentCard'
import PaymentsHeader from './PaymentsHeader'

interface Props {
    readOnly?: boolean
}

const PaymentsPage: FC<Props> = ({ readOnly }) => {
    const { data, isLoading } = useListContext<PaymentModel>()

    if (isLoading || !data) {
        return null
    }

    return (
        <PageContent>
            <PaymentsHeader readOnly={readOnly} />
            <GridContainerGrid>
                {data.map((item, index) => (
                    <GridItemCard key={item.id}>
                        <PaymentCard
                            payment={item}
                            index={index}
                        />
                    </GridItemCard>
                ))}
            </GridContainerGrid>
        </PageContent>
    )
}

export default PaymentsPage
