import { urls } from 'configs'
import { useNavigate } from 'lib'
import { Box, Divider, BoxContainer, Button, PageBlock } from 'ui'

import DialogFooterSaveButton from './DialogFooterSaveButton'
import ManageFooterSaveButton from './ManageFooterSaveButton'

const IntegrationsUnitListFooter = ({
    dialog,
    onClose,
    provider,
}: {
    dialog?: boolean
    onClose?: () => void
    provider: string
}) => {
    const navigate = useNavigate()

    return (
        <Box
            sx={(theme) => ({
                position: dialog ? 'sticky' : 'fixed',
                width: dialog ? '100%' : 'calc(100% - 64px)',
                bottom: 0,
                right: 0,
                zIndex: 1,
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                },
            })}
        >
            <Divider />
            <PageBlock
                sx={{
                    padding: '20px 24px',
                    backgroundColor: (theme) => theme.palette.white,
                }}
            >
                <BoxContainer justifyContent="space-between">
                    {dialog ? (
                        <Button
                            onClick={onClose}
                            size="large"
                        >
                            Cancel
                        </Button>
                    ) : (
                        <Button
                            size="large"
                            onClick={() => {
                                navigate(urls.configure + '/integrations')
                            }}
                        >
                            Back
                        </Button>
                    )}
                    {dialog ? (
                        <DialogFooterSaveButton onClick={onClose} />
                    ) : (
                        <ManageFooterSaveButton provider={provider} />
                    )}
                </BoxContainer>
            </PageBlock>
        </Box>
    )
}

export default IntegrationsUnitListFooter
