import { useOpenUtilityDrawer } from 'components/Drawer'
import { authStore } from 'core/auth'

import { CancelButton } from './CancelButton'
import { FormContent } from './FormContent'
import { FormWrapper } from './FormWrapper'
import { PaymentFormSubmitButton } from './SubmitButton'

export const useOpenPaymentMethod = () => {
    const open = useOpenUtilityDrawer()
    const billing = !!authStore.billing

    return function (type: 'upgrade' | 'change') {
        if (!billing) {
            return null
        }

        open({
            drawerArgs: {
                title: type === 'change' ? 'Change Payment Method' : 'Add Payment Method',
                renderWrapper: (params) => (
                    <FormWrapper
                        {...params}
                        type={type}
                    />
                ),
                renderContent: () => <FormContent />,
                renderBottomRight: (renderButton) => (
                    <PaymentFormSubmitButton render={renderButton} />
                ),
                renderBottomLeft: (renderButton) => <CancelButton render={renderButton} />,
            },
            extraArgs: {
                type: 'create',
            },
        })
    }
}
