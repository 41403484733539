import { type PtLaborModel } from 'resources/pricingTiers'

export const deleteLaborActionParams = (record: PtLaborModel) => {
    if (record.customersCount) {
        return {
            confirmConfig: {
                title: `${record.name} Labor Sale Rate is Currently Assigned to a Customer.`,
                content: (
                    <>
                        If you delete this labor sale rate, all affected customers will use the
                        "Default" setting.
                        <br />
                        <br />
                        Do you wish to continue?
                    </>
                ),
            },
        }
    }
    return {
        confirmConfig: {
            title: 'You are about to delete a labor sale rate.',
            content: 'This cannot be undone.',
        },
    }
}
