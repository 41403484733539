import Icons from 'assets/icons'
import { Icon } from 'ui'

import { isVendorWo } from '../isVendorWo'
import { type WorkOrderModel } from '../types'

const avatar = {
    Icon: Icons.WorkOrders,
    getRecordIcon: (wo: WorkOrderModel) => (isVendorWo(wo) ? Icons.VendorWO : Icons.WorkOrders),
    RecordIcon: ({ record }: { record: WorkOrderModel }) => {
        const icon = avatar.getRecordIcon(record)
        return <Icon component={icon} />
    },
}

export default avatar
