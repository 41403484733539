import { type FC, type ReactElement } from 'react'

import { inject } from 'mobx-react'
import { useRecordContext } from 'react-admin'

import { UtilityDrawerForm, useOpenUtilityDrawer } from 'components'
import { type AuthStore, useSubmit, serialize, type Serializer } from 'core'
import { type Flags, useFlags } from 'lib'
import { companyFields } from 'resources/company'
import { type CompanyModel, type BillingModel } from 'resourcesBase'
import { capitalize } from 'utils'

import CompanyInfoForm, { sameAsBilling } from './CompanyInfoDrawerForm'

interface CompanyInfoEditDrawerTogglerProps {
    children: (params: { onClick: () => void }) => ReactElement
    auth?: AuthStore
}

interface CompanyDTO
    extends CompanyModel,
        Record<`billing${Capitalize<keyof BillingModel['billingDetails']>}`, any> {
    [sameAsBilling]: boolean
}

const companySerializer = (flags: Flags, isSameAsBilling): Serializer<CompanyDTO> => {
    const data: Serializer<CompanyDTO> = [
        'address',
        'address2',
        'zipCode',
        'state',
        'city',
        'phone',
        'name',
        'dotNumber',
        { name: companyFields.avatar.source, parse: 'file' },
        { name: 'email', parse: 'email' },
    ]
    if (flags.useBilling) {
        data.push({ name: sameAsBilling, parse: 'boolean' })
        if (!isSameAsBilling) {
            const billingData: Serializer<CompanyDTO> = [
                'billingAddress',
                'billingAddress2',
                'billingZipCode',
                'billingState',
                'billingCity',
                'billingPhone',
                'billingName',
                'billingDotNumber',
                { name: 'billingEmail', parse: 'email' },
            ]
            data.push(...billingData)
        }
    }
    return data
}

export const CompanyInfoEditDrawerToggler: FC<CompanyInfoEditDrawerTogglerProps> = inject('auth')(({
    auth,
    children,
}) => {
    const open = useOpenUtilityDrawer()
    const flags = useFlags()

    const record = useRecordContext<CompanyModel>()
    const submitHandler = useSubmit<CompanyDTO>(
        async (formData) => {
            await auth.saveCompany({
                data: serialize(formData, companySerializer(flags, formData[sameAsBilling])),
            })
        },
        {
            successMessage: ({ defaultMessages }) => defaultMessages.updated,
        },
    )
    const defaultValues = {
        ...record,
    }
    if (auth.billing) {
        const billingInfo = auth.billing?.billingDetails
        Object.assign(defaultValues, {
            [sameAsBilling]: Boolean(billingInfo?.sameAsBillingInfo),
        })
        if (billingInfo) {
            Object.assign(
                defaultValues,
                Object.fromEntries(
                    Object.entries(billingInfo).map(([key, value]) => [
                        'billing' + capitalize(key),
                        value,
                    ]),
                ),
            )
        }
    }

    return children({
        onClick: () => {
            open({
                extraArgs: {
                    recordContext: defaultValues,
                    type: 'edit',
                },
                drawerArgs: {
                    title: 'Edit Company Information',
                    renderWrapper: (params) => (
                        <UtilityDrawerForm
                            {...params}
                            onSubmit={submitHandler}
                        />
                    ),
                    renderContent: () => <CompanyInfoForm />,
                },
            })
        },
    })
})

export default CompanyInfoEditDrawerToggler
