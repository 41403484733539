import { type SvgIconElement } from 'appTypes'
import Icons from 'assets/icons'
import { useTheme } from 'lib'
import {
    type JobItemsCalculations,
    unitClarificationMap,
} from 'pages/Dashboard/components/Widgets/components/types'
import { partFields } from 'resources/parts'
import { JobItemTypeKeys } from 'resources/workOrders'

import { type WorkOrderItemsMapType } from '../useJobItemTranslate'

export type JobLineItemsMapType = {
    id: keyof WorkOrderItemsMapType
    label: string
    icon: SvgIconElement
    total?: number
    totalPercent?: number
    color?: string
}[]

export const LineItemsMap: JobLineItemsMapType = [
    {
        id: JobItemTypeKeys.PART,
        label: 'Parts',
        icon: partFields.avatar.Icon,
    },
    {
        id: JobItemTypeKeys.LABOR,
        label: 'Labor',
        icon: Icons.ScheduleOutlined,
    },
    {
        id: JobItemTypeKeys.SERVICE,
        label: 'Services',
        icon: Icons.WorkOutlineOutlined,
    },
    {
        id: JobItemTypeKeys.FEE,
        label: 'Fee',
        icon: Icons.Fee,
    },
    {
        id: JobItemTypeKeys.TAX,
        label: 'Tax',
        icon: Icons.PercentOutlined,
    },
]

const useJobItemsTooltipData = () => {
    const { palette } = useTheme()
    return (ratioData: Partial<JobItemsCalculations> & { id?: string }) =>
        LineItemsMap.map((item) => {
            const currItemMap = unitClarificationMap.find((itemMap) => itemMap.id === item.id)

            return {
                ...item,
                ...{
                    color: palette.white,
                    total:
                        ratioData.id === 'uncategorized' && ratioData[currItemMap.total] === 0
                            ? null
                            : ratioData[currItemMap.total],
                },
            }
        })
}

export default useJobItemsTooltipData
