import { type ReactElement } from 'react'

import { ReferenceArrayInput, useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import {
    UtilityDrawerForm,
    useUtilityDrawerContext,
    AutocompleteInput,
    useOpenUtilityDrawer,
} from 'components'
import {
    api,
    type Serializer,
    useSubmit,
    serialize,
    requiredValidation,
    renderOnPermission,
    perPageLimit,
} from 'core'
import { MemberField, membersResource } from 'resources/members'
import { shopResource } from 'resources/shops'
import { type ShopModel, type MemberModel } from 'resourcesBase'

interface AssignUserDrawerExtra {
    shop: ShopModel
}

const MemberInput = () => {
    const { extra } = useUtilityDrawerContext()
    const { shop } = extra as AssignUserDrawerExtra
    return (
        <ReferenceArrayInput
            source="members"
            reference={membersResource.resource}
            perPage={perPageLimit}
            contextType={shopResource.resource}
            contextId={shop.id}
        >
            <AutocompleteInput
                optionText={<MemberOptionText />}
                inputText={(record: MemberModel) => {
                    return record.name ? record.name : record.email
                }}
                label="Select User"
                multiple
                validate={requiredValidation}
            />
        </ReferenceArrayInput>
    )
}

const MemberOptionText = () => {
    const record = useRecordContext<MemberModel>()

    return <MemberField record={record} />
}
const assignMemberSerializer: Serializer = [{ name: 'members', parse: 'emptyToNull' }]

const AssignMemberDrawerToggler = renderOnPermission(
    ({
        children,
        record,
    }: {
        children: (params: { onClick: () => void }) => ReactElement
        record: ShopModel
    }) => {
        const open = useOpenUtilityDrawer()

        const handleSubmit = useSubmit(
            async (formData) => {
                await api.post(
                    `${shopResource.resource}/${record.id}/add_members`,
                    serialize(formData, assignMemberSerializer),
                )
            },
            {
                successMessage: 'Successfully assigned user(s)',
            },
        )
        return children({
            onClick: () => {
                open({
                    drawerArgs: {
                        title: 'Assign Users',
                        renderWrapper: (params) => (
                            <UtilityDrawerForm
                                {...params}
                                onSubmit={handleSubmit}
                            />
                        ),
                        renderContent: () => (
                            <>
                                <MemberInput />
                            </>
                        ),
                        renderBottomRight: (render) =>
                            render({
                                label: 'Assign',
                                icon: <Icons.PersonAddAltOutlined />,
                            }),
                    },
                    extra: {
                        shop: record,
                    } as AssignUserDrawerExtra,
                })
            },
        })
    },
    'addMembers',
    shopResource.resource,
)

export default AssignMemberDrawerToggler
