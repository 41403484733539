import { type FC } from 'react'

import { inject, observer } from 'mobx-react'
import { useCreatePath } from 'react-admin'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import {
    CardCollapsibleContent,
    type CardListConfig,
    type DatagridColumnsProps,
    type FilterConfig,
    List,
    ListAvatar,
    ListBase,
    ListFilterRangeInput,
    type ListSortContentProps,
    PhotosCollapsibleContent,
    PhotosCollapsibleIconHolder,
    formatMoney,
    resolveQtyMask,
    costMaskParams,
} from 'components'
import { urls } from 'configs'
import { type AuthStore, type SortPayload } from 'core'
import { Link, formatDate } from 'lib'
import { createdField } from 'resources/base'
import { inventoryFields, type InventoryItem } from 'resources/inventory'
import { type PartModel, partFields, partCk33Label } from 'resources/parts'
import { shopFields } from 'resources/shops'
import { TagsCollapsibleContent, TagsCollapsibleIconHolder, tagFields } from 'resources/tags'
import { uomFields } from 'resources/unitsOfMeasure'
import { ck33Fields, ck34Fields } from 'resources/vmrs'
import { IconBox, LinkButton, PageContent, Typography } from 'ui'
import { pathJoin } from 'utils'

import { InventoryItemDrawerToggler, InventoryListHeader, OnHandQuantityField } from './components'

type Inventory = InventoryItem & {
    [key in 'shopName' | `part${Capitalize<keyof PartModel>}`]: never
}

const defaultInventorySort: SortPayload<Inventory> = {
    field: 'created',
    order: 'DESC',
}

const InventoryList: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const createPath = useCreatePath()

        const filterConfig: FilterConfig<Inventory> = {
            filters: [
                partFields.number.filter({
                    id: partFields.number.longSource,
                }),
                ck34Fields.self.filter({
                    id: 'partManufacturer',
                }),
                uomFields.self.filter({
                    id: 'partUnitOfMeasure',
                }),
                tagFields.self.filter({
                    id: 'partTags',
                }),
                partFields.photos.filter({
                    id: partFields.photos.longSource,
                }),
                partFields.positionApplicable.filter({
                    id: partFields.positionApplicable.longSource,
                }),
                partFields.serializedPart.filter({
                    id: partFields.serializedPart.longSource,
                }),
                ck33Fields.self.filter({
                    id: 'partComponent',
                    label: partCk33Label(auth),
                }),
                {
                    id: 'acquisitionCost',
                    label: 'Average Cost',
                    filterType: 'range',
                    renderComponent: (props) => (
                        <ListFilterRangeInput
                            inputProps={costMaskParams}
                            {...props}
                        />
                    ),
                },
                { id: 'isBelowMinQuantity', label: 'Below QTY Threshold' },
                shopFields.self.withGuard(auth, shopFields.self.filter({ id: 'shopName' })),
            ],
        }

        const sortConfig: ListSortContentProps<Inventory> = {
            sortBy: [
                createdField.sort({
                    label: 'Added on',
                }),
                partFields.number.sort({ id: partFields.number.longSource }),
                partFields.description.sort({ id: partFields.description.longSource }),
                { id: 'quantityOnHand', label: 'On Hand QTY' },
                { id: 'availableQuantity', label: 'Available QTY' },
                { id: 'allocatedQuantity', label: 'Allocated QTY' },
                { id: 'quantityOnOrder', label: 'On Order QTY' },
                { id: 'binLocationId', label: 'Bin location ID' },
                shopFields.self.withGuard(auth, shopFields.self.sort()),
                ck33Fields.self.sort({ id: 'partComponent' }),
                ck34Fields.self.sort({ id: 'partManufacturer' }),
                partFields.manufacturerPartNumber.sort({
                    id: partFields.manufacturerPartNumber.longSource,
                }),
                partFields.upc.sort({
                    id: partFields.upc.longSource,
                }),
                partFields.positionApplicable.sort({
                    id: partFields.positionApplicable.longSource,
                }),
                partFields.serializedPart.sort({
                    id: partFields.serializedPart.longSource,
                }),
                partFields.photos.sort({
                    id: partFields.photos.longSource,
                }),
                uomFields.self.sort({ id: 'partUnitOfMeasure' }),
            ],
        }

        const navigateTo = (id: Identifier) =>
            createPath({
                resource: urls.inventory,
                id,
                type: 'edit',
            })
        const columnsConfig: DatagridColumnsProps<Inventory> = {
            resetColumns: {
                [inventoryFields.qtyThreshold.source]: false,
                partComponent: false,
                partManufacturer: false,
                partManufacturerPartNumber: false,
                partUniversalProductCode: false,
                partPositionApplicable: false,
                partSerializedPart: false,
                partPhotosCount: false,
                partUnitOfMeasure: false,
            },
            constantColumns: {
                partNumber: true,
            },
            checkboxSelection: false,
            columns: [
                {
                    field: 'partImage',
                    headerName: 'Picture',
                    maxWidth: 72,
                    renderCell: ({ row }) => (
                        <ListAvatar
                            id={row.id}
                            imageSrc={row.partData.image}
                            resource={urls.inventory}
                            defaultImage={<partFields.avatar.Icon />}
                        />
                    ),
                },
                {
                    field: 'partNumber',
                    headerName: 'Part Number',
                    renderCell: ({ row }) => (
                        <LinkButton to={pathJoin(urls.inventory, row.id)}>
                            {row.partData.number}
                        </LinkButton>
                    ),
                },
                partFields.description.tableColumn({
                    id: partFields.description.longSource,
                    dataToValue: (data) => data.partData.description,
                }),
                shopFields.self.withGuard(
                    auth,
                    shopFields.self.tableColumn({
                        dataToRecord: (data: Inventory) => data.shopData,
                    }),
                ),
                {
                    field: 'quantityOnHand',
                    headerName: 'On Hand QTY',
                    renderCell: ({ row }) => <OnHandQuantityField record={row} />,
                },
                {
                    field: 'availableQuantity',
                    headerName: 'Available QTY',
                    valueFormatter: ({ value }) => resolveQtyMask(value),
                },
                {
                    field: 'allocatedQuantity',
                    headerName: 'Allocated QTY',
                    valueFormatter: ({ value }) => resolveQtyMask(value),
                },
                {
                    field: 'quantityOnOrder',
                    headerName: 'On Order QTY',
                    valueFormatter: ({ value }) => resolveQtyMask(value),
                },
                createdField.tableColumn({
                    dataToValue: (data) => data.created,
                    label: 'Added on',
                }),
                tagFields.self.column({
                    dataToRecord: (data) => data.partData.tagsData,
                    id: 'partTags',
                }),
                {
                    field: 'binLocationId',
                    headerName: 'Bin Location ID',
                    valueGetter: ({ value }) => value,
                },
                {
                    field: 'acquisitionCost',
                    headerName: 'Average Cost',
                    headerAlign: 'right',
                    align: 'right',
                    renderCell: ({ value }) => formatMoney(value),
                },
                {
                    field: 'totalCost',
                    headerName: 'Inventory Total',
                    headerAlign: 'right',
                    align: 'right',
                    renderCell: ({ value }) => formatMoney(value),
                },
                {
                    field: inventoryFields.qtyThreshold.source,
                    headerName: inventoryFields.qtyThreshold.label,
                    renderCell: ({ row }) => <inventoryFields.qtyThreshold.Value record={row} />,
                    toExport: {
                        separate: [
                            { field: 'minQuantity', headerName: 'Min Quantity' },
                            { field: 'maxQuantity', headerName: 'Max Quantity' },
                        ],
                    },
                },
                ck33Fields.self.gridColumn({
                    id: 'partComponent',
                    dataToRecord: (data: InventoryItem) => data.partData.componentData,
                }),
                ck34Fields.self.column({
                    id: 'partManufacturer',
                    dataToRecord: (data: InventoryItem) => data.partData.manufacturerData,
                }),
                partFields.manufacturerPartNumber.tableColumn({
                    id: partFields.manufacturerPartNumber.longSource,
                    dataToValue: (data: InventoryItem) => data.partData.manufacturerPartNumber,
                }),
                partFields.upc.tableColumn({
                    id: partFields.upc.longSource,
                    dataToValue: (data: InventoryItem) => data.partData.universalProductCode,
                }),
                partFields.positionApplicable.tableColumn({
                    id: partFields.positionApplicable.longSource,
                    dataToValue: (data: InventoryItem) => data.partData.positionApplicable,
                }),
                partFields.serializedPart.tableColumn({
                    id: partFields.serializedPart.longSource,
                    dataToValue: (data: InventoryItem) => data.partData.serializedPart,
                }),
                partFields.photos.tableColumn({
                    id: partFields.photos.longSource,
                    getData: (data: InventoryItem) => data.partData,
                }),
                uomFields.self.tableColumn({
                    id: 'partUnitOfMeasure',
                    dataToRecord: (data: InventoryItem) => data.partData.unitOfMeasureData,
                }),
            ],
            actions: ({ row }, { children }) => [
                <Link
                    to={navigateTo(row.id)}
                    key="show"
                >
                    {children({
                        Icon: Icons.Edit,
                        title: 'View/Edit',
                    })}
                </Link>,
            ],
        }

        const cardConfig: CardListConfig<InventoryItem> = {
            titleSource: (record) => record.partData.number,
            subheaderTypographyProps: {
                variant: 'chartTitle',
            },
            subTitleSource: (record) => record.partData.description,
            imageSource: (record) => record.partData.image,
            defaultImage: <partFields.avatar.Icon />,
            titleLink: (record) => navigateTo(record.id),
            details: [
                {
                    source: 'quantityOnHand',
                    label: 'On Hand QTY',
                    render: (_, data) => <OnHandQuantityField record={data} />,
                },
                {
                    source: 'availableQuantity',
                    label: 'Available QTY',
                    render: (value) => resolveQtyMask(value),
                },
                {
                    source: 'quantityOnOrder',
                    label: 'On Order QTY',
                    render: (value) => resolveQtyMask(value),
                },
                {
                    source: 'allocatedQuantity',
                    label: 'Allocated QTY',
                    render: (value) => resolveQtyMask(value),
                },
                shopFields.self.withGuard(
                    auth,
                    shopFields.self.dataCardRow({
                        dataToRecord: (data: InventoryItem) => data.shopData,
                    }),
                ),
                {
                    source: 'created',
                    label: 'Added on',
                    render: (value) =>
                        formatDate(value, (dateFormats) => dateFormats.shortenedDateTime),
                },
                {
                    source: 'binLocationId',
                    label: 'Bin location ID',
                },
                {
                    source: 'acquisitionCost',
                    label: 'Average Cost',
                    render: (value) => formatMoney(value),
                },
                {
                    source: 'totalCost',
                    label: 'Inventory Total',
                    render: (value) => formatMoney(value),
                },
            ],
            collapsibleContent: (record) => (
                <CardCollapsibleContent
                    content={[
                        {
                            iconHolder: (
                                <TagsCollapsibleIconHolder
                                    tags={record.partData.tagsData}
                                    key="tagsIcon"
                                />
                            ),
                            component: (
                                <TagsCollapsibleContent
                                    tags={record.partData.tagsData}
                                    key="tagsContent"
                                />
                            ),
                        },
                        {
                            iconHolder: (
                                <PhotosCollapsibleIconHolder
                                    record={record}
                                    key="photosIcon"
                                />
                            ),
                            component: (
                                <PhotosCollapsibleContent
                                    record={record}
                                    key="photosContent"
                                />
                            ),
                        },
                    ]}
                />
            ),
            action: (record) => (
                <Link
                    to={navigateTo(record.id)}
                    key="show"
                >
                    <IconBox
                        sx={{
                            width: '24px',
                            height: '24px',
                        }}
                    >
                        <Icons.Edit />
                    </IconBox>
                </Link>
            ),
        }
        return (
            <ListBase sort={defaultInventorySort}>
                <InventoryListHeader />
                <PageContent>
                    <List
                        exportFileName="inventory"
                        sortCfg={sortConfig}
                        filtersCfg={filterConfig}
                        columnsCfg={columnsConfig}
                        cardsCfg={cardConfig}
                        listFTUProps={{
                            secondaryTitle: 'Would you like to add one?',
                            action: (
                                <InventoryItemDrawerToggler>
                                    {({ onClick }) => (
                                        <Typography
                                            variant="body1"
                                            color="primary.main"
                                            sx={{ cursor: 'pointer' }}
                                            onClick={onClick}
                                        >
                                            Add Part to Inventory
                                        </Typography>
                                    )}
                                </InventoryItemDrawerToggler>
                            ),
                        }}
                    />
                </PageContent>
            </ListBase>
        )
    }),
)

export default InventoryList
