import { type FC } from 'react'

import { alpha, type Theme } from 'lib'
import { Backdrop } from 'ui'

import { useSidebarContext } from './SidebarContext'

const SidebarBackdrop: FC = () => {
    const { open, close } = useSidebarContext()

    return (
        <Backdrop
            sx={(theme: Theme) => ({
                color: alpha(theme.palette.text.primary, 0.8),
                zIndex: 5,
            })}
            open={open}
            onClick={close}
        />
    )
}

export default SidebarBackdrop
