import { type FC, type ReactNode } from 'react'

import { type Identifier } from 'appTypes'
import { urls } from 'configs'
import { useCreateResourcePath } from 'core/resource'
import { LinkButton } from 'ui'

interface Props {
    id: Identifier
    children: ReactNode
}

export const ShopLink: FC<Props> = ({ id, children }) => {
    const createPath = useCreateResourcePath()
    return (
        <LinkButton
            to={createPath({
                resource: urls.repairShops,
                type: 'edit',
                id,
            })}
        >
            {children}
        </LinkButton>
    )
}
