import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import { ViewHeader } from 'components'
import { useResourcePermissions, basePermissions } from 'core/permissions'
import { ShopDrawerToggler } from 'resources/shops'
import { type ShopModel } from 'resourcesBase'
import { IconBox } from 'ui'

const DetailsHeader = () => {
    const record = useRecordContext<ShopModel>()
    const permissions = useResourcePermissions()

    const hideAction = !permissions[basePermissions.update]
    return (
        <ViewHeader
            title="Details"
            loading={!record}
        >
            {hideAction || (
                <ViewHeader.Content placement="rightMobile">
                    <ShopDrawerToggler id={record?.id}>
                        {({ onClick }) => (
                            <IconBox
                                title="Edit"
                                onClick={onClick}
                            >
                                <Icons.Edit />
                            </IconBox>
                        )}
                    </ShopDrawerToggler>
                </ViewHeader.Content>
            )}
        </ViewHeader>
    )
}
export default DetailsHeader
