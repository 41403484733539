import { useRecordContext } from 'react-admin'

import { SkeletonSliderCardContainer, SliderView } from 'components'
import { AddressCard } from 'resources/address'
import { type ShopModel } from 'resourcesBase'
import { Section, Skeleton } from 'ui'

import DetailsCard from './DetailsCard'
import DetailsHeader from './DetailsHeader'

const CardSkeleton = () => (
    <SkeletonSliderCardContainer
        sx={{
            height: '191px',
            gap: '10px',
        }}
    >
        <Skeleton
            height="8px"
            width="362px"
            sx={{
                my: '20px',
            }}
        />
        <Skeleton />
    </SkeletonSliderCardContainer>
)

const skeletonSlides = new Array(2).fill(0).map((v, i) => <CardSkeleton key={i} />)

const DetailsSection = () => {
    const record = useRecordContext<ShopModel>()
    const slides = record ? [<DetailsCard />, <AddressCard />] : skeletonSlides

    return (
        <Section>
            <DetailsHeader />
            <SliderView
                height="187px"
                items={slides}
            />
        </Section>
    )
}

export default DetailsSection
