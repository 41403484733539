import { CardDefaultSkeleton, InfoCard, InfoCardDetails, InfoCardHeader } from 'components'
import { displayBooleanValue } from 'utils'

import { type InvoicingSettings } from '../types'
const InvoicingCard = ({ settings }: { settings: InvoicingSettings }) => {
    if (!settings) {
        return <CardDefaultSkeleton height="161px" />
    }
    return (
        <InfoCard>
            <InfoCardHeader title="Settings" />
            <InfoCardDetails
                details={[
                    {
                        label: 'Invoice on WO close',
                        source: () => displayBooleanValue(settings.invoiceOnWorkOrderClose),
                    },
                    {
                        label: 'Include Company Logo',
                        source: () => displayBooleanValue(settings.printoutIncludeCompanyLogo),
                    },
                    {
                        label: 'Show Part Number in PDF',
                        source: () => displayBooleanValue(settings.printoutShowPartNumbers),
                    },
                ]}
            />
        </InfoCard>
    )
}

export default InvoicingCard
