import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import {
    type CardListConfig,
    type DatagridColumnsProps,
    List,
    type ListSortContentProps,
} from 'components'
import { type ResourceType } from 'core'
import { deleteOneAction } from 'core/actions'
import { createdField } from 'resources/base'
import {
    ptDeleteTitleOnDisabled,
    PTTaxDrawerToggler,
    ptTaxFields,
    type PtTaxModel,
} from 'resources/pricingTiers'
import { type ShopModel } from 'resourcesBase'

import { deleteTaxActionParams } from '../utils'

const columnsCfg: DatagridColumnsProps<PtTaxModel> = {
    checkboxSelection: false,
    columns: [
        {
            field: ptTaxFields.name.source,
            headerName: ptTaxFields.name.label,
            renderCell: ({ row }) => <ptTaxFields.name.Value record={row} />,
        },
        {
            field: ptTaxFields.percentageOnTotal.source,
            headerName: ptTaxFields.percentageOnTotal.label,
            renderCell: ({ row }) => ptTaxFields.percentageOnTotal.value(row),
            align: 'right',
            headerAlign: 'right',
            maxWidth: 130,
        },
    ],
    actions: ({ row }, { children, resource }) => [
        <PTTaxDrawerToggler
            key="edit"
            id={row.id}
            resource={resource.resource}
        >
            {(open) =>
                children({
                    title: 'Edit',
                    Icon: Icons.Edit,
                    onClick: open,
                })
            }
        </PTTaxDrawerToggler>,
        deleteOneAction({
            children,
            id: row.id,
            disabled: row.isDefault,
            titleOnDisabled: ptDeleteTitleOnDisabled,
            ...deleteTaxActionParams(row),
        }),
    ],
}

const cardsCfg: CardListConfig<PtTaxModel> = {
    titleSource: (record) => <ptTaxFields.name.Value record={record} />,
    disableTitleLink: true,
    defaultImage: null,
    details: [
        {
            source: ptTaxFields.percentageOnTotal.source,
            label: ptTaxFields.percentageOnTotal.label,
            render: (_, record) => ptTaxFields.percentageOnTotal.value(record),
        },
    ],
    actions: (record, { children, resource }) => [
        <PTTaxDrawerToggler
            key="edit"
            id={record.id}
            resource={resource.resource}
        >
            {(open) =>
                children({
                    title: 'Edit',
                    Icon: Icons.Edit,
                    key: 'edit',
                    onClick: open,
                })
            }
        </PTTaxDrawerToggler>,
        deleteOneAction({
            children,
            id: record.id,
            disabled: record.isDefault,
            titleOnDisabled: ptDeleteTitleOnDisabled,
            ...deleteTaxActionParams(record),
        }),
    ],
}

const sortCfg: ListSortContentProps<PtTaxModel> = {
    sortBy: [
        createdField.sort(),
        {
            id: ptTaxFields.name.source,
            label: ptTaxFields.name.label,
        },
        {
            id: ptTaxFields.percentageOnTotal.source,
            label: ptTaxFields.percentageOnTotal.label,
        },
    ],
}

const TaxesList = ({ preferencesResource }: { preferencesResource: ResourceType }) => {
    const record = useRecordContext<ShopModel>()

    return (
        <List
            isLoading={!record}
            columnsCfg={columnsCfg}
            cardsCfg={cardsCfg}
            sortCfg={sortCfg}
            preferencesResource={preferencesResource}
            disableExportButton
            disableManageColumns
        />
    )
}

export default TaxesList
