import { inject, observer } from 'mobx-react'

import { tabRoutes } from 'components'
import { basePermissions, type AuthStore } from 'core'
import { Routes } from 'lib'
import { companyPermissionName } from 'resources/company'

import CompanyInfo from './CompanyInfo'
import ManageAccount from './ManageAccount'
import Members from './Members'
import { RepairShopsList } from './RepairShops'
import UserRoles from './UserRoles'
import { CompanyHeader } from './components'

const Company = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const { routes, tabs } = tabRoutes([
            {
                value: '',
                label: 'Info & Billing',
                element: <CompanyInfo />,
            },
            {
                value: 'members',
                label: 'Users',
                element: <Members />,
            },
            {
                value: 'user-roles',
                label: 'User Roles',
                element: <UserRoles />,
            },
            {
                value: 'repair-shops',
                label: 'Repair Shops',
                element: <RepairShopsList />,
            },
            auth.permissions[companyPermissionName][basePermissions.destroy]
                ? {
                      value: 'account',
                      label: 'Manage Account',
                      element: <ManageAccount />,
                  }
                : null,
        ])

        return (
            <>
                <CompanyHeader tabs={tabs} />
                <Routes> {routes} </Routes>
            </>
        )
    }),
)

export default Company
