import { GlobalStyles } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LicenseInfo } from '@mui/x-license'
import { Provider as MobxProvider } from 'mobx-react'
import PWAPrompt from 'react-ios-pwa-prompt'
// import { ReactQueryDevtools } from 'react-query/devtools'

import { AdminContext, globalStyle, FullPageLoader } from 'components'
import { config } from 'configs'
import {
    Confirm,
    NotificationProvider,
    UserObserver,
    MobxSyncFlags,
    ErrorHandling,
    authStore,
    BlockersProvider,
} from 'core'
import { Routes } from 'pages'

LicenseInfo.setLicenseKey(config.MUI_X_KEY)

const App = () => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobxProvider auth={authStore}>
                <AdminContext>
                    <NotificationProvider>
                        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                        <GlobalStyles styles={globalStyle} />
                        <BlockersProvider>
                            <ErrorHandling>
                                <Routes />
                            </ErrorHandling>
                            <Confirm />
                            <UserObserver />
                            <MobxSyncFlags />
                            <FullPageLoader />
                        </BlockersProvider>
                    </NotificationProvider>
                </AdminContext>
                <PWAPrompt
                    copyBody={
                        'For a better experience you can add Fleetpal ' +
                        'to your home screen and use it in fullscreen mode.'
                    }
                    copyClosePrompt="Close"
                />
            </MobxProvider>
        </LocalizationProvider>
    )
}

export default App
