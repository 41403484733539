import Icons from 'assets/icons'
import { ListTotalBadge, ViewHeader } from 'components'
import { supportEmail } from 'configs'
import { useConfirm } from 'core'
import { Anchor, Alert, Button, FixedOnMobile } from 'ui'

const RepairShopListHeader = () => {
    const confirm = useConfirm()
    return (
        <ViewHeader
            title="Repair Shops"
            pb="20px"
        >
            <ViewHeader.Content>
                <ListTotalBadge />
            </ViewHeader.Content>
            <ViewHeader.Content placement="right">
                <FixedOnMobile>
                    <Button
                        variant="contained"
                        startIcon={<Icons.SettingsOutlined />}
                        onClick={() => {
                            confirm({
                                title: 'Manage Repair Shop',
                                confirmButtonProps: null,
                                content: (
                                    <Alert severity="info">
                                        Do you need to create or remove a repair shop? Contact us at{' '}
                                        <Anchor href={`mailto:${supportEmail}`}>
                                            {supportEmail}
                                        </Anchor>
                                    </Alert>
                                ),
                            })
                        }}
                    >
                        Manage Repair Shop
                    </Button>
                </FixedOnMobile>
            </ViewHeader.Content>
        </ViewHeader>
    )
}

export default RepairShopListHeader
