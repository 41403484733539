import { createTheme as createBaseTheme, type Breakpoint, type ThemeOptions } from '@mui/material'

import { type ThemeOptionsConfig } from 'lib'

import lightPalette, { type IPalette } from './palette'
import themeTypography, { type ITypography } from './typography'

declare module '@mui/material/styles' {
    interface Palette extends IPalette {}
    interface PaletteOptions extends IPalette {}

    interface PaletteColor extends Partial<IPalette> {}
    interface SimplePaletteColorOptions extends Partial<IPalette> {}
    interface Theme {
        props: typeof extraProps

        palette: IPalette

        typography: ITypography
    }

    interface ThemeOptions extends Partial<Theme> {}
}

const extraProps = {
    drawerWidth: 64,
    headerHeight: 56,
    sidebarPaddingX: 8,
    mobileViewBreakpoint: 'sm' as Breakpoint,
}

const themeBreakpoints: { [key in Breakpoint]: number } = {
    xs: 0,
    sm: 720,
    md: 1024,
    lg: 1440,
    xl: 2048,
}

export const themeOption: ThemeOptionsConfig = {
    palette: lightPalette,
}

export const createTheme = ({ components }: Pick<ThemeOptions, 'components'>) => {
    const typography = themeTypography(themeOption)

    const rtheme = createBaseTheme({
        direction: 'ltr',
        palette: lightPalette,
        typography,
        props: extraProps,
        spacing: 4,
        shape: {
            borderRadius: 4,
        },
        components,
        breakpoints: {
            values: themeBreakpoints,
        },
    })

    return rtheme
}

export const cssVariables = {
    pageContentPadding: '--page-content-padding',
    gap: '--gap',
    iconSize: '--icon-size',
}

export const globalClassNames = {
    ellipsis: 'ellipsis',
    autoRow: 'auto-row',
    withGap: 'with-gap',
    displayNone: 'd-none',
}

export const formHelperTextHeight = '39px'
