import { type FC, type ReactNode } from 'react'

import { AdminContext as RAAdminContext, type DataProvider } from 'react-admin'

import { getTheme } from 'components/theme'
import { authProvider as authProviderConstructor, authStore } from 'core/auth'
import { dataProvider as dataProviderConstructor } from 'core/data'
import { i18nProvider } from 'core/i18n'
import { storeProvider } from 'core/store'
import { queryClientProvider } from 'lib'

const dataProvider = dataProviderConstructor(authStore)
const authProvider = authProviderConstructor(authStore)

interface Props {
    children: ReactNode
}

const AdminContext: FC<Props> = ({ children }) => {
    return (
        <RAAdminContext
            dataProvider={dataProvider as DataProvider}
            authProvider={authProvider}
            theme={getTheme()}
            i18nProvider={i18nProvider}
            store={storeProvider}
            queryClient={queryClientProvider}
        >
            {children}
        </RAAdminContext>
    )
}

export default AdminContext
