import { useShowContext } from 'react-admin'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { ViewHeader } from 'components'
import { api } from 'core'
import { useQuery } from 'lib'
import { shopResource } from 'resources/shops'
import { type ShopModel } from 'resourcesBase'
import { GridContainer, GridItemLayout, IconBox, Section } from 'ui'

import { type InvoicingSettings } from '../types'

import InvoicingCard from './InvoicingCard'
import InvoicingSettingsDrawerToggler from './InvoicingSettingsDrawerToggler'

const getResource = (id: Identifier) => `${shopResource.resource}/${id}/invoicing_settings`

const InvoicingSection = () => {
    const { record, isLoading: isLoadingShow } = useShowContext<ShopModel>()
    const resource = getResource(record?.id)
    const { data, isLoading } = useQuery<InvoicingSettings>(
        ['invoicing_settings'],
        async () => {
            const res = await api.get(resource)
            return res
        },
        { enabled: !isLoadingShow },
    )

    return (
        <Section>
            <ViewHeader
                title="Invoicing"
                loading={isLoading || isLoadingShow}
            >
                <ViewHeader.Content placement="rightMobile">
                    <InvoicingSettingsDrawerToggler
                        settings={data}
                        resource={resource}
                    >
                        {({ onClick }) => (
                            <IconBox
                                title="Edit"
                                onClick={onClick}
                            >
                                <Icons.Edit />
                            </IconBox>
                        )}
                    </InvoicingSettingsDrawerToggler>
                </ViewHeader.Content>
            </ViewHeader>
            <GridContainer>
                <GridItemLayout>
                    <InvoicingCard settings={data} />
                </GridItemLayout>
            </GridContainer>
        </Section>
    )
}

export default InvoicingSection
