import { useShowContext } from 'react-admin'

import {
    type CardListConfig,
    type DatagridColumnsProps,
    List,
    ListBase,
    type ListSortContentProps,
} from 'components'
import { type ResourceType, type SortPayload } from 'core'
import { multiselectAction } from 'core/actions'
import { useResourcePermissions } from 'core/permissions'
import { memberFields, membersResource } from 'resources/members'
import { shopResource } from 'resources/shops'
import { type MemberModel } from 'resourcesBase'
import { DataAvatar, PageContent } from 'ui'

import { ListHeader, unassignManyFromListAction, unassignOneFromListAction } from './components'

const defaultSort: SortPayload<MemberModel> = {
    field: 'lastLogin',
    order: 'DESC',
}

const sortCfg: ListSortContentProps<MemberModel> = {
    sortBy: [
        { id: memberFields.email.source, label: memberFields.email.label },
        { id: memberFields.name.source, label: memberFields.name.label },
        { id: memberFields.role.source, label: memberFields.role.label },
        { id: memberFields.phone.source, label: memberFields.phone.label },
        { id: memberFields.shops.source, label: memberFields.shops.label },
        { id: memberFields.lastLogin.source, label: memberFields.lastLogin.label },
    ],
}

const columnsCfg = (isLimitedRole: boolean): DatagridColumnsProps<MemberModel> => ({
    checkboxSelection: !isLimitedRole,
    columns: [
        {
            headerName: memberFields.avatar.label,
            field: memberFields.avatar.source,
            renderCell: (cell) => (
                <DataAvatar
                    defaultImage={<memberFields.avatar.Icon record={cell.row} />}
                    imageSrc={cell.value}
                />
            ),
        },
        {
            headerName: memberFields.email.label,
            field: memberFields.email.source,
        },
        {
            headerName: memberFields.name.label,
            field: memberFields.name.source,
        },
        {
            headerName: memberFields.role.label,
            field: memberFields.role.source,
            renderCell: ({ row }) => memberFields.role.value(row),
        },
        {
            field: memberFields.phone.source,
            headerName: memberFields.phone.label,
            flex: 1,
            renderCell: ({ row }) => memberFields.phone.value(row),
        },
        {
            headerName: memberFields.shops.label,
            field: memberFields.shops.source,
            renderCell: ({ row }) => memberFields.shops.value(row),
        },
        {
            headerName: memberFields.lastLogin.label,
            field: memberFields.lastLogin.source,
            renderCell: ({ row }) => memberFields.lastLogin.value(row),
        },
    ],
    actions: isLimitedRole
        ? null
        : ({ row }, { children }) => [
              unassignOneFromListAction({
                  id: row.id,
                  children,
              }),
          ],
})

const cardsCfg = (isLimitedRole: boolean): CardListConfig<MemberModel> => ({
    titleSource: (record) => memberFields.email.value(record),
    disableTitleLink: true,
    defaultImage: (record) => <memberFields.avatar.Icon record={record} />,
    imageSource: memberFields.avatar.source,
    subTitleSource: (record) => memberFields.role.value(record),
    details: [
        {
            source: memberFields.name.source,
            label: memberFields.name.label,
            render: (v, record) => memberFields.name.value(record),
        },
        {
            source: memberFields.phone.source,
            label: memberFields.phone.label,
            render: (v, record) => memberFields.phone.value(record),
        },
        {
            source: memberFields.shops.source,
            label: memberFields.shops.label,
            render: (v, record) => memberFields.shops.value(record),
        },
        {
            source: memberFields.lastLogin.source,
            label: memberFields.lastLogin.label,
            render: (v, record) => memberFields.lastLogin.value(record),
        },
    ],
    actionsDisabled: () => isLimitedRole,
    actions: (record, { children }) => [
        multiselectAction({
            children,
            id: record.id,
        }),
        unassignOneFromListAction({
            id: record.id,
            children,
        }),
    ],
})
const preferencesResource: ResourceType = {
    name: 'rs-members',
    resource: 'rs-members',
}
const RepairShopMembers = () => {
    const permissions = useResourcePermissions(shopResource)

    const isLimitedRole = !permissions.removeMembers
    const { record, isLoading } = useShowContext()
    return (
        <ListBase<MemberModel>
            sort={defaultSort}
            resource={membersResource.resource}
            filter={{
                shops: record?.id,
            }}
            preferencesResource={preferencesResource}
        >
            <PageContent>
                <ListHeader />
                <List
                    exportFileName="users"
                    isLoading={isLoading}
                    bulkActions={({ children }) => [unassignManyFromListAction({ children })]}
                    sortCfg={sortCfg}
                    columnsCfg={columnsCfg(isLimitedRole)}
                    cardsCfg={cardsCfg(isLimitedRole)}
                    listFTUProps={{
                        linkText: null,
                    }}
                    preferencesResource={preferencesResource}
                />
            </PageContent>
        </ListBase>
    )
}

export default RepairShopMembers
