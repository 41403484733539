import { type Identifier } from 'appTypes'
import { type BaseModel } from 'appTypes/common'
import { type PartModel } from 'resources/parts'
import { type WithUOM } from 'resources/unitsOfMeasure'
import { type CK15Model, type CK33Model, type CK34Model } from 'resources/vmrs'

export enum JobItemTypeKeys {
    LABOR = 'LABOR',
    PART = 'PART',
    FEE = 'FEE',
    TAX = 'TAX',
    SERVICE = 'SERVICE',
}

export type JobItemTypesUnion = (typeof JobItemTypeKeys)[keyof typeof JobItemTypeKeys]

export type JobItemModels = {
    [JobItemTypeKeys.LABOR]: JobLaborModel
    [JobItemTypeKeys.PART]: JobPartModel
    [JobItemTypeKeys.FEE]: JobFeeModel
    [JobItemTypeKeys.TAX]: JobTaxModel
    [JobItemTypeKeys.SERVICE]: JobServiceModel
}

export type JobItemKeysUnion = keyof JobItemModels

export type JobItem = JobItemModels[keyof JobItemModels]

type JobItemType<T extends JobItemTypesUnion> = { type: T }

export interface JobLaborModel extends BaseModel, JobItemType<typeof JobItemTypeKeys.LABOR> {
    description: string
    hourlyRate: number
    hours: number
    cause: Identifier
    correction: Identifier
    componentData: CK33Model
    correctionData: CK15Model
    component: Identifier
}

export interface JobPartModel extends BaseModel, JobItemType<typeof JobItemTypeKeys.PART>, WithUOM {
    positionApplicable: boolean
    partNumber: 'string'
    description: string
    price: number
    quantity: number
    component: number
    componentData: CK33Model
    part: Identifier
    partData: PartModel
    partType: PartModel['type']
    manufacturer: number
    manufacturerData: CK34Model
    position: number
}

interface VWorkorderItemBase<T extends JobItemTypesUnion> extends BaseModel, JobItemType<T> {
    description: string
    price: number
}

export interface JobTaxModel extends VWorkorderItemBase<typeof JobItemTypeKeys.TAX> {}
export interface JobFeeModel extends VWorkorderItemBase<typeof JobItemTypeKeys.FEE> {}
export interface JobServiceModel extends VWorkorderItemBase<typeof JobItemTypeKeys.SERVICE> {}
