import { type Identifier } from 'appTypes'
import {
    type CardListConfig,
    type DatagridColumnsProps,
    List,
    ListAvatar,
    ListBase,
    type ListSortContentProps,
} from 'components'
import { urls } from 'configs'
import { type SortPayload } from 'core'
import { addressFields } from 'resources/address'
import { countryFields } from 'resources/country'
import { shopFields, shopResource } from 'resources/shops'
import { stateFields } from 'resources/state'
import { type ShopModel } from 'resourcesBase'
import { PageContent } from 'ui'

import { ListHeader } from './components'

const defaultSort: SortPayload<ShopModel> = {
    field: 'name',
    order: 'DESC',
}
const navigateTo = (id: Identifier) => {
    return `${urls.repairShops}/${id}`
}
const sortCfg: ListSortContentProps<ShopModel> = {
    sortBy: [
        {
            id: shopFields.name.source,
            label: shopFields.name.label,
        },
        {
            id: shopFields.phoneNumber.source,
            label: shopFields.phoneNumber.label,
        },
        {
            id: shopFields.contactPerson.source,
            label: shopFields.contactPerson.label,
        },
        {
            id: shopFields.emailAddress.source,
            label: shopFields.emailAddress.label,
        },
        {
            id: shopFields.website.source,
            label: shopFields.website.label,
        },
        addressFields.address1.sort(),
        addressFields.address2.sort(),
        addressFields.city.sort(),
        countryFields.self.sort(),
        stateFields.self.sort(),
        addressFields.zipCode.sort(),
        {
            label: shopFields.members.label,
            id: shopFields.members.source,
        },
        {
            id: shopFields.hourlyLaborRate.source,
            label: shopFields.hourlyLaborRate.label,
        },
    ],
}
const columnsConfig: DatagridColumnsProps<ShopModel & { logo: string }> = {
    checkboxSelection: false,
    resetColumns: { [countryFields.self.source]: false },
    constantColumns: {
        [shopFields.name.source]: true,
    },
    columns: [
        {
            field: shopFields.avatar.source,
            headerName: shopFields.avatar.label,
            maxWidth: 72,
            renderCell: (cell) => (
                <ListAvatar
                    id={cell.row.id}
                    customPath={navigateTo(cell.row.id)}
                    imageSrc={cell.value}
                    defaultImage={<shopFields.avatar.Icon record={cell.row} />}
                    sx={{ cursor: 'pointer' }}
                    color={cell.row.avatarColor}
                    avatarOpacity={1}
                />
            ),
        },
        {
            field: shopFields.name.source,
            headerName: shopFields.name.label,
            renderCell: ({ row }) => shopFields.name.valueWithLink(row),
        },
        {
            field: shopFields.phoneNumber.source,
            headerName: shopFields.phoneNumber.label,
            renderCell: ({ row }) => shopFields.phoneNumber.value(row),
        },
        {
            field: shopFields.contactPerson.source,
            headerName: shopFields.contactPerson.label,
            renderCell: ({ row }) => shopFields.contactPerson.value(row),
        },
        {
            field: shopFields.emailAddress.source,
            headerName: shopFields.emailAddress.label,
            renderCell: ({ row }) => shopFields.emailAddress.value(row),
        },
        {
            field: shopFields.website.source,
            headerName: shopFields.website.label,
            renderCell: ({ row }) => shopFields.website.value(row),
        },
        addressFields.address1.tableColumn({}),
        addressFields.address2.tableColumn({}),

        countryFields.self.tableColumn({ dataToRecord: (record: ShopModel) => record.countryData }),
        stateFields.self.tableColumn({ dataToRecord: (record: ShopModel) => record.stateData }),
        addressFields.city.tableColumn({}),
        addressFields.zipCode.tableColumn({}),
        {
            field: shopFields.members.source,
            headerName: shopFields.members.label,
            renderCell: ({ row }) => shopFields.members.value(row),
        },
        {
            field: shopFields.hourlyLaborRate.source,
            headerName: shopFields.hourlyLaborRate.label,
            renderCell: ({ row }) => shopFields.hourlyLaborRate.value(row),
            headerAlign: 'right',
            align: 'right',
        },
    ],
    actions: null,
}

const cardsConfig: CardListConfig<ShopModel> = {
    titleSource: (record) => shopFields.name.value(record),
    defaultImage: (record) => <shopFields.avatar.Icon record={record} />,
    avatarColor: (t, record) => record.avatarColor,
    avatarOpacity: 1,
    titleLink: (record) => navigateTo(record.id),
    details: [
        {
            label: shopFields.phoneNumber.label,
            source: shopFields.phoneNumber.source,
            render: (v, record) => shopFields.phoneNumber.value(record),
        },
        {
            label: shopFields.contactPerson.label,
            source: shopFields.contactPerson.source,
            render: (v, record) => shopFields.contactPerson.value(record),
        },
        {
            label: shopFields.emailAddress.label,
            source: shopFields.emailAddress.source,
            render: (v, record) => shopFields.emailAddress.value(record),
        },
        {
            label: shopFields.website.label,
            source: shopFields.website.source,
            render: (v, record) => shopFields.website.value(record),
        },
        addressFields.address1.dataCardRow({}),
        addressFields.address2.dataCardRow({}),
        stateFields.self.dataCardRow({ id: 'state', dataToRecord: (data) => data.stateData }),
        addressFields.city.dataCardRow({}),
        addressFields.zipCode.dataCardRow({}),
        {
            label: shopFields.members.label,
            source: shopFields.members.source,
            render: (v, record) => shopFields.members.value(record),
        },
        {
            label: shopFields.hourlyLaborRate.label,
            source: shopFields.hourlyLaborRate.source,
            render: (v, record) => shopFields.hourlyLaborRate.value(record),
        },
    ],
}

export const RepairShopsList = () => {
    return (
        <PageContent>
            <ListBase<ShopModel>
                sort={defaultSort}
                resource={shopResource.resource}
            >
                <ListHeader />
                <List
                    disableExportButton
                    columnsCfg={columnsConfig}
                    cardsCfg={cardsConfig}
                    sortCfg={sortCfg}
                />
            </ListBase>
        </PageContent>
    )
}
