import { type ReactNode } from 'react'

import { ListFTU } from 'components'
import { useGetBillingInvoices } from 'resources/billing'
import { PageContent } from 'ui'

import PaymentHistoryContent from './PaymentHistoryContent'
import { PaymentHistoryHeader } from './components'

const PaymentHistory = () => {
    const { data, isLoading } = useGetBillingInvoices()
    let content: ReactNode = ''

    if (!isLoading) {
        if (data.length) {
            content = <PaymentHistoryContent data={data} />
        } else {
            content = (
                <ListFTU
                    title="No Data"
                    secondaryTitle="Your invoices will appear here."
                    linkText={null}
                />
            )
        }
    }

    return (
        <>
            <PaymentHistoryHeader />
            <PageContent>{content}</PageContent>
        </>
    )
}

export default PaymentHistory
